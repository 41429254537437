import { getResourceCount } from "api/ApiCommons";
import QuestionnaireResponse from "api/QuestionnaireResponse";

/**
 * 
 * Actualiza el estado de una QuestionnaireResponse
 * 
 * @param {*} outcome objeto que contiene la referencia a la QuestionnaireResponse
 * @param {*} status 
 * @returns 
 */
export function patchStatusQuestionnaireResponse(outcome, status) {
  const dataPatch = {
    id: outcome?.reference.split("QuestionnaireResponse/")[1],
    patch: [{
      op: "replace",
      path: `/status`,
      value: status
    }]
  };

  return QuestionnaireResponse.updatePatch(dataPatch);
}

/**
 * Devuelve el número de QuestionnaireResponse no desactivadas de un Paciente que pertenecen a un CarePlan  
 * 
 * @param {*} carePlanId 
 * @returns {int}
 */
export const findCountOfQuestionnaireResponsesCompletedAndInProgress = (carePlanId, questionnaireId) => {
  const query = `?based-on=${carePlanId}&questionnaire=Questionnaire/${questionnaireId}&status=completed,stopped&_summary=count`;
  return getResourceCount(QuestionnaireResponse.findAllDataBy(query));
};

/**
 * Recupera un QuestionnaireResponse a partir de su id.
 * 
 * @param {String} id 
 * @return {QuestionnaireResponse}
 */
export const findQuestionnaireResponseById = (id) => {
  return QuestionnaireResponse.getQuestionnaireResponse(id);
};
