import ReactDOM from 'react-dom';
import { Modal } from '@carbon/react';
import PractitionerRole from 'api/PractitionerRole';
import { PRACTITIONER_ROLE_MAP } from 'api/constants/tags/PractitionerRoleTags';
import { useState } from 'react';


export default function AccountOptions({name, roleId, onClose, logout, changePassword}) {

  const [role, setRole] = useState("");

  PractitionerRole.getPractitionerRole(roleId).then((practitionerRole) => {
    setRole(PRACTITIONER_ROLE_MAP.get(PractitionerRole.getPractitionerRoleCodeId(practitionerRole)).display);
  });
  
  return (
    <>
      {
        typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <Modal
              open
              onRequestClose={onClose}
              onRequestSubmit={changePassword}
              onSecondarySubmit={logout}
              primaryButtonText="Cambiar Contraseña"
              secondaryButtonText="Cerrar Sesion"
              size="sm"
              modalLabel={'Perfil'}
              modalHeading={name}
              preventCloseOnClickOutside
            >

              <span className='text-param'>Perfil: </span>
              <span className='text-value'>{role}</span>
              
            </Modal>
            , document.body
          )
      }
    </>
  );
}