import React, { useCallback, useEffect, useState, useRef } from 'react';

const INTERVAL_MS = 1000;

export const TIMER_DIRECTION = {
  UP: 'up',
  DOWN: 'down'
};

const TIMER_INITIAL_STATE = {
  started: 0,
  progress: '0%',
  duration: 0,
  remaining: 0,
  stopByTimeout: false
};

/**
 * Componente que muestra al usuario un temporizador. Admite los siguientes parámetros de configuración:
 * @param {Number}   length - determina el tiempo límite del temporizador (expresado en milisegundos)
 * @param {Boolean}  run - Permite iniciar o parar el temporizador
 * @param {Boolean}  autoStart - Permite iniciar el temporizador automáticamente al renderizarse el componente
 * @param {String}   format - String que permite indicar si se quiere mostrar 'hh:mm:ss', 'mm:ss', o 'ss'
 * @param {String}   direction - permite indicar si es una cuenta atrás o una cuenta hacia adelante. posibles valores 'up' o 'down'.
 * @param {Function} onFinish - Función que se ejecutará al finalizar el temporizador. Recibe por parámetro la duración final.
 * @param {Function} onProgress - Ejecuta una función en cada segundo del intervalo a la que le pasa el progreso completado en porcentaje.
 */
export default function Timer({ run = false, length = 300000, format = 'hh:mm:ss', direction = TIMER_DIRECTION.DOWN, onFinish = () => { }, onProgress = () => { } }) {
  const [timerState, setTimerState] = useState(TIMER_INITIAL_STATE);
  const intervalRef = useRef(null);

  const timerTick = useCallback(() => {
    setTimerState((prevState) => {
      const remaining = getRemaining(prevState.started, length);
      const duration = getDuration(prevState.started);
      if ((direction === TIMER_DIRECTION.DOWN && remaining <= 500) || (direction === TIMER_DIRECTION.UP && duration >= (length + 500))) {
        onProgress('100%');
        return { ...prevState, remaining: remaining, duration: duration, stopByTimeout: true, progress: '100%' };
      } else {
        const progress = getProgress(duration, length);
        onProgress(progress);
        return { ...prevState, remaining: remaining, duration: duration, progress: progress };
      }
    });
  }, [direction, length, onProgress]);

  const start = useCallback(() => {
    if (run && !timerState.started) {
      setTimerState((prevState) => { return { ...prevState, started: Date.now() }; });
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(() => { timerTick(); }, INTERVAL_MS);
    }
  }, [run, timerState.started, timerTick]);

  const stop = useCallback(() => {
    onFinish(timerState.duration);
    setTimerState((prevTimerState) => {
      if (direction === TIMER_DIRECTION.DOWN) {
        return { ...TIMER_INITIAL_STATE, remaining: prevTimerState.remaining };
      } else {
        return { ...TIMER_INITIAL_STATE };
      }
    });
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, [onFinish, timerState.duration]);

  useEffect(() => {
    if (run) {
      start();
    } else if (timerState.started) {
      stop();
    }
  }, [run]);

  useEffect(() => {
    if (timerState.stopByTimeout) {
      stop();
    }
  }, [timerState.stopByTimeout]);


  useEffect(() => {
    if (direction === TIMER_DIRECTION.DOWN) {
      setTimerState((prevTimerState) => {
        return { ...prevTimerState, remaining: length };
      });
    }
  }, [length, direction]);

  return (
    <Time time={direction === TIMER_DIRECTION.DOWN ? timerState.remaining : timerState.duration} started={timerState.started} format={format} />
  );
}

class Time extends React.Component {
  render() {
    let time = Math.round(this.props.time / 1000);
    const seconds = zeroPad(time % 60, 2);
    time -= seconds;
    const minutes = zeroPad(Math.floor(time / 60) % 60, 2);
    time -= minutes * 60;
    const hours = zeroPad(Math.floor(time / 3600), 2);
    if (this.props.format === 'ss') {
      return [<span key={'seconds'} data-testid="seconds">{zeroPad(seconds, 2)}</span>];
    } else if (this.props.format === 'mm:ss') {
      return [<span key={'minutes'} data-testid="minutes">{zeroPad(minutes, 2)}</span>, ":", <span key={'seconds'} data-testid="seconds">{zeroPad(seconds, 2)}</span>];
    } else {
      return [<span key={'hours'} data-testid="hours">{zeroPad(hours, 2)}</span>, ":", <span key={'minutes'} data-testid="minutes">{zeroPad(minutes, 2)}</span>, ":", <span key={'seconds'} data-testid="seconds">{zeroPad(seconds, 2)}</span>];
    }
  }
}

function zeroPad(x, width) {
  return String(x).padStart(width, "0");
}

function getRemaining(started, length) {
  if (!started) {
    return 0;
  } else {
    return length - (Date.now() - started);
  }
}

function getDuration(started) {
  if (!started) {
    return 0;
  } else {
    return (Date.now() - started);
  }
};

function getProgress(duration, length) {
  const progress = ((duration / length) * 100);
  const progressRounded = Math.round((progress + Number.EPSILON) * 100) / 100;
  return progressRounded + '%';
};