import React, { useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, ButtonSet, Grid, Column, Form, TextInput, Checkbox, RadioButton, RadioButtonGroup, DatePicker, DatePickerInput, InlineLoading } from '@carbon/react';
import { Formik, FieldArray } from 'formik';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Add } from '@carbon/react/icons';
import Overlay from 'layout/overlay/Overlay';
import Patient from 'api/Patient';
import { ASSAY_MAP, SYSTEM_ASSAY } from 'api/constants/tags/AssayTags';
import { getTagsBySystem } from 'api/constants/tags/TagFunctions';

import useNotification from 'hooks/useNotification';
import { useAuth } from 'auth/Auth';
import ConfirmAction from 'components/ConfirmAction/ConfirmAction';

export default function EnrolPatientForm() {
    const navigate = useNavigate();
    const notification = useNotification();
    const [loading, setLoading] = useState(false);
    const [showConfirmCancel, setShowConfirmCancel] = useState(false);

    const auth = useAuth();
    const birthDateRegexp = /^\d{4}$/;
    const practitionerRole = auth.practitionerRole;
    
    /**
     * Recupera la lista de códigos de ensayo a los que el usuario tiene acceso.
     * 
     * @param {*} practitionerRole 
     * @returns lista de String con los códigos de los ensayos.
     */
    const getUserAssays = (practitionerRole) => {
        return getTagsBySystem(practitionerRole?.meta, SYSTEM_ASSAY)?.map(element => element.code) || [];
    };

    const userAssays = getUserAssays(practitionerRole);
    const initialValues = {
        alias: '',
        birthDate: '',
        gender: '',
        consentimiento: '',
        signDate: null,
        registerNumber: '',
        assays: Array.from(ASSAY_MAP.values())
            .filter(item => userAssays.includes(item.code))
            ?.map(item => { return { code: item.code, display: item.display, initialdate: '' }; }) || [],
        patientCovidControlType: false
    };

    const validate = (values) => {
        const errors = {};
        if (!values.birthDate || !birthDateRegexp.test(values.birthDate)) {
            errors.birthDate = 'Fecha de nacimiento requerida como un número de 4 cifras';
        }
        if (!values.gender) {
            errors.gender = 'Género requerido';
        }
        if (!values.consentimiento || values.consentimiento.length === 0) {
            errors.consentimiento = 'Consentimiento informado requerido';
        }
        if (!values.registerNumber) {
            errors.registerNumber = 'Número de registro del consentimiento requerido';
        }
        if (!values.signDate) {
            errors.signDate = 'Fecha de firma del consentimiento requerida';
        }
        if (!values.assays?.map(item => item.checked).reduce((previousValue, currentValue) => previousValue || currentValue?.length,
            false)) {
            errors.assayMessage = 'Ensayos en los que participa el paciente requeridos';
        }
        values.assays?.forEach((element, i) => {
            if (element.checked?.[0] && !element.initialdate) {
                if (!errors.assays) {
                    errors.assays = {};
                }
                errors.assays[i] = { initialdate: `Fecha de inicio en ${element.display} requerida` };
            }
        });
        return errors;
    };

    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        setLoading(true);
        values.identifier = await Patient.generatePatientIdentifier();
        if (!values.alias) {
            values.alias = values.identifier;
        }

        await Patient.createPatient({ values, practitionerRole })
            .then(() => {
                Patient.findByIdentifier(values.identifier).then(data => {
                    Patient.updatePatientWithConsent(values, data).then(() => {
                        navigate(`/patients/${data.id}`);
                    });
                });

            }).catch((e, r) => {
                console.log(e, r);
                notification.error({ title: "Error en la creación del paciente", caption: "" });
            }).finally(function () {
                setSubmitting(false);
                setLoading(false);
            });
    };

    const propsConfirmCancel = {
        text: "¿Desea abandonar la creación de un paciente?",
        onSubmit: () => {
            navigate(-1);
            setShowConfirmCancel(false);
        },
        onClose: () => setShowConfirmCancel(false)
    };

    return (
        <Overlay>
            <div className="heading">
                <Breadcrumb noTrailingSlash>
                    <BreadcrumbItem><Link to="/patients">Pacientes</Link></BreadcrumbItem>
                    <BreadcrumbItem href="#" isCurrentPage>Inscribir nuevo paciente</BreadcrumbItem>
                </Breadcrumb>
            </div>

            <Formik validate={validate} onSubmit={onSubmit} validateOnMount={false} enableReinitialize={true}
                initialValues={initialValues}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
                    <Form className="enrol-patient-form" onSubmit={handleSubmit}>
                        <div>
                            <Grid >
                                <Column lg={4} sm={4}>
                                    <TextInput
                                        id="alias"
                                        name="alias"
                                        invalid={errors.alias && touched.alias}
                                        invalidText={errors.alias}
                                        labelText="Alias (opcional)"
                                        placeholder=""
                                        helperText="No indique ningún valor que permita identificar a la persona"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.alias}

                                    />
                                </Column>
                                <Column lg={3} sm={3}>
                                    <TextInput
                                        id="birthDate"
                                        name="birthDate"
                                        invalid={errors.birthDate && touched.birthDate}
                                        invalidText={errors.birthDate}
                                        labelText="Año de nacimiento"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.birthDate}
                                    />
                                </Column>

                                <Column lg={2} sm={2}>
                                    <RadioButtonGroup
                                        legend="Sexo"
                                        name="gender"
                                        value={values.gender}
                                        invalid={errors.gender && touched.gender}
                                        invalidText={errors.gender}
                                        onChange={e => {
                                            const event = { target: { value: e, name: 'gender' } };
                                            // event.target.value
                                            handleChange(event);
                                        }}
                                    >
                                        <legend className={`cds--label`}>Sexo</legend>

                                        <RadioButton
                                            labelText={`Mujer`}
                                            value="female"
                                            name="gender"
                                            id="female"
                                        />
                                        <RadioButton
                                            labelText={`Hombre`}
                                            value="male"
                                            name="gender"
                                            id="male"
                                        />
                                    </RadioButtonGroup>
                                </Column>

                            </Grid>
                            <Grid >
                                <Column lg={2} sm={2}>
                                    <legend className={`cds--label`}>Firma Consentimiento</legend>
                                    <Checkbox
                                        labelText={`Consentimiento informado`}
                                        id="consentimiento"
                                        invalid={errors.consentimiento}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </Column>

                                <Column lg={4} sm={4}>
                                    <TextInput
                                        id="registerNumber"
                                        name="registerNumber"
                                        invalid={errors.registerNumber && touched.registerNumber}
                                        invalidText={errors.registerNumber}
                                        labelText="Número de registro del consentimiento informado"
                                        placeholder=""
                                        helperText=""
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.registerNumber}
                                    />
                                </Column>
                                <Column lg={4} sm={4}>
                                    <DatePicker locale={'es'}
                                        dateFormat="d/m/Y"
                                        datePickerType="single"
                                        name="signDate"
                                        id="signDate"
                                        value={values.signDate}
                                        onChange={range => {
                                            const event = { target: { value: range[0], name: 'signDate' } };
                                            handleChange(event);
                                        }}
                                    >
                                        <DatePickerInput
                                            id="signDateInput"
                                            placeholder="dd/mm/yyyy"
                                            labelText="Fecha de firma del consentimiento informado"
                                            autoComplete="off"
                                        />
                                    </DatePicker>
                                </Column>
                            </Grid>
                            <Grid >
                                <Column lg={8} sm={4}>
                                    <legend className={`cds--label`}>Paciente de COVID-control</legend>
                                    <Checkbox
                                        labelText={`Marque si el paciente es COVID-control, es decir, ha sufrido COVID y no padece secuelas. Deje desmarcado para pacientes COVID-persistente.`}
                                        id="patientCovidControlType"
                                        onChange={event => {
                                            // necesario para actualizar el valor del campo
                                            handleChange(event);
                                        }}
                                    />
                                </Column>
                            </Grid>
                            <FieldArray name="assays">
                                <div className='ensayos'>
                                    <legend className="cds--label">Ensayos</legend>
                                    {
                                        values.assays && values.assays.map((assay, i) => {
                                            return <div className='box' key={`key-${assay.code}`} >
                                                <Checkbox
                                                    labelText={assay.display}
                                                    id={`assays.${i}.checked`}
                                                    name={`assays.${i}.checked`}
                                                    invalid={`errors.assays.${i}`}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    className="item-box"
                                                />
                                                <DatePicker locale={'es'}
                                                    dateFormat="d/m/Y"
                                                    datePickerType="single"
                                                    name={`assays.${i}.initialdate`}
                                                    id={`assays.${i}.initialdate`}
                                                    value={values.assay}
                                                    onChange={arrayValues => {
                                                        const event = { target: { value: arrayValues[0], name: `assays.${i}.initialdate` } };
                                                        handleChange(event);
                                                    }}>
                                                    <DatePickerInput
                                                        id={`assays.${i}.initialdateinput`}
                                                        name={`assays.${i}.initialdateinput`}
                                                        placeholder="dd/mm/yyyy"
                                                        labelText={`Fecha de inicio del ensayo ${assay.display}`}
                                                        autoComplete="off"
                                                        invalid={Boolean(errors?.assays && errors.assays[i]?.initialdate && values.assays[i]?.checked?.[0])}
                                                        invalidText={errors?.assays && errors.assays[i]?.initialdate}
                                                    />
                                                </DatePicker>
                                            </div>;
                                        })
                                    }
                                </div>
                            </FieldArray>
                        </div>
                        <div className='component-button-footer cds--css-grid'>
                            <ButtonSet className='cds--sm:col-span-8 cds--lg:col-span-8 cds--css-grid-column'>
                                <Button kind="secondary" onClick={() => setShowConfirmCancel(true)} disabled={isSubmitting}>Cancelar</Button>
                                <Button type="submit" renderIcon={Add} disabled={isSubmitting || !isValid}>{loading ? <InlineLoading description={'Guardando paciente'}></InlineLoading> : <span>Inscribir paciente</span>}</Button>
                            </ButtonSet>
                        </div>
                    </Form>
                )}
            </Formik>
            {showConfirmCancel && <ConfirmAction props={propsConfirmCancel} />}
        </Overlay>
    );
}
