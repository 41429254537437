import React, { useState, useEffect } from 'react';
import QuestionnaireComponent from 'questionnaire';
import Questionnaire from 'api/Questionnaire';
import QuestionnaireResponse from 'api/QuestionnaireResponse';
import useNotification from 'hooks/useNotification';

import { RequestQuote } from '@carbon/react/icons';
import { Link, SkeletonText } from '@carbon/react';

import 'components/Launchers/QuestionnaireLauncher.scss';

/**
 * Componente que permite visualizar y responder un cuestionario.
 * @param {*} activity Activity que contiene la referencia al cuestionario a mostrar.
 * @param {*} patient
 * @param {*} carePlan
 * @param {*} practitionerRole
 * @param {*} questionnaireResponseReference "reference" FHIR al QuestionnaireResponse que contiene las respuestas. Puede venir vacío 
 *                si se desea mostrar el componente para insertar un nuevo cuestionario.
 * @returns 
 */
export default function QuestionnaireLauncher({ activity, patient, carePlan, practitionerRole, questionnaireResponseReference, handleUpdateCarePlan }) {
  const [questionnaire, setQuestionnaire] = useState(null);
  const [questionnaireResponse, setQuestionnaireResponse] = useState(null);
  const [loadQuestionnaire, setLoadQuestionnaire] = useState(false);
  const notification = useNotification();
  const isQuestionnaire = activity.type === 'Questionnaire';

  const onClose = () => {
    setQuestionnaire(null);
  };

  const onSubmit = (questionnaireResponse) => {
    handleUpdateCarePlan && handleUpdateCarePlan(questionnaireResponse.questionnaire, true, questionnaireResponse.id);
    setQuestionnaire(null);
    searchQuestionnaireResponse();
    notification.success({ title: "Operación realizada", caption: "Cuestionario guardado correctamente" });
  };

  const onSavingError = () => {
    notification.error({ title: "Error guardando el cuestionario", caption: "No ha sido posible guardar los datos del cuestionario. Contacte con el administrador." });
  };

  const onClickSearchQuestionnaireInfo = () => {
    onClickSearchQuestionnaire();
  };

  const onClickSearchQuestionnaire = () => {
    const questionnaireId = activity.referenceUuid;
    if (isQuestionnaire) {
      Questionnaire.getQuestionnaire(questionnaireId)
        .then(data => {
          setQuestionnaire(data);
        }).catch(e => {
          console.log("Error cargando Questionnaire " + questionnaireId, e);
          notification.error({
            title: "Error cargando el cuestionario",
            caption: "No ha sido posible cargar los datos del cuestionario. Contacte con el administrador."
          });
        });
    } else {
      console.log("Error cargando Questionnaire " + questionnaireId);
    }
  };

  const searchQuestionnaireResponse = () => {
    if (!questionnaireResponseReference) {
      setLoadQuestionnaire(true);
      return;
    }

    QuestionnaireResponse.getQuestionnaireResponse(questionnaireResponseReference.reference.replace('QuestionnaireResponse/', ''))
      .then(data => {
        setQuestionnaireResponse(data);
        setLoadQuestionnaire(true);
      }).catch(e => {
        console.log("Error cargando QuestionnaireResponse " + questionnaireResponseReference, e);
        notification.error({
          title: "Error cargando la respuesta al cuestionario",
          caption: "No ha sido posible cargar los datos de la respuesta al cuestionario. Contacte con el administrador."
        });
        setLoadQuestionnaire(true);
      });
  }

  useEffect(() => {
    searchQuestionnaireResponse();
    return () => {
      setQuestionnaireResponse();
      setLoadQuestionnaire();
    };
  }, [questionnaireResponseReference]);

  const contextData = {
    patient: patient,
    practitionerRole: practitionerRole,
    carePlan: carePlan
  };
  return (
    <>

      {loadQuestionnaire ?
        <Link
          disabled={questionnaireResponse?.status === "entered-in-error"}
          className='questionnaire-link coperia-clickable'
          onClick={questionnaireResponse?.status !== "entered-in-error" ? onClickSearchQuestionnaireInfo : () => { }}
          renderIcon={RequestQuote} >
          {(questionnaireResponseReference ? 'Cuestionario cumplimentado' : 'Responder cuestionario')}</Link>
        :
        <SkeletonText className="activity-outcome-row skeleton-text" />
      }

      {
        questionnaire && loadQuestionnaire &&
        <QuestionnaireComponent
          modal
          questionnaire={questionnaire}
          questionnaireResponse={questionnaireResponse}
          contextData={contextData}
          onClose={onClose} onSubmit={onSubmit} onSavingError={onSavingError}
        />
      }
    </>
  );
}