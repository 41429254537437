// Funcionalidades para gestión de recurso Observation
import http from 'common/HttpService';
import { OBSERVATION_STATUS_PRELIMINARY } from 'api/constants/ObservationStatus';
import cloneDeep from 'lodash/cloneDeep';

const Observation = {
  getTemplate: (observationDefinition) => {
    const observation = cloneDeep({
      resourceType: "Observation",
      meta: observationDefinition.meta,
      basedOn: [],
      status: OBSERVATION_STATUS_PRELIMINARY,
      category: observationDefinition.category,
      code: observationDefinition.code,
      method: cloneDeep(observationDefinition.method),
      subject: {},
      encounter: {},
      performer: []
    });
    return observation;
  },
  createObservation: (data, returnRepresentation = false) => {
    const headers = returnRepresentation ? { headers: { 'Prefer': 'return=representation' } } : {};
    return http.post('/Observation', data, headers);
  },
  updateObservation: (data) => {
    return http.put(`/Observation/${data.id}`, data);
  },
  get: async (observationId) => {
    const res = await http.get(`/Observation/${observationId}`);
    return res.data;
  },
  findBy: async (query) => {
    const res = await http.get(`/Observation?${query}`);
    const { entry = [] } = res.data;
    return entry ? entry : null;
  },
  search: async (query) => {
    const res = await http.post(`/Observation/_search?${query}`);
    const { entry = [] } = res.data;
    return entry ? entry : null;
  },
  deleteObservation: (observationId) => {
    return http.delete(`/Observation/${observationId}`);
  }
};

export default Observation;