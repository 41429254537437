const authenticationHolder = {};

/** In-memory authentication store */
export default class AuthStore {

  static setAuthentication(auth) {
    authenticationHolder.authentication = auth;
  }

  static setRocketChatAuthId(id) {
    authenticationHolder.rcAuthId = id;
  }

  static setRocketChatAuthToken(token) {
    authenticationHolder.rcAuthToken = token;
  }

  static getRocketChatAuthId() {
    return authenticationHolder.rcAuthId || null;
  }

  static getRocketChatAuthToken() {
    return authenticationHolder.rcAuthToken || null;
  }

  static getAuthentication() {
    return authenticationHolder.authentication || null;
  }

  static clearAuthentication() {
    authenticationHolder.authentication = null;
  }

}