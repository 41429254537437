// Funcionalidades para gestión de recurso Patient
import http from "common/HttpService";
import {
  SYSTEM_ASSAY,
  SYSTEM_ASSAY_INITIAL_DATE,
} from "api/constants/tags/AssayTags";
import PractitionerRole from "api/PractitionerRole";
import { DOCTOR_CODE } from "api/constants/tags/PractitionerRoleTags";
import {
  PATIENT_IDENTIFIER,
  PATIENT_ONE_TIME_PASSWORD,
  PATIENT_PROFILE,
  PATIENT_USERNAME,
  PATIENT_TYPE_SYSTEM,
  PATIENT_TYPE_VALUES,
  PATIENT_TYPE_COVID_CONTROL
} from "./constants/tags/PatientTags";

import { getGender } from "common/PatientUtils";

import { formatDate } from "common/DateTimeUtils";

import Consent from "api/Consent";

const NA = "NA";

const Patient = {
  getAlias: (resource) => {
    if (!resource.name || resource.name.length === 0) {
      return NA;
    }
    const alias = resource.name[0];
    return alias.text;
  },
  getBirthDate: (resource) => {
    if (!resource.birthDate || resource.birthDate.length === 0) {
      return NA;
    }

    return resource.birthDate;
  },
  getGender: (resource) => {
    return getGender(resource.gender);
  },
  getIdentifier: (resource) => {
    if (!resource.identifier || resource.identifier.length === 0) {
      return NA;
    }
    const identifier = resource.identifier[0];
    return identifier.value;
  },
  getId: (resource) => {
    if (!resource.id || resource.id.length === 0) {
      return NA;
    }
    return resource.id;
  },
  getAssays: (resource) => {
    if (!resource.meta || resource.meta.length === 0) {
      return NA;
    }

    if (!resource.meta.tag || resource.meta.tag.length === 0) {
      return NA;
    }
    const assays = [];
    for (let assay of resource.meta.tag) {
      if (assay.system === SYSTEM_ASSAY) {
        assays.push(assay.display);
      }
    }
    return assays;
  },
  getAssay: (resource) => {
    if (!resource.meta || resource.meta.length === 0) {
      return NA;
    }

    if (!resource.meta.tag || resource.meta.tag.length === 0) {
      return NA;
    }

    let assay = "";
    for (let index in resource.meta.tag) {
      if (resource.meta.tag[index].system === SYSTEM_ASSAY) {
        if (!assay) {
          assay = resource.meta.tag[index].display;
        } else {
          assay = assay + ", " + resource.meta.tag[index].display;
        }
      }
    }
    return assay;
  },
  getPatientTypeTagFromCheckBox: (isCovidControl) => {
    return _getPatientTypeTag(isCovidControl);
  },
  getPatientTypeTagFromPatient: (resource) => {
    return resource.meta.tag.find(item => item.system === PATIENT_TYPE_SYSTEM);
  },
  isPatientTypeCovidControl: (resource) => {
    return resource && resource.meta.tag.find(item => item.system === PATIENT_TYPE_SYSTEM && item.code === PATIENT_TYPE_COVID_CONTROL);
  },
  getName: (resource) => {
    if (!resource.name || resource.name.length === 0) {
      return NA;
    }

    const name = resource.name[0];
    if (name.use === "nickname") {
      return name.text;
    } else {
      return NA;
    }
  },
  generateRandomPassword: () => {
    const suffix = Date.now().toString().substr(-6);
    return `P${suffix}`;
  },
  generateUsername: async () => {
    const totalPatients = await Patient.countPatients();
    return "UC" + (totalPatients + 1);
  },
  getPatient: async (id) => {
    const res = await http.get(`/Patient/${id}`);
    return res.data;
  },
  getPatients: async ({ page = 0, pageSize = 10, searchValues }) => {
    const field =
      searchValues && searchValues.field ? searchValues.field : "name";
    let filter = "";
    if (field === "name") {
      filter +=
        searchValues && searchValues.term
          ? `&${field}:contains=${searchValues.term}`
          : "";
    } else {
      filter +=
        searchValues && searchValues.term
          ? `&${field}=${searchValues.term}`
          : "";
    }
    filter +=
      searchValues && searchValues.birthDate
        ? `&birthdate=${searchValues.birthDate}`
        : "";
    if (searchValues && searchValues.genders) {
      let queryGenders = searchValues.genders.reduce(
        (previousValue, currentValue) => previousValue + currentValue + ",",
        ""
      );
      if (queryGenders) {
        filter += `&gender=${queryGenders}`;
      }
    }
    if (searchValues && searchValues.assays) {
      let queryAssays = searchValues.assays.reduce(
        (previousValue, currentValue) =>
          previousValue + `${SYSTEM_ASSAY}|` + currentValue.id + ",",
        ""
      );
      if (queryAssays) {
        filter += `&_tag=${queryAssays}`;
      }
    }
    if (searchValues.sort) {
      filter += `&_sort=${searchValues.sort}`;
    }
    const res = await http.get(
      `/Patient?_page=${page}&active=true&_count=${pageSize}${filter}`
    );
    return res.data;
  },
  countPatients: async () => {
    const res = await http.get(`/Patient?_summary=count`);
    return res.data.total;
  },
  findByIdentifier: async (identifier) => {
    const res = await http.get(`/Patient?identifier=${identifier}`);
    const { entry = [] } = res.data;
    if (entry.length === 0) {
      return entry[0];
    }
    return entry[0].resource;
  },
  findByFilters: async (filters) => {
    const res = await http.get(`/Patient` + filters);
    const { entry = [] } = res.data;
    return entry;
  },
  generatePatientIdentifier: async () => {
    var todayDate = new Date();
    var todayMonth = todayDate.getMonth() + 1;
    var todayDayOfMonth = todayDate.getDate();
    var patientNumber = (await Patient.countPatients()) + 1;
    var patientCode =
      "COPERIA-" + patientNumber + "-" + todayDayOfMonth + "-" + todayMonth;

    return patientCode;
  },
  createPatient: async ({ values, practitionerRole }) => {
    const tagArray =
      values.assays
        .filter((assay) => !!assay.checked?.[0])
        .map((assay) => {
          return {
            system: SYSTEM_ASSAY,
            code: assay.code,
            display: assay.display,
          };
        }) || [];

    tagArray.push(_getPatientTypeTag(values.patientCovidControlType));

    const data = {
      resourceType: "Patient",
      meta: {
        profile: [PATIENT_PROFILE],
        tag: tagArray,
      },
      active: true,
      extension: [
        {
          url: PATIENT_USERNAME,
          valueString: await Patient.generateUsername(),
        },
        {
          url: PATIENT_ONE_TIME_PASSWORD,
          valueString: await Patient.generateRandomPassword(),
        },
      ],
      identifier: [
        {
          system: PATIENT_IDENTIFIER,
          value: values.identifier,
        },
      ],
      name: [
        {
          use: "nickname",
          text: values.alias,
        },
      ],
      gender: values.gender,
      birthDate: values.birthDate,
    };

    let practitionerRoleCode =
      PractitionerRole.getPractitionerRoleCodeId(practitionerRole);
    if (practitionerRoleCode === DOCTOR_CODE) {
      data.generalPractitioner = [
        {
          reference: `PractitionerRole/${practitionerRole.id}`,
          display: `${practitionerRole.practitioner.display}`,
        },
      ];
    }

    values.assays
      .filter((assay) => !!assay.checked?.[0])
      .forEach((assay) => {
        data.extension.push({
          url: SYSTEM_ASSAY_INITIAL_DATE + assay.code,
          valueDate: formatDate(assay.initialdate),
        });
      });
    return http.post("/Patient", data);
  },
  updatePatientWithConsent: async (values, patient) => {
    patient.contained = [];
    patient.contained.push(await Consent.createConsent(values));
    return http.put("/Patient/" + patient.id, patient);
  },
  updatePatient: async (patient) => {
    const headers = { headers: { 'Prefer': 'return=representation' } };
    return http.put(`/Patient/${patient.id}`, patient, headers);
  },
  deletePatient: (id) => {
    return http.delete(`/Patient/${id}`);
  },
  getConsent: (patient) => {
    if (typeof patient.contained !== "undefined") {
      for (let containedItem of patient.contained) {
        if (containedItem.resourceType === "Consent") {
          return containedItem;
        }
      }
    }
    return null;
  },
  updatePatient: async (data) => {
    const headers = { headers: { Prefer: "return=representation" } };
    return await http.put(`/Patient/${data.id}`, data, headers);
  },
};

const _getPatientTypeTag = (isPatientCovidControlType) => {
  const patientType = PATIENT_TYPE_VALUES.find(
    item => !!item.default === !isPatientCovidControlType
  );
  return {
    system: PATIENT_TYPE_SYSTEM,
    code: patientType.code,
    display: patientType.display,
  };
};

export default Patient;
