import React from "react";

import "content-site/_content-site.scss";
import { useEffect } from "react";
import qrAndroid from 'content-site/QR_COPERIA_Android.png';
import qrIOS from 'content-site/QR_COPERIA_iOS.png';

export default function ContentSite() {

  useEffect(() => {
    document.getElementById('coperia-content-site').src = process.env.REACT_APP_CONTENT_SITE;
  }, []);

  return (
    <div className="row">
      <div className="column-left">
        <div className="intro">
          <h1>Contenidos para el paciente</h1>
          <p>
            En esta sección se muestran los contenidos que el paciente tendrán
            accesibles desde la aplicación móvil.
          </p>
          <p>
            El paciente encontrará consejos para llevar una vida sana y
            equilibrada.
          </p>
          <p>
            Además, si lo desea, podrá descargar la aplicación móvil COPERIA escaneando uno de los siguientes códigos QR (Android o IOS)
          </p>
          <img src={qrAndroid} alt="QR COPERIA Android" className="coperia-qr-code"></img>
          <img src={qrIOS} alt="QR COPERIA iOS" className="coperia-qr-code"></img>
        </div>
      </div>
      <div className="column-right">

        <div className="marvel-device s5 black">
          <div className="top-bar"></div>
          <div className="sleep"></div>
          <div className="camera"></div>
          <div className="sensor"></div>
          <div className="speaker"></div>
          <div className="screen">
            <div className="container-mobile">
              <iframe
                id="coperia-content-site"
                title="Consejos para los pacientes"
                src=""
                sandbox="allow-scripts allow-same-origin"
                loading="eager"
                className="responsive-iframe"
              />
            </div>
          </div>
          <div className="home"></div>
        </div>

      </div>
    </div>
  );
}
