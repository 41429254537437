import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Button, ButtonSet, CopyButton, SkeletonText, TextArea, Tile } from '@carbon/react';
import { Reset, Save, ArrowLeft, Chat } from '@carbon/react/icons';
import { User } from '@carbon/pictograms-react';
import { ASSAY_MAP, SYSTEM_ASSAY, ASSAYS_INITIAL_DATE_URL_LIST, SYSTEM_ASSAY_INITIAL_DATE } from 'api/constants/tags/AssayTags';
import { getExtension, getExtensionString, getTagsBySystem, mergeTagList } from 'api/constants/tags/TagFunctions';
import { PATIENT_SUMMARY_BUTTON_MAP, PATIENT_TYPE_SYSTEM, PATIENT_USERNAME } from 'api/constants/tags/PatientTags';
import { useAuth } from 'auth/Auth';
import { getGender } from 'common/PatientUtils';
import Patient from 'api/Patient';
import { dateTimeToLocalDate, toISOString } from 'common/DateTimeUtils';
import { useNavigate } from 'react-router-dom';
import useNotification from 'hooks/useNotification';
import PatientAssays from 'components/PatientAssays/PatientAssays';
import ConfirmAction from 'components/ConfirmAction/ConfirmAction';
import PatientTypeSelector from 'patients/PatientTypeSelector';
import cloneDeep from 'lodash.clonedeep';
import ChatComponent from 'components/RocketChat/ChatComponent';
import PatientCovidPersistentPrediction from './PatientCovidPersistentPrediction';

/**
 * Componente que muestra los datos del paciente registrado y permite actualizar algunos de ellos.
 * 
 * @param {Patient} patient con el objeto que contiene los datos del paciente a mostrar 
 * @param {Function} onPatientUpdate función a ejecutar cuando se actualizan los datos de paciente
 */
export default function PatientSummary({ patient, onPatientUpdate }) {
  const navigate = useNavigate();
  const notification = useNotification();
  const auth = useAuth();
  const practitionerRole = auth.practitionerRole;
  const [localPatient, setLocalPatient] = useState();
  const [consent, setConsent] = useState();
  const [assays, setAssays] = useState();
  const [showAssayGroups, setShowAssayGroups] = useState(false);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [showConfirmReturn, setShowConfirmReturn] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const notesTextArea = useRef();
  const showChat = (process.env.REACT_APP_ENABLE_CHAT === 'true');

  /**
   * Initializa la lista de ensayos a los que el paciente está asociado y sus datos. La lista de ensayos 
   * se obtiene del objeto Patient pasado por parámetro.
   */
  const initAssays = useCallback(() => {
    const patientAssays = [];
    if (patient) {
      patientAssays.push(...getTagsBySystem(patient.meta, SYSTEM_ASSAY));

      const practitionerAssays = [];
      if (practitionerRole) {
        practitionerAssays.push(...getTagsBySystem(practitionerRole.meta, SYSTEM_ASSAY));
      }
      const availableAssays = mergeTagList(patientAssays, practitionerAssays);
      const checkedListOfAssays = availableAssays.map(assay => {
        const extensionInitialDate = getExtension(patient.extension, SYSTEM_ASSAY_INITIAL_DATE + assay.code);
        const initialDate = extensionInitialDate?.[0]?.valueDate ? extensionInitialDate[0].valueDate : "";
        const checked = patient.meta?.tag && patient.meta.tag.filter(tag => tag.system === SYSTEM_ASSAY && tag.code === assay.code).length > 0;
        return {
          code: assay.code,
          label: assay.display,
          checked: checked,
          initialDate: initialDate
        };
      }
      );
      setAssays(checkedListOfAssays);
    }
  }, [patient]);

  /**
   * Inicializa las notas asociadas al paciente.
   */
  const initNotes = () => {
    if (patient) {
      notesTextArea.current.value = getNotes(patient).note[0].text;
    }
  };

  /**
   * Carga los datos del paciente en los estados del componente.
   */
  useEffect(() => {
    if (patient) {
      setLocalPatient(patient);
      initNotes();
      setConsent(Patient.getConsent(patient));
    }
  }, [patient]);

  useEffect(() => {
    initAssays();
  }, [practitionerRole, patient]);

  /**
   * Deshae las modificaciones realizadas en los campos de la pantalla.
   */
  function reset() {
    initAssays();
    initNotes();
  }

  function save() {
    if (validateAssayFields()) {
      const checkedAssayTags = assays.filter(assay => assay.checked).map(assay => ASSAY_MAP.get(assay.code));
      const tagsLocalPatientNonUpdateable = localPatient.meta?.tag ? localPatient.meta.tag
        .filter(tag => tag.system !== SYSTEM_ASSAY) : [];
      localPatient.meta.tag = tagsLocalPatientNonUpdateable.concat(checkedAssayTags);
      const extensionLocalPatient = localPatient.extension ? localPatient.extension
        .filter(extension => !ASSAYS_INITIAL_DATE_URL_LIST.includes(extension.url)) : [];
      const extensionInitialDates = assays.filter(assay => assay.checked).map(assay => {
        return {
          url: SYSTEM_ASSAY_INITIAL_DATE + assay.code,
          valueDate: assay.initialDate
        };
      });
      localPatient.extension = extensionLocalPatient.concat(extensionInitialDates);
      addNotes(notesTextArea.current.value);
      Patient.updatePatient(localPatient).then((response) => {
        notification.success({ title: "Operación realizada", caption: "El paciente ha sido modificado correctamente" });
        onPatientUpdate({ ...response.data });
      }).catch(() => {
        notification.error({ title: "Operación NO realizada", caption: "Ha ocurrido un error y no se ha podido modificar el paciente" });
      });
    } else {
      notification.error({ title: "Operación NO realizada", caption: "Los ensayos seleccionados deben tener fecha de inicio" });
    }

  }

  function notificationError(title, caption) {
    return notification.error({ title: title, caption: caption });
  }

  /**
   * Valida si todos los ensayos a los que está asociado el paciente tienen fecha.
   * 
   * @returns boolean
   */
  function validateAssayFields() {
    let validAssayValues = true;
    assays.forEach(assay => {
      if (assay.checked && (!assay.initialDate || assay.initialDate.length <= 0)) {
        validAssayValues = false;
      }
    });
    return validAssayValues;
  }

  function isAssignedToMeDisabled() {
    const patientPractitioner = localPatient?.generalPractitioner?.[0] ? localPatient.generalPractitioner[0].reference : '';
    const userLoggedId = practitionerRole ? `PractitionerRole/${practitionerRole.id}` : '';
    return patientPractitioner.substring(patientPractitioner.indexOf("PractitionerRole/")) === userLoggedId;
  }

  function assignToMe() {
    if (localPatient?.generalPractitioner?.[0]) {
      localPatient.generalPractitioner[0].reference = `PractitionerRole/${practitionerRole.id}`;
      localPatient.generalPractitioner[0].display = practitionerRole.practitioner.display;
    } else {
      localPatient.generalPractitioner = [{
        reference: `PractitionerRole/${practitionerRole.id}`,
        display: practitionerRole.practitioner.display
      }];
    }
    Patient.updatePatient(localPatient).then((response) => {
      notification.success({ title: "Operación realizada", caption: "Se ha asignado el paciente correctamente a su usuario" });
      onPatientUpdate({ ...response.data });
    }).catch(() => {
      notification.error({ title: "Operación NO realizada", caption: "Ha ocurrido un error y no se asignar el paciente a su usuario" });
    });
  }

  function toggleAssayGroups() {
    setShowAssayGroups(!showAssayGroups);
  }

  /**
   * Añade o actualiza una nota a la copia local de los datos del paciente.
   * @param {String} notesText texto de la nota 
   */
  function addNotes(notesText) {
    if (notesText) {
      const clinicalImpression = getNotes(localPatient);
      clinicalImpression.note[0].time = toISOString(new Date());
      clinicalImpression.note[0].text = notesText;
      if (localPatient.contained?.length > 0) {
        const otherContainedResources = localPatient.contained.filter(c => c.resourceType !== 'ClinicalImpression');
        localPatient.contained = otherContainedResources.concat(clinicalImpression);
      } else {
        localPatient.contained = [clinicalImpression];
      }
    }
  }

  /**
   * Recupera las notas asociadas al paciente que han sido registradas como ClinicalImpression. En caso de no existir,
   * inicializa un objeto para las notas.
   * @returns an Object representing the ClinicalImpression associated to the pacient
   */
  function getNotes(currentPatient) {
    const clinicalImpression = currentPatient.contained?.length > 0 && currentPatient.contained.filter(c => c.resourceType === 'ClinicalImpression');
    if (clinicalImpression && clinicalImpression.length > 0) {
      return clinicalImpression[0];
    } else {
      const now = new Date();
      return {
        resourceType: "ClinicalImpression",
        status: "completed",
        effectiveDateTime: toISOString(now),
        subject: {
          reference: `Patient/${currentPatient.id}`
        },
        note: [{
          time: toISOString(now),
          text: ''
        }]
      };
    }
  }

  /**
   * Función que actualiza este componente con la lista de nuevos datos de ensayo.
   * @param {Array} assays 
   */
  const onAssaysChangeHandler = (updatedAssays) => {
    setAssays(updatedAssays);
  };

  const onChangePatientType = (isCovidControlCheckbox) => {
    const tagsLocalPatientNonUpdateable = localPatient.meta?.tag ? localPatient.meta.tag
      .filter(tag => tag.system !== PATIENT_TYPE_SYSTEM) : [];
    const newPatientTypeTag = Patient.getPatientTypeTagFromCheckBox(isCovidControlCheckbox);
    console.log('Cambio a isCovidControl: ' + isCovidControlCheckbox);
    const clonedPatient = cloneDeep(localPatient);
    clonedPatient.meta.tag = tagsLocalPatientNonUpdateable.concat(newPatientTypeTag);
    setLocalPatient(clonedPatient);
  };

  const propsConfirmSave = {
    text: "¿Desea guardar los cambios?",
    onSubmit: () => {
      save();
      setShowConfirmSave(false);
    },
    onClose: () => setShowConfirmSave(false)
  };

  const propsConfirmReset = {
    text: "¿Desea deshacer los cambios?",
    onSubmit: () => {
      reset();
      setShowConfirmReset(false);
    },
    onClose: () => setShowConfirmReset(false)
  };

  const propsConfirmReturn = {
    text: "¿Desea volver a la lista de pacientes",
    onSubmit: () => {
      navigate('/patients');
      setShowConfirmReturn(false);
    },
    onClose: () => setShowConfirmReturn(false)
  };

  return (
    <div className='patient-summary'>
      <Tile>
        <div className='patient-data'>
          <div className='patient-general-info'>
            <div className='patient-items'>
              <div>
                <div className='patient-item'>
                  <legend><em>Año de nacimiento:</em></legend>
                  <div className='patient-item-response'>
                    {localPatient ? localPatient.birthDate : <SkeletonText />}
                  </div>
                </div>
                <div className='patient-item'>
                  <legend><em>Sexo:</em></legend>
                  <div className='patient-item-response'>
                    {localPatient ? getGender(localPatient.gender) : <SkeletonText />}
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='patient-item'>
                  <div className='patient-consent'>
                    <legend><em>Consentimiento informado</em></legend>
                    <div className='patient-consent--item'>
                      <legend><em>Fecha: </em></legend>
                      <div className='patient-item-response'>
                        {consent && dateTimeToLocalDate(consent.dateTime)}
                      </div>
                    </div>
                    <div className='patient-consent--item'>
                      <legend><em>Número de registro: </em></legend>
                      <div className='patient-item-response'>
                        {consent && consent.sourceAttachment.title}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='patient-item'>
                <div className='patient-item-response'>
                  <PatientTypeSelector patientTypeTag={localPatient && Patient.getPatientTypeTagFromPatient(localPatient)}
                    onChangePatientType={onChangePatientType} />
                </div>
              </div>
              <div className='patient-treatment'>
                <div className='botonera'>
                <ButtonSet>
                  <Button kind="tertiary" renderIcon={() => PATIENT_SUMMARY_BUTTON_MAP.get(showAssayGroups).icon}
                    iconDescription={PATIENT_SUMMARY_BUTTON_MAP.get(showAssayGroups).text}
                    onClick={() => toggleAssayGroups()}>Tipo de tratamiento</Button>
                  <PatientCovidPersistentPrediction patient={localPatient} />
                </ButtonSet>


                </div>
              </div>
              <div className='patient-assays'>
                <legend><em>Ensayos:</em></legend>
                <PatientAssays patient={localPatient} assays={assays} onAssaysChange={onAssaysChangeHandler} showAssayGroups={showAssayGroups}
                  practitionerRole={practitionerRole} />
              </div>
            </div>
          </div>
          <div className="patient-profile-wrapper">
            <div className='patient-profile'>
              <User width={128} height={128} />
            </div>
            <div className='patient-profile--item'>
              {localPatient ?
                <>
                  <span>{localPatient.identifier && localPatient.identifier[0] && localPatient.identifier[0].value}</span>
                  <CopyButton onClick={() => { navigator.clipboard.writeText(patient.identifier[0].value); }} />
                </>
                : <SkeletonText />}
            </div>
            <div className='patient-profile--item'>
              <span>{localPatient ? localPatient.name && localPatient.name.filter(n => n.use === 'nickname')[0].text : ''}</span>
            </div>
            {showChat && <Button kind="ghost" onClick={() => setOpenChat(true)} title="Abrir chat" renderIcon={Chat}></Button>}
          </div>
        </div>
        <div className="patient-notes">
          <div className='patient-practitioner'>
            <legend><em>Facultativo a cargo:</em></legend>
            <div className='patient-practitioner--response'>
              {localPatient ? localPatient.generalPractitioner && localPatient.generalPractitioner[0] ? localPatient.generalPractitioner[0].display : '' : <SkeletonText />}
            </div>
            <Button disabled={isAssignedToMeDisabled()} onClick={() => { assignToMe(); }} kind="ghost">Asignarme a mí</Button>
          </div>
          <TextArea labelText='Notas:' ref={notesTextArea}></TextArea>
        </div>
      </Tile>
      <div className='botonera'>
        <ButtonSet>
          <Button kind="secondary" renderIcon={ArrowLeft} onClick={() => setShowConfirmReturn(true)}>Volver</Button>
          <Button kind="tertiary" renderIcon={Reset} onClick={() => setShowConfirmReset(true)}>Restaurar</Button>
          <Button kind="primary" renderIcon={Save} onClick={() => setShowConfirmSave(true)}>Guardar</Button>
        </ButtonSet>
        {openChat && <ChatComponent username={getExtensionString(patient.extension, PATIENT_USERNAME).toString().toLowerCase()} onClose={() => setOpenChat(false)} notificationError={notificationError}></ChatComponent>}
        {showConfirmSave && <ConfirmAction props={propsConfirmSave} />}
        {showConfirmReset && <ConfirmAction props={propsConfirmReset} />}
        {showConfirmReturn && <ConfirmAction props={propsConfirmReturn} />}
      </div>
    </div>
  );
}
