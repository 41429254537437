export const REDUCER_ACTIONS = {
  PAIR: 'pair',
  PAIRED: 'paired',
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  START_NOTIFICATIONS: 'start_notifications',
  STOP_NOTIFICATIONS: 'stop_notifications',
  NEXT_NOTIFICATIONS: 'next_notifications',
  ADD_DATA: 'add_data',
  PROCESS_DATA: 'process_data',
  RESET_DATA: 'reset_data',
};

/**
 * Acciones realizadas con el dispositivo Bluetooth BeurerPO60 que deben reflejarse en el estado del 
 * dispositivo para actualizar la información que se obtiene de el.
 * 
 * No se implementa lógica de obtención de datos, solo se actualiza el estado del dipositivo con la inforamción
 * proporcionada por useBluetoothBeurerPO60.
 * 
 * @param {Object} state - Estado del bluetooth compuesto por un objeto como el siguiente:
    {
      device: null - dispositivo bluetooth
      deviceName: '' - nombre del dispositivo
      server: null - servidor que proporciona acceso al servicio
      primaryService: null - servicio que proporciona las características
      writeCharacteristic - null, características del dispositico para enviar comandos
      notifyCharacteristic - null, características del dispositico en la que recibir notificaciones
      dataToShow: '' - datos que se van a mostrar al usuario
      data: new Map() - mapa clave/valor donde se almacenarán los distintos datos que se capturen en l
          as notificaciones.
    };
 * @param {Object} action - Objeto en el que se reciben la acción a ejecutar y la carga de pago asociada a la acción:
    { 
      type: '' - Una de las acciones disponibles en REDUCER_ACTIONS, 
      payload: {} - Objeto que contendrá la información que modificará el state
    }
 * @returns Estado del bluetooth actualizado o en su defecto el estado sin cambios si ninguna operación 
      coincide con REDUCER_ACTIONS.
 */
export default function BeurerPO60BluetoothReducer(state, action) {
  const payload = action.payload;
  switch (action.type) {
    case REDUCER_ACTIONS.PAIR:
      console.log('Buscar dispositivos a emparejar');
      return { ...state};
    case REDUCER_ACTIONS.PAIRED:
      console.log(`Emparejado device ${payload.device.name}`);
      return { 
        ...state, 
        device: payload.device, 
        deviceName: payload.device.name,
        loading: true 
      };
    case REDUCER_ACTIONS.CONNECT:
      console.log(`Conectando al servicio en ${state.deviceName}`);
      return { 
        ...state,
        loading: true,
        server: payload.server,
        primaryService: payload.primaryService,
        writeCharacteristic: null,
        notifyCharacteristic: null,
        startTime: null,
        stopTime: null,
        dataBuffer: null,
        dataToShow: '',
        data: new Map(),
        notificationCounter: 0
      };
    case REDUCER_ACTIONS.DISCONNECT:
        console.log(`Desconectando device ${state.deviceName}`);
      return { 
        ...state,
        loading: false,
        device: null,
        server: null,
        primaryService: null,
        writeCharacteristic: null,
        notifyCharacteristic: null,
      };
    case REDUCER_ACTIONS.START_NOTIFICATIONS:
      console.log(`Iniciadas las notificaciones en device ${state.deviceName}`);
      return { 
        ...state,
        writeCharacteristic: payload.writeCharacteristic,
        notifyCharacteristic: payload.notifyCharacteristic,
        startTime: new Date().getTime(),
      };
    case REDUCER_ACTIONS.STOP_NOTIFICATIONS:
      return { 
        ...state, 
        stopTime: new Date().getTime()
      };
    case REDUCER_ACTIONS.NEXT_NOTIFICATIONS:
      console.log("Se solicita el envio de más información.");
      return state;     
    case REDUCER_ACTIONS.ADD_DATA:
      //console.log("Se añaden los datos recibidos al buffer de datos: %o", payload.dataBuffer);
      const newDataBuffer = [ ...(state.dataBuffer || []), ...payload.dataBuffer ];
      //console.log("Buffer de datos actualizado: %o", newDataBuffer);
      return { ...state, dataBuffer: newDataBuffer, notificationCounter: state.notificationCounter+1 };
    case REDUCER_ACTIONS.PROCESS_DATA:
      //console.log('Se han procesado los datos recuperados del dispositivo: %o', payload.data);
      return { ...state, data: payload.data, dataToShow:  { ...payload.dataToShow, device: state.deviceName }, dataBuffer: [] };
    case REDUCER_ACTIONS.RESET_DATA:
      console.log('Se eliminan los datos almacenados en el dispositivo.');
      return  { ...state, dataBuffer: [] };
    default:
      console.log("BeurerPO60BluetoothReducer: acción no reconocida.");
      return state;
  };
};