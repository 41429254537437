const estados = {
  "completed": "Realizado",
  "scheduled": "Programado",
  "active": "Pendiente de realizar",
  "not-done": "A revisar"

};

export function getEstado(code) {
  return estados[code];
}