import Keycloak from "keycloak-js";
import { Component } from "react";
import { AuthContext } from 'auth/Auth';
import Authentication from 'auth/Authentication';
import AuthStore from 'auth/AuthStore';
import PractitionerRole from 'api/PractitionerRole';

/** 
 * Provider to handle AuthContext state with OpenID Connect / OAuth 2.0
 * keycloak-js config (https://www.keycloak.org/docs/latest/securing_apps/#javascript-adapter-reference)
*/

// Configuración de ejemplo.
// Definir y leer de .env.[entorno] process.env.REACT_APP_KEYCLOAK_URL
const config = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: 'coperia',
  clientId: 'patient-management'
};

const kc = new Keycloak(config);

export default class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: false, failed: false, practitionerRole: null };
    kc.onAuthLogout = this.onAuthLogout;
    kc.onAuthSuccess = this.onAuthSuccess;
    kc.onAuthRefreshSuccess = this.onAuthRefreshSuccess;
  }

  componentDidMount() {
    kc.init({
      onLoad: 'login-required',
      pkceMethod: 'S256',
      scope: 'openid',
      checkLoginIframe: false // puede provocar recarga infinita si las cookies de keycloak no se almacenan correctamente
    }).catch(e => {
      this.setState({ failed: true });
      console.log("Authentication error", e);
    });
  };

  componentDidUpdate() {
    if (!this.state.practitionerRole) {
      PractitionerRole.getPractitionerRole(kc.idTokenParsed.practitionerRoleId).then((practitionerRole) => {
        this.setState((prevState) => { return { ...prevState, practitionerRole: practitionerRole }; });
      });
    }
  };

  getUser = () => {
    return {
      username: kc.idTokenParsed.preferred_username,
      id: kc.idTokenParsed.practitionerId
    };
  };

  getName = () => {
    return kc.idTokenParsed.name;
  }

  getPractitionerRoleId = () => {
    return kc.idTokenParsed.practitionerRoleId;
  }

  authenticate = () => {
    const { idTokenParsed, resourceAccess = [], token } = kc;
    // Info de usuario con datos contenidos en el idToken
    const { sub, name, preferred_username, email } = idTokenParsed;
    const user = { sub, name, username: preferred_username, email };

    // En Keycloak podemos usar los roles de reino (globales) y roles por recurso (client)
    // En este caso usamos los roles del client-id actual
    const resource = kc.clientId;
    const roles = resourceAccess[resource] ? resourceAccess[resource].roles : [];
    const auth = new Authentication(user, roles, token);
    AuthStore.setAuthentication(auth);
    this.setState({ authenticated: true });

  };

  onAuthSuccess = () => {
    this.authenticate();
  };

  

  onAuthRefreshSuccess = () => {
    console.log("Auth refresh success");
    this.authenticate();
  };

  onAuthLogout = () => {
    AuthStore.clearAuthentication();
    this.setState({ authenticated: false, practitionerRole: null });
  };

  isAuthenticated = () => {
    return this.state.authenticated;
  };

  isTokenExpired = () => {
    return kc.isTokenExpired();;
  };

  updateToken = async () => {
    var updated = await kc.updateToken();
    if(updated){
      this.authenticate();
    }
  };

  hasFailed = () => {
    return this.state.failed;
  };

  logout = () => {
    return kc.logout({ redirectUri: window.location.origin + process.env.PUBLIC_URL });
  };

  changePassword = () => {
    return kc.login({redirectUri: window.location.origin + process.env.PUBLIC_URL, action: "UPDATE_PASSWORD"});
  }

  render() {

    const value = {
      authentication: AuthStore.getAuthentication(),
      practitionerRole: this.state.practitionerRole,
      logout: this.logout,
      isAuthenticated: this.isAuthenticated,
      hasFailed: this.hasFailed
    };

    return (
      <AuthContext.Provider value={value}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}