import React, { useState, useEffect } from 'react';
import {
  DataTable,
  DataTableSkeleton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableExpandHeader,
  TableHeader,
  TableBody,
  TableExpandRow,
  TableCell,
  TableExpandedRow,
  TableToolbar,
  TableToolbarContent,
  Button,
  MultiSelect,
  Dropdown,
  ButtonSet
} from '@carbon/react';
import { ArrowLeft, Add, UpdateNow, CheckmarkOutline, EventSchedule } from '@carbon/react/icons';
import { useNavigate } from 'react-router-dom';
import useNotification from 'hooks/useNotification';

import CarePlan from 'api/CarePlan';
import ServiceRequest from 'api/ServiceRequest';

import { findServiceRequestByCarePlanId, updateServiceRequestStatus } from 'components/ServiceRequest/ServiceRequestService';
import { findCommunicationsByPatientAndAssay } from 'components/Communication/CommunicationService';

import { usePatient } from 'patients/PatientContext';
import { useAuth } from 'auth/Auth';

import { dateTimeToLocalDate, dateTimeToLocalTime, compareDateTimes, formatTime } from 'common/DateTimeUtils';
import { getEstado } from 'common/EstadoUtils';
import { mapQuestionnairesToActivities, mapServiceRequestsFromCarePlanToActivities, mapCommunicationsToActivities } from 'agenda/Activity';
import ActivityDetailsComponent from 'agenda/ActivityDetailsComponent';
import { customFilterRows } from 'agenda/CustomFilter';

import ParticipantsComponent from 'components/Views/ParticipantsComponent';
import LaunchManager from 'components/Launchers/LaunchManager';
import TableDateRangeSearch from 'components/Datatable/TableDateRangeSearch';

import ActivityPlanner from './ActivityPlanner';
import Questionnaire from 'api/Questionnaire';
import ActivityDefinition from 'api/ActivityDefinition';
import { addActivityQuestionnaireCarePlan, addActivityServiceRequestCarePlan, deleteActivityCarePlan, updateActivityQuestionnaireCarePlan, updateActivityServiceRequestCarePlan, updateStatusActivityQuestionnaireCarePlan } from 'components/CarePlan/CarePlanService';

import { ASSAY_PHASE_TREATMENT, SYSTEM_ASSAY_PHASE } from 'api/constants/tags/AssayTags';
import { hasCode } from 'api/constants/tags/TagFunctions';
import ConfirmAction from 'components/ConfirmAction/ConfirmAction';
import { findCountOfQuestionnaireResponsesCompletedAndInProgress, patchStatusQuestionnaireResponse } from 'components/QuestionnaireResponse/QuestionnaireResponseService';
import { findCountOfEncountersCompleted, patchStatusEncounter } from 'components/Encounter/EncounterService';

export default function PatientAgenda({ assay, loadTimestamp, activeTab, date }) {

  const notification = useNotification();
  const navigate = useNavigate();

  const patient = usePatient();
  const auth = useAuth();

  const [loading, setLoading] = useState();
  const [listOfCarePlan, setListOfCarePlan] = useState([]);
  const [selectedAssayPhases, setSelectedAssayPhases] = useState([]);
  const [currentCarePlan, setCurrentCarePlan] = useState({});
  const [activityPlanner, setActivityPlanner] = useState(false);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [activities, setActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [showConfirmReturn, setShowConfirmReturn] = useState(false);


  const [agenda, setAgenda] = useState([]);

  const searchListOfCarePlan = async () => {
    let localListOfCarePlan = [];
    if (patient && patient.id) {

      CarePlan.getCarePlansByPatientIdentifierAndAssaysAndAssayPhases(patient.id,
        [assay],
        selectedAssayPhases.map(item => { return item.id; }))
        .then(data => {
          //console.log("Actualizo a axenda de " + assay + ", loadTimestamp: " + loadTimestamp);
          localListOfCarePlan = data.map(entry => entry.resource);
        }).catch(e => {
          notification.error(
            {
              title: "Error cargando las agendas del paciente",
              caption: "No ha sido posible cargar los datos de la agenda del paciente. Contacte con el administrador."
            }
          );
        }).finally(() => {
          setListOfCarePlan(localListOfCarePlan);
        });
    }
  };

  /**
   * Actualiza la lista de CarePlans que el usuario puede seleccionar
   * a partir de los selectores de la tabla o del paciente.
   * Solo se lanza la conuslta si la pestaña activa es la actual.
   */
  useEffect(() => {
    if (assay && selectedAssayPhases && assay === activeTab) {
      setLoading(true);
      searchListOfCarePlan()
        .finally(setLoading(false));
    }
  },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [assay, selectedAssayPhases, loadTimestamp]);

  /**
   * Setea el CarePlan que se mostrará al usuario a partir de la
   * lista de CarePlans.
   */
  useEffect(() => {
    if (!listOfCarePlan.length) {
      setCurrentCarePlan({});
    } else if (!listOfCarePlan.includes(currentCarePlan)) {
      setCurrentCarePlan(listOfCarePlan[0]);
    }
  },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [listOfCarePlan]);

  /**
   * Actualiza los registros de la agenda del paciente cuando cambia el currentCarePlan.
   * Implica enriquecer los datos faltantes de las Activity de CarePlan seleccionado.
   */
  useEffect(() => {
    updateCarePlan();
  },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCarePlan]);

  useEffect(() => {
    if ((activities && activities.length > 0) || (questionnaires && questionnaires.length > 0) || selectedActivity) {
      setActivityPlanner(true);
    }
  }, [activities, questionnaires, selectedActivity]);

  /** Actualiza la visualización de la agenda con las actividades del CarePlan y comunicaciones del paciente */
  const updateCarePlan = () => {
    let localAgenda = [];
    let index = 0;
    setLoading(true);
    if (currentCarePlan?.id) {
      const questionnairesInCarePlan = mapQuestionnairesToActivities(currentCarePlan.activity);
      localAgenda.push(...questionnairesInCarePlan);

      const activitiyPromises = [];
      activitiyPromises.push(findServiceRequestByCarePlanId(currentCarePlan.id));
      // las comunicaciones se muestran solo si hay "tratamiento"
      if (hasCode(currentCarePlan.meta, SYSTEM_ASSAY_PHASE, ASSAY_PHASE_TREATMENT)) {
        activitiyPromises.push(findCommunicationsByPatientAndAssay(patient.id, [assay]));
      }
      Promise.allSettled(activitiyPromises).then(
        (results) => results.forEach(
          (result) => {
            if (result.status === 'fulfilled') {
              if (result.value?.[0]?.resourceType === 'ServiceRequest') {
                const serviceRequestsInCarePlan = mapServiceRequestsFromCarePlanToActivities(currentCarePlan.activity, result.value);
                localAgenda.push(...serviceRequestsInCarePlan);
              } else if (result.value?.[0]?.resourceType === 'Communication') {
                const communications = mapCommunicationsToActivities(result.value);
                localAgenda.push(...communications);
              }
            } else {
              console.log(result);
            }
          })
      ).finally(() => {
        localAgenda.sort((activityA, activityB) => compareDateTimes(activityA.date, activityB.date));
        localAgenda.forEach(item => item.id = (index++).toString());
        setAgenda(localAgenda);
        setLoading(false);
      });
    } else {
      setAgenda(localAgenda);
      setLoading(false);
    }
  };

  const handleChangeCurrentCarePlanSelection = (value) => {
    setCurrentCarePlan(value.selectedItem);
  };

  const handleChangeAssayPhaseSelection = (values) => {
    setSelectedAssayPhases(values.selectedItems);
  };

  const handleCloseModal = () => {
    setSelectedActivity();
    setSelectedDate();
    setSelectedTime();
    setActivities();
    setQuestionnaires();
    setActivityPlanner(false);
  };

  const searchQuestionnairesAndActivities = () => {
    if (!Object.keys(currentCarePlan).length) {
      notification.error(
        {
          title: "Error creando un nuevo registro",
          caption: "El paciente no dispone de ningún plan asistencial. Contacte con el administrador."
        }
      );
      return;
    }

    Questionnaire.getQuestionnaires().then(data => {
      setQuestionnaires(data.entry.map(e => e.resource));
    });

    ActivityDefinition.getActivities().then(data => {
      setActivities(data);
    });
  };

  const launchActivityPlanner = (row) => {
    if (agenda[row.id].type === "Questionnaire") {
      Questionnaire.getQuestionnaire(agenda[row.id].referenceUuid)
        .then(q => setSelectedActivity(buildActivityPlannerBody(q.title, q.id, q.resourceType)));
    } else {
      setSelectedActivity(buildActivityPlannerBody(agenda[row.id].name, agenda[row.id].referenceUuid, agenda[row.id].type));
    }
    setSelectedDate(dateTimeToLocalDate(row.cells[0].value));
    setSelectedTime(formatTime(dateTimeToLocalTime(row.cells[1].value)));
  };

  const buildActivityPlannerBody = (title, id, resourceType) => {
    return {
      title: title,
      id: id,
      resourceType: resourceType
    };
  };

  const updateOutcomeReferenceCarePlan = (referenceId, isQuestionnaire, outcomeReferenceId) => {
    if (isQuestionnaire) {
      const dataPatch = updateActivityQuestionnaireCarePlan(currentCarePlan, referenceId, outcomeReferenceId);
      CarePlan.updatePatch(dataPatch).then((response) => {
        setCurrentCarePlan(response.data);
      });
    } else {
      const dataPatchCarePlan = updateActivityServiceRequestCarePlan(currentCarePlan, referenceId, outcomeReferenceId);
      const dataPatchStatusServiceRequest = {
        id: referenceId.split("ServiceRequest/")[1],
        patch: [{
          op: "replace",
          path: `/status`,
          value: "completed"
        }]
      };
      CarePlan.updatePatch(dataPatchCarePlan).then((response) => {
        ServiceRequest.updatePatch(dataPatchStatusServiceRequest).then(() => {
          setCurrentCarePlan(response.data);
        });
      });
    }
  };

  const updateActivityCarePlan = (activityToUpdate, isQuestionnaire, activityDate) => {
    if (isQuestionnaire) {
      const referenceId = "Questionnaire/" + activityToUpdate.id;
      const dataPatch = updateActivityQuestionnaireCarePlan(currentCarePlan, referenceId, null, activityDate);
      CarePlan.updatePatch(dataPatch).then((response) => {
        setCurrentCarePlan(response.data);
      }).then(() => {
        notification.success({ title: "Operación realizada", caption: "Actividad actualizada correctamente." });
      });
    } else {

      const dataPatchStatusServiceRequest = {
        id: activityToUpdate.id,
        patch: [{
          op: "replace",
          path: `/occurrenceDateTime`,
          value: activityDate
        }]
      };

      ServiceRequest.updatePatch(dataPatchStatusServiceRequest).then(() => {
        updateCarePlan();
      }).then(() => {
        notification.success({ title: "Operación realizada", caption: "Actividad actualizada correctamente." });
      });
    }
    handleCloseModal();
  };

  const addActivityCarePlan = (activityToUpdate, isQuestionnaire, activityDate) => {
    if (isQuestionnaire) {
      const dataPatch = addActivityQuestionnaireCarePlan(currentCarePlan, activityToUpdate.id, activityDate);
      CarePlan.updatePatch(dataPatch).then((response) => {
        setCurrentCarePlan(response.data);
      }).then(() => {
        notification.success({ title: "Operación realizada", caption: "Actividad registrada correctamente." });
      });
    } else {
      const data = {
        carePlan: currentCarePlan,
        activityDefinition: activityToUpdate,
        activityDate: activityDate,
        patient: patient,
        practitionerRole: auth.practitionerRole
      };
      ServiceRequest.createServiceRequest(data)
        .then(serviceRequest => {
          const dataPatch = addActivityServiceRequestCarePlan(currentCarePlan, serviceRequest);
          CarePlan.updatePatch(dataPatch).then((response) => {
            setCurrentCarePlan(response.data);
          });
        }).then(() => {
          notification.success({ title: "Operación realizada", caption: "Actividad registrada correctamente." });
          handleCloseModal();
        }).catch((e) => {
          console.log(e);
          notification.error({ title: "Operación fallida", caption: "No se ha podido registrar la actividad. Consulte al administrador." });
        });
    }
  };

  const deleteActivityOld = (activityType, activityReferenceUuid, outcome) => {
    deleteActivityCarePlan(currentCarePlan, activityType, activityReferenceUuid, outcome).then(response => {
      notification.success({ title: "Operación realizada", caption: "Registro de actividad borrado correctamente." });
      setCurrentCarePlan(response.data);
    }).catch((e) => {
      console.log(e);
      notification.error({ title: "Operación fallida", caption: "No se ha podido borrar el registro de la actividad. Consulte al administrador." });
    });
  };

  /**
   * Función que desactiva los registros de actividades (QuestionnaireResponses y Encounters)
   * 
   * @param {*} activityType Tipo de la actividad
   * @param {*} carePlan recurso fhir con el CarePlan
   * @param {*} activityReferenceUuid id de la actividad
   * @param {*} outcome referencia al recurso fhir del registro de la actividad
   */
  const deleteActivity = (activityType, carePlan, activityReferenceUuid, outcome) => {
    if (activityType === "Questionnaire") {
      patchStatusQuestionnaireResponse(outcome, "entered-in-error").then(() => {
        findCountOfQuestionnaireResponsesCompletedAndInProgress(carePlan.id, activityReferenceUuid).then(count => {
          if (!count) {
            updateStatusActivityQuestionnaireCarePlan(carePlan, activityReferenceUuid, "scheduled").then(response => setCurrentCarePlan(response.data));
          } else {
            updateCarePlan();
          }
        });
      });
    }

    if (activityType === "ServiceRequest") {
      patchStatusEncounter(outcome, "cancelled").then(() => {
        findCountOfEncountersCompleted(activityReferenceUuid).then(count => {
          if (!count) {
            updateServiceRequestStatus(activityReferenceUuid, "active").then(() => updateCarePlan());
          } else {
            updateCarePlan();
          }
        });
      });
    }
  };


  const propsConfirmReturn = {
    text: "¿Desea volver a la lista de pacientes?",
    onSubmit: () => {
      navigate('/patients');
      setShowConfirmReturn(false);
    },
    onClose: () => setShowConfirmReturn(false)
  };

  return (
    <>
      {
        loading ?
          <DataTableSkeleton headers={[]} />
          :
          <DataTable
            rows={agenda}
            headers={
              [
                {
                  key: 'date',
                  header: 'Fecha'
                }, {
                  key: 'time',
                  header: 'Hora'
                }, {
                  key: 'name',
                  header: 'Actividad'
                },
                {
                  key: 'participants',
                  header: ' '
                },
                {
                  key: 'status',
                  header: 'Estado'
                },
                {
                  key: 'execute',
                  header: 'Ejecutar actividad'
                }, {
                  key: 'planner',
                  header: ' '
                }
              ]
            }
            filterRows={customFilterRows}
            isSortable
            render={({
              rows,
              headers,
              getHeaderProps,
              getRowProps,
              getTableProps,
              onInputChange
            }) => (
              <TableContainer className="coperia--data-table-container"
                title={currentCarePlan && currentCarePlan.title ? currentCarePlan.title : 'No se ha encontrado la agenda del paciente para este ensayo. Por favor, inténtelo de nuevo en unos minutos.'}
                description={currentCarePlan && currentCarePlan.description ? currentCarePlan.description : 'Una agenda de paciente es la lista de las actividades que debe realizar el paciente en una fase concreta de un ensayo. Por ejemplo, un paciente puede tener una o varias agendas de actividades para la fase de evaluación inicial, otra para el tratamiento y una para la evaluación final.'}
              >
                <TableToolbar className="coperia--table-toolbar">
                  <TableToolbarContent className="coperia--table-toolbar-content">
                    <TableDateRangeSearch id='agenda-date-range-picker' onChange={onInputChange} date={date} containerClass="date-range-picker-container"
                      locale={'es'} />
                    <MultiSelect id="fase-ensayo"
                      className="coperia--select--wrapper"
                      items={
                        [
                          { id: 'initial-evaluation', title: '1. Evaluación inicial' },
                          { id: 'treatment', title: '2. Tratamiento' },
                          { id: 'final-evaluation', title: '3. Evaluación final' }
                        ]
                      }
                      itemToString={item => { return item ? item.title : '-'; }}
                      selectedItems={selectedAssayPhases}
                      onChange={handleChangeAssayPhaseSelection}
                      label="Fase del ensayo"
                      selectionFeedback="top-after-reopen"
                    />
                    { /* Lista de planes asistenciales del paciente */}
                    <Dropdown id="plan-asistencial"
                      className="coperia--select--wrapper"
                      items={listOfCarePlan}
                      itemToString={item => { return item ? item.title : '-'; }}
                      selectedItem={currentCarePlan}
                      onChange={handleChangeCurrentCarePlanSelection}
                      label="Plan asistencial del paciente"
                    />
                    <Button onClick={() => { searchQuestionnairesAndActivities(); }}
                      renderIcon={Add}>
                      Nueva actividad
                    </Button>
                  </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()}>
                  <TableHead>
                    <TableRow>
                      <TableExpandHeader />
                      {headers.map(header => (
                        <TableHeader {...getHeaderProps({ header })}>
                          {header.header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <React.Fragment key={row.id}>
                        <TableExpandRow {...getRowProps({ row })}>
                          {
                            <>
                              <TableCell key={row.cells[0].id}>{dateTimeToLocalDate(row.cells[0].value)}</TableCell>
                              <TableCell key={row.cells[1].id}>{dateTimeToLocalTime(row.cells[1].value)}</TableCell>
                              <TableCell key={row.cells[2].id}>{row.cells[2].value}</TableCell>
                              <TableCell key={row.cells[3].id}>
                                <ParticipantsComponent participants={row.cells[3].value} status={row.cells[4].value}/>
                              </TableCell>
                              <TableCell key={row.cells[4].id}><div className='data-table-status-cointainer'>{row.cells[4].value === 'completed' ? <CheckmarkOutline className="status-icon completed" /> : <EventSchedule className="status-icon" />}{getEstado(row.cells[4].value)}</div></TableCell>
                              <TableCell key={`${row.cells[5].id}:execution`}>
                                <LaunchManager activity={agenda[row.id]}
                                  patient={patient}
                                  carePlan={currentCarePlan}
                                  practitionerRole={auth.practitionerRole}
                                  handleUpdateCarePlan={updateOutcomeReferenceCarePlan}
                                />
                              </TableCell>
                              <TableCell key={row.cells[6].id}>
                                {
                                  agenda[row.id]?.type !== 'Communication' &&
                                  <UpdateNow onClick={() => launchActivityPlanner(row)} arial-label='Replanificar' className='coperia-clickable' />
                                }
                              </TableCell>
                            </>
                          }
                        </TableExpandRow>
                        <TableExpandedRow id={`${row.id}-info`} colSpan={headers.length + 1}>
                          {
                            row.isExpanded && <ActivityDetailsComponent key={`${row.id}-activity-info`} id={`${row.id}-activity-info`}
                              activity={agenda[row.id]}
                              patient={patient}
                              carePlan={currentCarePlan}
                              practitionerRole={auth.practitionerRole}
                              handleDeleteActivity={deleteActivity}
                            />
                          }
                        </TableExpandedRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          />
      }
      {
        activityPlanner && <ActivityPlanner questionnaires={questionnaires} activities={activities} preselectedActivity={selectedActivity} selectedDate={selectedDate} selectedTime={selectedTime} handleUpdateCarePlan={selectedActivity ? updateActivityCarePlan : addActivityCarePlan} onClose={handleCloseModal} />
      }
      {showConfirmReturn && <ConfirmAction props={propsConfirmReturn} />}
      <div className='botonera'>
        <ButtonSet>
          <Button kind="secondary" renderIcon={ArrowLeft} onClick={() => setShowConfirmReturn(true)}>Volver</Button>
        </ButtonSet>
      </div>
    </>
  );
}