import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { DatePicker, DatePickerInput } from '@carbon/react';
import { Close } from '@carbon/icons-react';

import { dateRangeToLocaleString, dateStringToRange, isValidDate } from 'common/DateTimeUtils';

import 'components/Datatable/_TableDateRangeSearch.scss';

const TableDateRangeSearch = ({
  containerClass,
  onChange: onChangeProp,
  disabled,
  id,
  date: dateString = '',
  ...rest
}) => {
  const [rangeDate, setRangeDate] = useState();
  const [resetDateDisabled, setResetDateDisabled] = useState(true);

  const clearDatePickerInputs = () => {
    document.querySelector(`#${id}-start`).value = '';
    document.querySelector(`#${id}-finish`).value = '';
  };

  useEffect(
    () => {
      if (dateString) {
        const date = new Date(dateString);
        if (isValidDate(date)) {
          const parsedDate = dateStringToRange(date);
          onChange(parsedDate);
        }
      }
    },
    [dateString]
  );

  useEffect(
    () => {
      if (resetDateDisabled) {
        clearDatePickerInputs();
      }
    },
    [resetDateDisabled]
  );

  const onChange = (e) => {
    setRangeDate(e);
    setResetDateDisabled(!(e[0] && e[1]));
    if (onChangeProp) {
      const dateRangeAsString = dateRangeToLocaleString(e);
      const event = { target: { value: dateRangeAsString } };
      onChangeProp(event);
    }
  };

  const clearDatePicker = () => {
    onChange([undefined, undefined]);
  };

  const enableResetDatePickerInputs = () => {
    //setResetDateDisabled(false);
  };

  return (
    <>
      <DatePicker id={id}
        className={containerClass}
        datePickerType="range"
        dateFormat="d/m/Y"
        value={rangeDate}
        onChange={onChange}
        onClose={enableResetDatePickerInputs}
        short={true}
        {...rest}
      >
        <DatePickerInput
          id={id + '-start'}
          placeholder="Fecha inicio"
          labelText=""
          autoComplete="off"
        />
        <DatePickerInput
          id={id + '-finish'}
          placeholder="Fecha fin"
          labelText=""
          autoComplete="off"
        />
      </DatePicker>
      <div className="clear-button-container">
        <button
          disabled={resetDateDisabled}
          className='clear-button cds--search-button'
          onClick={clearDatePicker}
          type="button">
          <Close />
        </button>
      </div>
    </>
  );
};

TableDateRangeSearch.propTypes = {
  children: PropTypes.node,

  /**
  * Provide an optional class name for the search container
  */
  className: PropTypes.string,

  /**
  * Provide an optional id for the search container
  */
  id: PropTypes.string,

  /**
  * Provide an optional hook that is called each time the input is updated
  */
  onChange: PropTypes.func,

  /**
  * Optional callback called when the search value is cleared.
  */
  onClear: PropTypes.func,

  /**
  * Optional prop to specify the tabIndex of the <Search> (in expanded state) or the container (in collapsed state)
  */
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TableDateRangeSearch.defaultProps = {
  tabIndex: '0'
};

export default TableDateRangeSearch;