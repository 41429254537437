import React from 'react';

import { MedicalStaff, DevicePairing, Advocate, Warning_01 } from '@carbon/pictograms-react';

import 'components/Views/ParticipantsComponent.scss';

export default function ParticipantsComponent(value) {
  const { participants, status  } = value;
  let i = 0;
  
  return (
    <>
      {
        participants.map(item => {
          switch(item) {
            case 'practitioner': 
              return <MedicalStaff key={`${item}${i++}`} className="info" aria-label="Facultativos" />;
            case 'device': 
              return <DevicePairing key={`${item}${i++}`} className="info" aria-label="Dispositivo"/>;
            case 'patient':
              return <Advocate key={`${item}${i++}`} className="info" aria-label="Paciente"/>;
            case 'communication':
                return status === 'completed' ? <></> :<Warning_01 key={`${item}${i++}`} className="alert" aria-label="Episodio de malestar"/>;
            default:
              return '';
          }
        })
      }
    </>
  );
}