// Funcionalidades para gestión de recurso Encounter
import http from 'common/HttpService';

const Encounter = {
  findById: async (identifier) => {
    const res = await http.get(`/Encounter/${identifier}`);
    return res.data;
  },
  findAllDataBy: async (query) => {
    const res = await http.get(`/Encounter/${query}`);
    return res.data;
  },
  findByServiceRequestIdentifier: async (serviceRequestIdentifier) => {
    const res = await http.get(`/Encounter?based-on=${serviceRequestIdentifier}`);
    const { entry = [] } = res.data;
    return entry;
  },

  createEncounter: async (encounter) => {
    const headers = { headers: { 'Prefer': 'return=representation' } };
    return http.post('/Encounter', encounter, headers);
  },

  updateEncounter: async (encounter) => {
    const headers = { headers: { 'Prefer': 'return=representation' } };
    return http.put(`/Encounter/${encounter.id}`, encounter, headers);
  },

  updatePatch: (dataPatch) => {
    const headers = { headers: { 'Prefer': 'return=representation', 'Content-Type': 'application/json-patch+json' } };
    return http.patch(`/Encounter/${dataPatch.id}`, dataPatch.patch, headers);
  }

};

export default Encounter;