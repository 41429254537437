import { Modal } from '@carbon/react';
import React from 'react';
import ReactDOM from 'react-dom';

export default function ConfirmAction({ props }) {
  const { onSubmit, onClose, text} = props;
  return (
    <>
      {
        typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <Modal
              open
              primaryButtonText="Aceptar"
              secondaryButtonText="Cancelar"
              onRequestSubmit={onSubmit}
              onRequestClose={onClose}
              preventCloseOnClickOutside
              size="sm">
              {text}
            </Modal>
            , document.body
          )
      }
    </>
  );
}