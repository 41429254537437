import React, { useEffect, useState } from 'react';
import { StopFilled, PlayFilledAlt, Bluetooth, BluetoothOff } from '@carbon/react/icons';
import Timer, { TIMER_DIRECTION } from 'components/Timer';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { Button, SkeletonText } from '@carbon/react';
import { CODE_MONITORING_CARDIAC_OUTPUT } from 'api/constants/tags/ObservationTags';
import { RECORDER_DATA_TYPE as HRV_RECORDER_DATA_TYPE } from 'components/HrvRecorder';

export default function BluetoothControlBar({ duration, observationsDefinition, bluetoothStateHrv, connectHrv, setRunState, runState }) {

  const [showHrvControl, setShowHrvControl] = useState(false);

  const existsBluetoothObservation = () => {
    return observationsDefinition && observationsDefinition.filter(
        od => od.method.coding[0].code === CODE_MONITORING_CARDIAC_OUTPUT
          && od.permittedDataType[0] === HRV_RECORDER_DATA_TYPE).length;
  };

  function startStop() {
    setRunState((previousState) => {
      if (previousState.started) {
        return { started: false, finished: true };
      } else {
        return { started: true, finished: false };
      }
    });
  }

  function isStartDisabled() {
    return showHrvControl && !bluetoothStateHrv.device && existsBluetoothObservation();
  }

  function getDeviceName(state) {
    if (state.loading) {
      return <SkeletonText className={'loading'} width='80%' />;
    }
    if (state.device) {
      return state.deviceName;
    } else {
      return 'No conectado';
    }
  }

  useEffect(() => {
    /*
    if (observationsDefinition 
      && observationsDefinition
          .filter(od => od.method.coding[0].code === CODE_MONITORING_CARDIAC_OUTPUT
              && od.permittedDataType[0] === HRV_RECORDER_DATA_TYPE).length) {
    */
    if (existsBluetoothObservation()) {
      setShowHrvControl(true);
    } else {
      setShowHrvControl(false);
    }
  }, [observationsDefinition]);


  return (
    observationsDefinition ?
      <div className='bluetooth-bar--wrapper'>
        {
          showHrvControl &&
          <div className='bluetooth-bar'>
            <div className='devices'>
              {showHrvControl &&
                <div className='hrv d-flex'>
                  <span className='label'>Pulso/variabilidad:</span>
                  <div className='device-name'>{getDeviceName(bluetoothStateHrv)}</div>
                  <div className='button button-hrv' onClick={() => { connectHrv(); }}>
                    {bluetoothStateHrv.device ? <Bluetooth /> : <BluetoothOff />}
                  </div>
                </div>
              }
                  </div>
            <div className='time'>
              <ProgressBar>
                <Timer length={duration} run={runState.started} format={'mm:ss'} direction={TIMER_DIRECTION.DOWN}
                  onFinish={() => { setRunState((prevState) => { return { ...prevState, started: false, finished: true }; }); }} />
              </ProgressBar>
            </div>
            <div className='startStop'>
              <Button kind="primary" hasIconOnly renderIcon={runState.started ? StopFilled : PlayFilledAlt}
                iconDescription={runState.started ? "Stop" : "Start"}
                disabled={isStartDisabled()}
                onClick={() => startStop()}>
              </Button>
            </div>
          </div>
        }
      </div>
      : <></>
  );
}
