import { Modal, Tab, TabPanel, TabPanels, TabList, Tabs, Toggle } from '@carbon/react';
import PatientDataComponent from 'patients/PatientDataComponent';
import CommunicationQuestionnaireComponent from "./CommunicationQuestionnaireComponent";
import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';
import Communication from 'api/Communication';
import ConfirmAction from 'components/ConfirmAction/ConfirmAction';
import CommunicationObservationComponent from './CommunicationObservationComponent';
import Patient from 'api/Patient';


export default function CommunicationComponent({ communication, defaultPatient, practitionerRole, onClose }) {
  const NOT_DONE = 'not-done';
  const COMPLETED = 'completed';

  const [status, setStatus] = useState(NOT_DONE);
  const [showConfirmReset, setShowConfirmSave] = useState(false);
  const [showConfirmDelete, setShowConfirmClose] = useState(false);
  const [activityDefinitionIDs, setActivityDefinitionIDs] = useState();
  const [observationIDs, setObservationIDs] = useState();
  const [patient, setPatient] = useState(defaultPatient);


  const isObservation = (identifier) => { return identifier.includes('Observation/'); };
  const isActivityDefinition = (identifier) => { return identifier.includes('ActivityDefinition/'); };

  function Info() {
    return (
      <div className="communication-component--patient-info">
        {<PatientDataComponent patient={patient} />}
      </div>
    );
  }

  function Header() {
    return (
      <div className="communication-component--header">
        <h2 className='title d-flex '>Episodio de malestar</h2>

      </div>
    );
  }

  function markAsRead(checked) {
    checked ? setStatus(COMPLETED) : setStatus(NOT_DONE);
  }

  const handleSave = () => {
    if (communication) {
      communication.status = status;
      Communication.update(communication);
    }
  };

  const propsConfirmSave = {
    text: "¿Desea guardar el estado del episodio de malestar?",
    onSubmit: () => {
      handleSave();
      setShowConfirmSave(false);
    },
    onClose: () => setShowConfirmSave(false)
  };

  const propsConfirmClose = {
    text: "¿Desea cerrar el episodio de malestar?",
    onSubmit: () => {
      onClose();
      setShowConfirmClose(false);
    },
    onClose: () => setShowConfirmClose(false)
  };

  useEffect(() => {
    if (communication) {
      !defaultPatient && Patient.getPatient(communication?.subject?.reference.split("Patient/")[1]).then(response => setPatient(response));
      setStatus(communication.status);
      loadObservationIDs(communication);
      loadActivityDefinitionIDs(communication);      
    }
    return () => setPatient()
  }, [communication, defaultPatient]);

  const loadObservationIDs = (communication) => {
    const observationIDsList = [];
    communication?.payload?.forEach((payloadItem) => {
      if (isObservation(payloadItem.contentReference.reference)) {
        observationIDsList.push(payloadItem.contentReference.reference.replace('Observation/', ''));
      }
    });
    setObservationIDs(observationIDsList);
  };

  const loadActivityDefinitionIDs = (communication) => {
    const activityDefinitionIDsList = [];
    communication?.instantiatesCanonical?.forEach((instantiatesCanonicalItem) => {
      if (isActivityDefinition(instantiatesCanonicalItem)) {
        activityDefinitionIDsList.push(instantiatesCanonicalItem.replace('ActivityDefinition/', ''));
      }
    });
    setActivityDefinitionIDs(activityDefinitionIDsList);
  };

  return (
    <>
      {showConfirmReset && <ConfirmAction props={propsConfirmSave} />}
      {showConfirmDelete && <ConfirmAction props={propsConfirmClose} />}
      {
        typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <Modal
              preventCloseOnClickOutside
              open={true}
              size="lg"
              onRequestClose={() => setShowConfirmClose(true)}
              onRequestSubmit={() => setShowConfirmSave(true)}
              modalHeading={<>
                <Header />
                <br />
                <Toggle labelText="Marque para indicar esta notifiación como leída." labelA="No leída" labelB="Leída"
                  id={`${communication.id}:toggle`}
                  onToggle={(checked) => { markAsRead(checked); }}
                  toggled={status === COMPLETED}

                />
              </>}
              primaryButtonText="Guardar"
              secondaryButtonText="Cerrar"
              modalLabel={<Info />}
            >

              <Tabs >
                <TabList aria-label={'Pestañas con información de la comunicación cubierta por el paciente'} activation={'manual'}>
                  {communication?.payload?.filter(communicationItem => communicationItem?.contentReference?.type === 'QuestionnaireResponse').map(communicationItem => {
                    return (<Tab className="modal-tab" key={`tabCommunication:${communicationItem.contentReference.reference}`}>
                      <div>{communicationItem.contentReference.display}</div>
                    </Tab>);
                  })
                  }
                  {communication?.payload?.some(communicationItem => communicationItem?.contentReference?.type === 'Observation') ?
                    <Tab>Mediciones</Tab> : <></>
                  }
                </TabList>
                <TabPanels>
                  {communication?.payload?.filter(communicationItem => communicationItem?.contentReference?.type === 'QuestionnaireResponse').map((communicationItem, index) => {
                    return <TabPanel key={`tabPanel:${index}${communicationItem?.contentReference?.reference}`}>
                      <CommunicationQuestionnaireComponent
                        key={`communication-questionnaire-component${index}${communicationItem?.contentreference?.reference}`}
                        communicationQuestionnaireElement={communicationItem.contentReference}
                      >
                      </CommunicationQuestionnaireComponent>

                    </TabPanel>;
                  })}

                  {communication?.payload?.some(communicationItem => communicationItem?.contentReference?.type === 'Observation') ?
                    <TabPanel>
                      {
                        patient && practitionerRole &&
                        <CommunicationObservationComponent
                          activityDefinitionIDs={activityDefinitionIDs}
                          observationIDs={observationIDs}
                          patient={patient}
                          practitionerRole={practitionerRole}>
                        </CommunicationObservationComponent>
                      }
                    </TabPanel> : <></>
                  }

                </TabPanels>
              </Tabs>
            </Modal>
            , document.body
          )
      }
    </>
  );
}