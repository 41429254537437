
import React from 'react';
import AuthStore from "auth/AuthStore";
import {v4 as uuidv4} from 'uuid';
import useNotification from 'hooks/useNotification';
import Bundle from 'api/Bundle';

export default function AppAdmin() {
  const isAdmin = AuthStore.getAuthentication()?.hasRole("admin");

  const notification = useNotification();

  const ID_PREFIX = "urn:uuid:";

  var MAP_IDs = new Map();

  var bundleActivities = {
    resourceType: "Bundle",
    type: "transaction",
    entry: []
  };

  var bundlePlanDefinition = {
    resourceType: "Bundle",
    type: "transaction",
    entry: []
  }

  const validateInputs = (activities, device, planDefinition) => {
    if(device && activities && planDefinition){
      return true;
    } else {
      console.log('los inputs no son válidos para ser procesados');
      notification.error(
        {
          title: "Error procesando la petición",
          caption: "No ha sido posible procesar los datos solicitados en la pantalla. Asegúrese que todos los campos contienen los datos necesarios."
        }
      );
      return false;
    }
  }

  function getFHIRIdentifier(originalID){
    if(MAP_IDs.get(originalID)){
      return MAP_IDs.get(originalID);
    } else{
      let randomUUID = uuidv4();;
      MAP_IDs.set(originalID, randomUUID);
      return randomUUID;
    }
  }

  function addDeviceExtension(activity, device){
    activity.extension = [device];
    return activity;
  }

  function replaceActivityDefinitionIDs(activityDefinition){
    console.log("START replaceActivityDefinitionIDs");
    activityDefinition.id = ID_PREFIX + getFHIRIdentifier(activityDefinition.id);
    console.log("END replaceActivityDefinitionIDs");
    return activityDefinition;
  }

  function replacePlanDefinitionIDs(planDefinition, bundleActivitiesResponse){
    console.log("START replacePlanDefinitionIDs");
    let planDefinitionActionsModified = [];
    if(planDefinition.action){
      planDefinition.action.forEach(planDefinitionAction =>{
        planDefinitionActionsModified.push(replacePlanDefinitionActionIDs(planDefinitionAction, bundleActivitiesResponse));
      });
      planDefinition.action = planDefinitionActionsModified;
    }
    console.log("END replacePlanDefinitionIDs");
    return planDefinition;
  }

  function replacePlanDefinitionActionIDs(planDefinitionAction, bundleActivitiesResponse){
    console.log("START replacePlanDefinitionActionIDs");
    let planDefinitionActivityIdentifier = planDefinitionAction.definitionCanonical.replace('ActivityDefinition/', '');
    let activityID = getActivityIDByIdentifier(bundleActivitiesResponse, planDefinitionActivityIdentifier);
    planDefinitionAction.definitionCanonical = 'ActivityDefinition/' + activityID;
    
    console.log("END replacePlanDefinitionActionIDs");
    return planDefinitionAction;
  }

  function getActivityIDByIdentifier(bundleActivitiesResponse, activityIdentifier){
    let activityID = "";
    console.log(activityIdentifier);
    console.log(bundleActivitiesResponse?.data?.entry);
    bundleActivitiesResponse?.data?.entry?.forEach(bundleEntry =>{
      if(activityIdentifier === bundleEntry?.resource?.identifier[0]?.value){
        console.log(bundleEntry);
        console.log(bundleEntry?.resource?.identifier);
        activityID = bundleEntry?.resource?.id;
      }
    });
    return activityID;
  }

  function generateActivityDefinitionsEntries(activities, device){
    console.log(activities);
    activities.forEach(activity => {
      let entryItem = {};
      entryItem.request = {
        method : "POST",
        url : activity.resourceType
      }
      activity = addDeviceExtension(activity, device);
      let activityDefinition = replaceActivityDefinitionIDs(activity);
      entryItem.fullUrl = activityDefinition.id;
      delete activityDefinition.id;
      entryItem.resource = activityDefinition;
      
      bundleActivities.entry.push(entryItem);
    });
  }

  function generatePlanDefinitionEntry(planDefinition, bundleActivitiesResponse){
      let entryItem = {};
      entryItem.request = {
        method : "POST",
        url : planDefinition.resourceType
      }
      entryItem.resource = replacePlanDefinitionIDs(planDefinition, bundleActivitiesResponse);
      bundlePlanDefinition.entry.push(entryItem);
  }

  async function generateEntities(){
    var activities = document.getElementById('activities').value;
    var device = document.getElementById('device_extension').value;
    var planDefinition = document.getElementById('plan_definition').value;
    if(validateInputs(activities, device, planDefinition)){
      activities = JSON.parse(activities);
      device = JSON.parse(device);
      planDefinition = JSON.parse(planDefinition);
      generateActivityDefinitionsEntries(activities, device);
      console.log(bundleActivities);
      let bundleResponseActivities = await Bundle.executeBundle(bundleActivities, true);
      document.getElementById('resultActivities').value = JSON.stringify(bundleResponseActivities);
      generatePlanDefinitionEntry(planDefinition, bundleResponseActivities);
      console.log(bundlePlanDefinition);
      let bundleResponsePlanDefinition = await Bundle.executeBundle(bundlePlanDefinition, true);
      document.getElementById('resultPlanDefinition').value = JSON.stringify(bundleResponsePlanDefinition);
    }
  }

  return (
    isAdmin && 
      <>
        <div>Actividades en formato array: <textarea name="activities" id="activities"></textarea></div>
        <div>Extensión del device a añadir: <textarea name="device_extension" id="device_extension"></textarea></div>
        <div>PlanDefinition: <textarea name="plan_definition" id="plan_definition"></textarea></div>
        <button type="button" onClick={() => {generateEntities()}}>Procesar</button>
        <div>Resultado de la creación de actividades: <textarea name="resultActivities" id="resultActivities"></textarea></div>
        <div>Resultado de la creación del PlanDefinition: <textarea name="resultPlanDefinition" id="resultPlanDefinition"></textarea></div>
      </>
  );
}