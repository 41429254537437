import React, { useEffect, useState } from 'react';
import useNotification from 'hooks/useNotification';
import ServiceRequest from 'api/ServiceRequest';
import Encounter from 'api/Encounter';
import ServiceRequestComponent from 'serviceRequest/ServiceRequestComponent';
import { dateTimeToLocalDate, dateTimeToLocalTime, toISOString } from 'common/DateTimeUtils';
import { Activity } from '@carbon/react/icons';
import 'components/Launchers/ServiceRequestLauncher.scss';
import { Link, SkeletonText } from '@carbon/react';
import ConfirmAction from 'components/ConfirmAction/ConfirmAction';

/**
 * Componente que permite visualizar y completar una actividad planificada en una ServiceRequest 
 * y registrada como un Encounter.
 * 
 * @param {*} activity Activity que contiene la referencia a la SeriveRequest a mostrar.
 * @param {*} patient
 * @param {*} carePlan
 * @param {*} practitionerRole
 * @param {*} encounterReference "reference" FHIR al Encounter que agrupa las variables obtenidas. Puede venir vacío 
 *                si se desea mostrar el componente para crear un nuevo Encounter.
 * @returns 
 */
export default function ServiceRequestLauncher({ activity, patient, carePlan, practitionerRole, encounterReference, handleUpdateCarePlan }) {
  const [serviceRequest, setServiceRequest] = useState(null);
  const [encounter, setEncounter] = useState(null);
  const [loadingEncounter, setLoadingEncounter] = useState(false);
  const [confirmCreateEncounter, setConfirmCreateEncounter] = useState(false);
  const notification = useNotification();

  const onClickSearchServiceRequest = () => {
    ServiceRequest.findById(activity.referenceUuid)
      .then(data => {
        if (data) {
          setServiceRequest(data);
          if (!encounter) {
            setConfirmCreateEncounter(true);
          }
        } else {
          notification.error({
            title: "Error cargando la cita",
            caption: "La cita no existe. Contacte con el administrador."
          });
        }
      }).catch(e => {
        console.log("Error cargando la cita", e);
        notification.error({
          title: "Error cargando la cita",
          caption: "No ha sido posible cargar los datos de la cita a ejecutar. Contacte con el administrador."
        });
      });
  };

  const buildEncounter = () => {
    const encounter = {
      resourceType: "Encounter",
      status: "in-progress",
      class: {
        system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
        code: "AMB",
        display: "ambulatory"
      },
      subject: {
        reference: "Patient/" + patient.id,
        display: patient.identifier[0].value
      },
      basedOn: [
        {
          reference: "ServiceRequest/" + serviceRequest.id
        }
      ],
      period: {
        start: toISOString(new Date())
      }

    };
    Encounter.createEncounter(encounter).then(data => {
      setEncounter(data.data);
    });
    setConfirmCreateEncounter(false);
  };


  const handleClose = () => {
    setServiceRequest(null);
    setEncounter(null);
  };

  const handleSave = () => {
    handleUpdateCarePlan && handleUpdateCarePlan(encounter.basedOn[0].reference, false, encounter.id);
    handleClose();
  };

  useEffect(() => {
    if (encounterReference && !encounter) {
      setLoadingEncounter(true);
      Encounter.findById(encounterReference.reference.replace('Encounter/', ''))
        .then(data => {
          setEncounter(data);
          setLoadingEncounter(false);
        }).catch(e => {
          console.log("Error cargando Encounter " + encounterReference.reference, e);
          notification.error({
            title: "Error cargando los datos de la prueba ejecutada",
            caption: "No ha sido posible cargar los datos de la prueba ejecutada. Contacte con el administrador."
          });
        });
    }
  }
    , [encounterReference, encounter]);

  const propsConfirmSave = {
    text: "¿Desea crear un nuevo registro de actividad?",
    onSubmit: () => {
      buildEncounter();
    },
    onClose: () => setConfirmCreateEncounter(false)
  };

  return (
    <>
      {
        loadingEncounter ? <SkeletonText className="activity-outcome-row skeleton-text" /> :
          encounter ?
            <Link
              className='activity-outcome-row coperia-clickable'
              disabled={encounter.status === "cancelled"}
              onClick={encounter.status !== "cancelled" ? onClickSearchServiceRequest : () => { }}
              renderIcon={Activity}>
              {`${dateTimeToLocalDate(encounter.period.start)} ${dateTimeToLocalTime(encounter.period.start)} - Actividad ${activity.name}`}
            </Link>
            :
            <Link className='coperia-clickable' onClick={onClickSearchServiceRequest} renderIcon={Activity}>Ejecutar actividad</Link>

      }
      {
        confirmCreateEncounter && <ConfirmAction props={propsConfirmSave} ></ConfirmAction>
      }
      {
        serviceRequest && encounter
        && <ServiceRequestComponent carePlan={carePlan} serviceRequest={serviceRequest} encounter={encounter} patient={patient} practitionerRole={practitionerRole} onSubmit={handleSave} onClose={handleClose} />
      }
    </>
  );
}