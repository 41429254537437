/**
 * Funciones para gestionar objectos Activity.
 * 
 * @param {*} typeParam String con el tipo de recurso FHIR que describe la actividad
 * @param {*} referenceUuidParam uuid en FHIR del objeto que describe la actividad
 * @param {*} dateParam timestamp con la fecha-hora planificada de la actividad
 * @param {*} timeParam timestamp con la fecha-hora planificada de la actividad
 * @param {*} statusParam String con el estado de la actividad: "scheduled" (por defecto), "completed".
 * @param {*} participantsParam lista de Strings indicando los participants: "patient", "device", "practitioner".
 * @param {*} performersParam lista de "reference" (FHIR) con la referencias a cada PractitionerRole ejecutor de la prueba.
 * @param {*} outcomesParam lista de "reference" (FHIR) con la referencias a cada resultado de la prueba.
 * 
 * @returns Activity
 */
export const createActivity = (
  typeParam = '',
  referenceUuidParam = '',
  nameParam = '',
  dateParam = '',
  timeParam = '',
  statusParam = 'scheduled',
  participantsParam = [],
  performersParam = [],
  outcomesParam = []
) => {
  return {
    type: typeParam,
    referenceUuid : referenceUuidParam,
    name: nameParam,
    date: dateParam,
    time: timeParam,
    status: statusParam,
    participants: participantsParam,
    performers: performersParam,
    outcomes: outcomesParam
  }
};

/**
 * Transforma las actividades de un CarePlan.activity[] en una lista
 * de Activity de la aplicación. Solo se procesan las actividades de tipo
 * Questionnaire.
 * 
 * @param {*} activities lista de actividades del CarePlan.
 * @returns 
 */
export const mapQuestionnairesToActivities = (activities) => {
  const questionnaires = activities
    .filter(item => (item.detail && item.detail.kind === 'Appointment'))
    ?.map(item => createActivity(
      'Questionnaire',
      item.detail.instantiatesCanonical[0].replace('Questionnaire/', ''),
      'Cuestionario',
      item.detail.scheduledPeriod.start,
      item.detail.scheduledPeriod.start,
      item.detail.status || 'scheduled',
      ['patient', 'practitioner'],
      item.detail.performer,
      item.outcomeReference || []
    ));
  return questionnaires || [];
}

/**
 * Genera una lista de Activities a partir de las actividades referenciadas
 * en el CarePlan y la lista de ServiceRequest generadas para el CarePlan. Solo
 * se mapean las actividades de tipo ServiceRequest
 * 
 * @param serviceRequestsActivitiesInCarePlan lista de las actividades en el CarePlan
 * @param serviceRequestsLinkedToCarePlan lista de ServiceRequest registradas para un CarePlan concreto
 */
export const mapServiceRequestsFromCarePlanToActivities = (serviceRequestsActivitiesInCarePlan, serviceRequestsLinkedToCarePlan) => {
  const activitiesInCarePlan = serviceRequestsActivitiesInCarePlan.filter(item => (item.reference && item.reference.type === 'ServiceRequest'))
    .map(serviceRequestActivity => {
      const serviceRequestDetail = serviceRequestsLinkedToCarePlan
        .filter(serviceRequest => serviceRequestActivity.reference.reference.includes(serviceRequest.id))?.[0];

      return createActivityFromServiceRequest(serviceRequestDetail, serviceRequestActivity.outcomeReference);
    });
  return activitiesInCarePlan;
};

/**
 * Transforma runa lista de serviceRequest en una lista
 * de Activity de la aplicación.
 * 
 * @param {*} activities lista de actividades del CarePlan.
 * @returns 
 */
export const mapServiceRequestsToActivities = (serviceRequest) => {
  const result = serviceRequest
    .filter(item => (item.resourceType && item.resourceType === 'ServiceRequest'))
    ?.map(serviceRequestDetail => createActivityFromServiceRequest(serviceRequestDetail));
  return result;
};

const createActivityFromServiceRequest = (serviceRequest, outcomeReference = []) => {
  const result = createActivity(
    'ServiceRequest',
    serviceRequest.id,
    serviceRequest.code.text || '-',
    serviceRequest.occurrenceDateTime,
    serviceRequest.occurrenceDateTime,
    serviceRequest.status === 'active' ? 'scheduled' : 'completed',
    ['patient', 'practitioner'],
    serviceRequest.performer,
    outcomeReference
  )
  return result
}

/**
 * Transforma las comunicaciones en una lista de Activity de la aplicación. 
 * 
 * @param {*} activities lista de comunicaciones del paciente
 * @returns 
 */
 export const mapCommunicationsToActivities = (communications) => {
  const communicationActivities = communications?.map(item => createActivity(
      'Communication',
      item.id,
      'Episodio de malestar',
      item.sent,
      item.sent,
      item.status || 'completed',
      [ 'communication' ],
      null,
      item.payload?.map(item => item.contentReference) || []
    ));
  return communicationActivities || [];
}