import { View, ViewOff } from "@carbon/react/icons";

export const PATIENT_PROFILE = "http://coperia.es/fhir/profile/patient";
export const PATIENT_USERNAME = "http://coperia.es/fhir/patient-username";
export const PATIENT_ONE_TIME_PASSWORD =
  "http://coperia.es/fhir/patient-one-time-password";
export const RESET_PATIENT_PASSWORD =
  "http://coperia.es/fhir/patient-reset-password";
export const PATIENT_IDENTIFIER = "http://coperia.es/fhir/patient-identifier";
export const PATIENT_TYPE_SYSTEM = "http://coperia.es/fhir/patient-type";

export const PATIENT_TYPE_COVID_PERSISTENTE = "covid-persistente";
export const PATIENT_TYPE_COVID_CONTROL = "covid-control";

export const PATIENT_TYPE_VALUES = [
  { code: PATIENT_TYPE_COVID_PERSISTENTE, display: "COVID persistente", default: true },
  { code: PATIENT_TYPE_COVID_CONTROL, display: "COVID control" },
];

export const PATIENT_SUMMARY_BUTTON_MAP = new Map([
  [true, { text: "Ocultar", icon: <ViewOff /> }],
  [false, { text: "Mostrar", icon: <View /> }],
]);
