import React, { useEffect, useState } from 'react';
import { Line } from "react-chartjs-2";
import { isoStringToLocalDateTime, secondsTommss } from 'common/DateTimeUtils';
//es necesario que se importe aunque no se use para que funcione el gráfico
import Chart from 'chart.js/auto';
import { CODE_OBSERVATION_HEART_RATE_PULSE, CODE_OBSERVATION_HEART_RATE_VARIABILITY, CODE_OBSERVATION_PULSE_OXIMETER_SATURATION } from 'api/constants/tags/ObservationTags';

const POSITIONS_IN_PERCENTAGE = [17, 50, 83];

export const COLORS_BY_COMPONENT = new Map([
  [CODE_OBSERVATION_HEART_RATE_VARIABILITY, { backgroundColor: 'rgba(75,192,192,0.2)', borderColor: 'rgba(75,192,192,1)' }],
  [CODE_OBSERVATION_HEART_RATE_PULSE, { backgroundColor: 'rgba(230, 92, 92, 0.774)', borderColor: 'rgba(209, 28, 28, 0.705)' }],
  [CODE_OBSERVATION_PULSE_OXIMETER_SATURATION, { backgroundColor: 'rgba(125, 187, 245, 0.774)', borderColor: 'rgba(7, 109, 204, 0.774)' }]
]);

export default function LineChart({ component, observation }) {
  const [chartInfo, setChartInfo] = useState(null);

  useEffect(() => {
    const data = component.valueSampledData.data.split(/[ ,]+/).join(',').split(',');
    const labels = [...Array(data.length).keys()].map((index) => positionToTime(observation, index, data.length));
    const chartDataObject = getChartDataObject(component.code.text, labels, data, COLORS_BY_COMPONENT.get(component.code.coding[0].code));
    const indexesToShow = new Map();
    POSITIONS_IN_PERCENTAGE.forEach((position) => {
      const index = Math.round((((data.length - 1) * position) / 100));
      const time = positionToTime(observation, index, data.length);
      indexesToShow.set(index, time);
    });
    const chartOptions = getChartOptions(indexesToShow);
    setChartInfo(() => {
      return {
        key: component.code.coding[0].code,
        data: chartDataObject,
        options: chartOptions
      };
    });
  }, [component]);

  return (
    chartInfo && <Line key={chartInfo.key} data={chartInfo.data} options={chartInfo.options} />
  );
}


function getChartDataObject(title, labels, chartData, color) {
  return {
    labels: labels,
    datasets: [
      {
        label: title,
        data: chartData,
        fill: false,
        backgroundColor: color.backgroundColor,
        borderColor: color.borderColor
      }
    ]
  };
}

function getChartOptions(indexesToShow) {
  return {
    scales: {
      x: {
        ticks: {
          callback: function (value) {
            if (indexesToShow.has(value)) {
              return `${indexesToShow.get(value)}`;
            }
          }
        }
      }
    }
  };
}

function positionToTime(observation, index, dataLength) {
  const tickFactorInSeconds = ((isoStringToLocalDateTime(observation.effectivePeriod.end).getTime() - isoStringToLocalDateTime(observation.effectivePeriod.start).getTime()) / 1000) / dataLength;
  const timeInSecods = Math.ceil(index * tickFactorInSeconds);
  return secondsTommss(timeInSecods);
}