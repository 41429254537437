import React, { useState, useEffect } from 'react';
import { Checkbox, DatePicker, DatePickerInput } from '@carbon/react';
import { dateTimeToLocalDate, formatDate } from 'common/DateTimeUtils';
import { getTagCodesBySystem } from 'api/constants/tags/TagFunctions';
import { SYSTEM_ASSAY_GROUP, ASSAY_GROUP_MAP } from 'api/constants/tags/AssayTags';

/**
 * Controla como se muestran los datos de un ensayo al que está asociado un paciente 
 * y gestiona sus cambios.
 * @param {*} param0 
 * @returns 
 */
export default function PatientSingleAssay({ patient, assay, onAssayChange, showAssayGroups }) {
  const [componentAssay, setComponentAssay] = useState();

  function onAssayCheckboxClick(event) {
    const updatedAssay = { ...assay, checked: event.target.checked };
    onAssayChange(updatedAssay);
  }

  /**
   * Maneja un evento que contiene el datetime (con zona horaria UTC+0).
   * 
   * @param {*} event 
   */
  const onAssayDateChange = (pickedDatetimeArray, pickedDate) => {
    const updatedAssay = { ...assay, initialDate: formatDate(pickedDatetimeArray[0]) };
    onAssayChange(updatedAssay);
  };

  function getAssayGroup(assayGroupTags, assayCode) {
    let message = "Grupo no asignado";
    if (assayGroupTags && assayCode) {
      let assignedGroup = assayGroupTags.filter(tag => tag.includes(assayCode));
      if (assignedGroup?.length) {
        const grupo = ASSAY_GROUP_MAP.get(assignedGroup[0]);
        message = grupo?.display || message;
      }
    }
    return message;
  }

  /**
   * Inicializa el estado que contiene los datos de este componente.
   */
  useEffect(() => {
    if (assay) {
      const initialDateValue = assay.initialDate ? dateTimeToLocalDate(assay.initialDate) : '';
      setComponentAssay({
        ...assay,
        initialDate: initialDateValue
      });
    }
  }, [assay]);

  return (
    componentAssay ?
      <div className="assay" key={'assay-wrapper--' + componentAssay.code}>
        <Checkbox key={'checkbox-' + componentAssay.code} className="assay-check"
          labelText={componentAssay.label}
          label={componentAssay.label}
          checked={componentAssay.checked}
          id={componentAssay.code}
          onClick={event => { onAssayCheckboxClick(event); }}
        />
        <div className='item-response'>
          {
            <DatePicker locale={'es'} datePickerType="single" dateFormat="d/m/Y"
              id={'date-picker-' + componentAssay.code}
              value={ componentAssay.initialDate }
              onChange={onAssayDateChange}
            >
              <DatePickerInput
                id={'date-picker-input-' + componentAssay.code} autoComplete="off"
                disabled={!componentAssay.checked}
                placeholder="dd/mm/yyyy"
                labelText="" />
            </DatePicker>
          }
        </div>
        {
          patient && showAssayGroups ? getAssayGroup(getTagCodesBySystem(patient.meta, [SYSTEM_ASSAY_GROUP]), componentAssay.code) : ""
        }
      </div>
      : <></>
  );
}
