import React from 'react'
import { useAuth } from 'auth/Auth';

export default function Protected({role, roles, children}) {
    const auth = useAuth();

    if( !auth.isAuthenticated() ){
        return null;
    }

    if( role && !auth.authentication.hasRole(role) ){
        return null;
    }

    if( roles && !auth.authentication.hasAnyRole(roles) ){
        return null;
    }

    return <>{children}</>
}