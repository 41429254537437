import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal, TableToolbar, TableToolbarContent, Toggle } from '@carbon/react';
import { Item } from 'components/GenericFields';
import { buildTagListFromPatientAndCarePlan, getExtensionCoding } from 'api/constants/tags/TagFunctions';
import { formatDate, toISOString } from 'common/DateTimeUtils';
import QuestionnaireResponse from 'api/QuestionnaireResponse';
import Patient from 'api/Patient';
import PatientDataComponent from 'patients/PatientDataComponent';
import StatusTag from 'components/StatusTag';
import Copyright from 'components/Copyright';
import { OBSERVATION_STATUS_DEFAULT, OBSERVATION_STATUS_FINAL, OBSERVATION_STATUS_PRELIMINARY } from 'api/constants/ObservationStatus';
import { QUESTIONNAIRE_RANGE_SELECTED_INDEX, QUESTIONNAIRE_RANGE_VALUES } from 'api/constants/tags/QuestionnaireTags';
import ConfirmAction from 'components/ConfirmAction/ConfirmAction';

export default function QuestionnaireComponent({ questionnaire, questionnaireResponse, contextData, readOnly = false, modal = false, onClose = () => { }, onSubmit = () => { }, onSavingError = () => { } }) {
  const [status, setStatus] = useState("stopped");
  const [initialStatus, setInitialStatus] = useState("stopped");
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showConfirmReturn, setShowConfirmReturn] = useState(false);
  const [primaryModalButtonDisabled, setPrimaryModalButtonDisabled] = useState(false);
  var initialValues = {};
  const [answers, setAnswers] = useState(initialValues);
  
  const initialize = (item) => {
    if (!item) {
      return;
    }

    if (item.answer) {
      const id = `_${item.linkId}`;
      if (item.answer.length === 1) {
        const firstAnswer = item.answer[0];
        if (firstAnswer) {
          const key = Object.keys(firstAnswer);
          switch (key[0]) {

            case 'valueTime': {
              initialValues[id] = firstAnswer[key].substr(0, firstAnswer[key].length - 3);
              break;
            }

            case 'valueDateTime':
            case 'valueDate': {
              initialValues[id] = new Date(firstAnswer[key]).toLocaleDateString();
              break;
            }

            default:
              initialValues[id] = firstAnswer[key];
          }
        }
      }

      if (item.answer.length > 1) {
        initialValues[id] = [];
        item.answer.forEach(a => {
          const key = Object.keys(a);
          initialValues[id].push(a[key]);
        });
      }
    }

    if (item.item) {
      item.item.forEach(i => initialize(i));
    }
  };

  initialize(questionnaireResponse);

  useEffect(() => {
    updateQuestionnaireResponseStatus();
    return () => {
      setStatus();
      setInitialStatus();
    };
  }, []);

  const updateQuestionnaireResponseStatus = () => {
    if (questionnaireResponse?.status) {
      setStatus(questionnaireResponse.status);
      setInitialStatus(questionnaireResponse.status);
    }
  }

  const handleChange = (event) => {
    const target = event.target;
    const id = target.id;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setAnswers(current => ({ ...current, [id]: value }));
  };

  const verifyForm = (checked) => {
    if (checked) {
      setStatus("completed");
    } else {
      setStatus("stopped");
    }
  };

  const generateId = (id) => {
    return `_${id}`;
  };

  const setValue = (item, linkId, value) => {
    if (item.linkId === linkId && value) {
      item.answer = item.answer || [];

      if (Array.isArray(value)) {
        value.forEach(v => { setValue(item, linkId, v?.valueCoding?.display || v); });
      } else {
        switch (item.type) {
          case "string": {
            item.answer.push({ valueString: value.toString() });
            break;
          }

          case 'boolean': {
            item.answer.push({ valueBoolean: value === true });
            break;
          }

          case "date": {
            item.answer.push({ valueDate: formatDate(value) });
            break;
          }

          case 'time': {
            item.answer.push({ valueTime: `${value}:00` });
            break;
          }

          case "dateTime": {
            const [day, month, year] = value.split('/');
            item.answer.push({ valueDateTime: toISOString(new Date(year, month - 1, day)).split('.')[0] + "Z" });
            break;
          }

          case "choice": {
            item.answerOption.forEach(option => {
              if (option?.valueCoding?.display === (value.code || value)) {
                item.answer.push({ valueCoding: { code: value.code || value } });
                item.extension = item.extension || [];
                if (getExtensionCoding(item.extension, QUESTIONNAIRE_RANGE_VALUES).length > 0) {
                  item.extension.push({
                    url: QUESTIONNAIRE_RANGE_SELECTED_INDEX,
                    valueInteger: option.extension[0].valueDecimal - 1
                  });
                }
              }
            });
            break;
          }

          case "decimal": {
            item.answer.push({ valueDecimal: parseFloat(value) });
            break;
          }

          case "integer": {
            item.answer.push({ valueInteger: parseInt(value) });
            break;
          }

          case "Quantity": {
            item.answer.push({ valueQuantity: { value: parseFloat(value) } });
            break;
          }

          case "SampledData": {
            item.answer.push({
              valueSampledData: {
                origin: { value: 2048 },
                period: 1000,
                dimensions: 1,
                data: value
              }
            });
            break;
          }

          default: {
            item.answer.push({ valueString: value.toString() });
          }

        }
      }
    }

    if (item.item) {
      item.item.forEach(i => setValue(i, linkId, value));
    }
  };

  const getResponseItems = (item) => {
    const responseItem = {};

    if (item.linkId) {
      responseItem.linkId = item.linkId;
    }

    if (item.definition) {
      responseItem.definition = item.definition;
    }

    if (item.text) {
      responseItem.text = item.text;
    }

    if (item.answer) {
      responseItem.answer = item.answer;
    }

    if (item.type) {
      responseItem.type = item.type;
    }

    if (item.extension) {
      responseItem.extension = item.extension;
    }

    if (item.item) {
      responseItem.item = [];
      item.item.forEach(i => responseItem.item.push(getResponseItems(i)));
    }

    return responseItem;

  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
    } catch (error) { }
    let responses = {
      resourceType: "QuestionnaireResponse",
      status: status,
      questionnaire: `Questionnaire/${questionnaire.id}`,
      basedOn: [{ reference: `CarePlan/${contextData.carePlan.id}` }],
      meta: {
        tag: buildTagListFromPatientAndCarePlan(contextData.patient, contextData.carePlan)
      },
      item: questionnaire.item,
      subject: {
        reference: "Patient/" + Patient.getId(contextData.patient),
        display: Patient.getName(contextData.patient)
      },
      authored: new Date().toISOString(),
      author: {
        reference: "PractitionerRole/" + contextData.practitionerRole.id,
        display: contextData.practitionerRole.username
      },
      source: {
        reference: "Patient/" + Patient.getId(contextData.patient)
      }
    };
    responses.item.forEach(questItem =>{
      delete questItem.answer;
    })
    
    Object.keys(answers).forEach(a => {
      const linkId = a.replace('_', '');
      const value = answers[a];
      setValue(responses, linkId, value);
    });

    responses.item = getResponseItems(responses).item;
    setPrimaryModalButtonDisabled(true);
    
    manageAnswers(responses, questionnaireResponse ? questionnaireResponse.id : null).then((response) => {
      onSubmit(response.data);
    }).catch((err) => {
      console.error("Error creando o actualizando el cuestionario:", err);
      onSavingError();
    }).finally(() =>{
      setPrimaryModalButtonDisabled(false);
    });
  };


  if (!questionnaire || questionnaire.resourceType !== 'Questionnaire') {
    return <span>Resource is not a Questionnaire</span>;
  }

  const propsConfirmSave = {
    text: "¿Desea guardar los cambios?",
    onSubmit: () => {
      handleSubmit();
      setShowConfirmSave(false);
    },
    onClose: () => setShowConfirmSave(false)
  };

  const propsConfirmReturn = {
    text: "¿Desea cerrar el cuestionario?",
    onSubmit: () => {
      onClose();
      setShowConfirmReturn(false);
    },
    onClose: () => setShowConfirmReturn(false)
  };

  if (modal) {
    return (
      <>
        {
          typeof document === 'undefined'
            ? null
            : ReactDOM.createPortal(
              <Modal
                open
                size="lg"
                onRequestClose={() => setShowConfirmReturn(true)}
                onRequestSubmit={() => setShowConfirmSave(true)}
                modalHeading={<>
                  <Header questionnaire={questionnaire} questionnaireResponse={questionnaireResponse} status={initialStatus} />
                  <br></br>
                  <Toggle labelText="Marque para indicar que esta medición es correcta." labelA="No verificado" labelB="Verificado"
                    id={`${questionnaire.id}:toggle`}
                    onToggle={(checked) => { verifyForm(checked); }}
                    toggled={status === "completed"}
                  />

                </>}
                modalLabel={<>
                  <Info questionnaire={questionnaire} />
                  {questionnaire.description && <div className='questionnaire-header-item'>{questionnaire.description}</div>}
                </>}
                primaryButtonText="Guardar cuestionario"
                primaryButtonDisabled = {primaryModalButtonDisabled} 
                secondaryButtonText="Cancelar"
                preventCloseOnClickOutside
              >
                <div className="questionnaire-viewer">

                  <div className="questionnaire-viewer--body">
                    {
                      questionnaire.item && questionnaire.item.map(i => {
                        const linkId = generateId(i.linkId);
                        const props = {
                          id: linkId,
                          type: i.type,
                          label: i.text,
                          item: i,
                          value: answers[linkId],
                          values: answers,
                          extension: i.extension,
                          repeats: i.repeats,
                          readOnly: readOnly,
                          onChange: readOnly ? null : handleChange,
                          generateId: generateId
                        };
                        return <Item key={i.linkId} props={props} />;
                      })
                    }

                  </div>
                  <Copyright questionnaire={questionnaire} />
                </div>
                <TableToolbar className="coperia--table-toolbar">
                  <TableToolbarContent className="coperia--table-toolbar-content">
                  </TableToolbarContent>
                </TableToolbar>


              </Modal>
              , document.body
            )
        }
        {showConfirmSave && <ConfirmAction props={propsConfirmSave} />}
        {showConfirmReturn && <ConfirmAction props={propsConfirmReturn} />}
      </>
    );
  }

  return (
    <div className="questionnaire-viewer">
      <div className="questionnaire-viewer--body">
        <form>
          {
            questionnaire.item && questionnaire.item.map(i => {
              const linkId = generateId(i.linkId);
              const props = {
                id: linkId,
                type: i.type,
                label: i.text,
                item: i,
                value: answers[linkId],
                values: answers,
                extension: i.extension,
                repeats: i.repeats,
                readOnly: readOnly,
                onChange: readOnly ? null : handleChange,
                generateId: generateId
              };
              return <Item key={i.linkId} props={props} />;
            })
          }
        </form>
      </div>
    </div>
  );
}

function Info() {
  return (
    <div className="questionnaire-viewer--patient-info"><PatientDataComponent /></div>
  );
}

function manageAnswers(questionnaireResponse, questionnaireResponseId) {
  getResponseItems(questionnaireResponse.item);
  if (!questionnaireResponseId) {
    return QuestionnaireResponse.createQuestionnaireResponse(questionnaireResponse);
  } else {
    questionnaireResponse.id = questionnaireResponseId;
    return QuestionnaireResponse.updateQuestionnaireResponse(questionnaireResponse);
  }
}

function Header({ questionnaire, questionnaireResponse, status }) {
  const { title } = questionnaire;
  let statusTag = "";

  if (!title) {
    return null;
  }

  if (!questionnaireResponse) {
    statusTag = OBSERVATION_STATUS_DEFAULT;
  } else if (status === "stopped") {
    statusTag = OBSERVATION_STATUS_PRELIMINARY;
  } else if (status === "completed") {
    statusTag = OBSERVATION_STATUS_FINAL;
  }

  return (
    <div className="questionnaire-viewer--header">
      <h2 className='title d-flex '>{title}<StatusTag status={statusTag} /></h2>

    </div>
  );
}

function getResponseItems(items) {
  items.forEach(item => {
    delete item.type;
    delete item.required;
    item.item && item.item.forEach(i => {
      i.extension && i.extension.forEach(e => {
        if (e.valueCodeableConcept.coding[0].code === "help") {
          delete item.item;
        } else {
          getResponseItems(item.item);
        }
      });
    });
  });
  return items;
}
