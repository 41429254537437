export const GENDERS = ["male", "female"];

export function getGender(gender) {
  if (gender === "male") {
    return "Masculino";
  } else if (gender === "female") {
    return "Femenino";
  } else if (gender === "other") {
    return "Otro";
  } else {
    return "Desconocido";
  }
}

export function getAge(date) {
  if (!date) return "NA";
  var today = new Date();
  var birthDate = new Date(date);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}