import { getResourceCount } from "api/ApiCommons";
import Encounter from "api/Encounter";

/**
 * Recupera una Encounter a partir de su id.
 * 
 * @param {String} id 
 * @return {Encounter}
 */
export const findEncounterById = (id) => {
  return Encounter.findById(id);
};

/**
 * Actualiza el estado de un encounter
 * 
 * @param {*} outcome objeto que contiene la referencia al encounter
 * @param {String} status 
 * @returns {Encounter}
 */
export function patchStatusEncounter(outcome, status) {
  const dataPatch = {
    id: outcome?.reference.split("Encounter/")[1],
    patch: [{
      op: "replace",
      path: `/status`,
      value: status
    }]
  };

  return Encounter.updatePatch(dataPatch);
}

/**
 * Devuelve el número de encounters completadas para una ServiceRequest
 * 
 * @param {String} serviceRequestId 
 * @returns {int}
 */
export const findCountOfEncountersCompleted = (serviceRequestId) => {
  const query = `?based-on=${serviceRequestId}&status=finished&_summary=count`;
  return getResourceCount(Encounter.findAllDataBy(query));
};