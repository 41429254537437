import {
  CODE_OBSERVATION_HEART_RATE_PULSE, 
  CODE_OBSERVATION_HEART_RATE_VARIABILITY
} from "api/constants/tags/ObservationTags";


export const HRV_CONNECTION_PARAMS = {
  serviceName: 'heart_rate',
  primaryServiceName: 'heart_rate',
  characteristicName: 'heart_rate_measurement'
};

export const OUTPUT_VARIABLE_HEART_RATE_PULSE = CODE_OBSERVATION_HEART_RATE_PULSE;
export const OUTPUT_VARIABLE_HEART_RATE_VARIABILITY = CODE_OBSERVATION_HEART_RATE_VARIABILITY;

const HeartRateService = {
  dataParser: (rawData) => {
    const responseHr = parseDataBT(rawData);
    const dataMap = new Map();
    dataMap.set(CODE_OBSERVATION_HEART_RATE_VARIABILITY, responseHr.rrIntervals);
    dataMap.set(CODE_OBSERVATION_HEART_RATE_PULSE, [responseHr.heartRate]);
    return { dataToShow: responseHr.heartRate, data: dataMap };
  }
};

function parseDataBT(data) {
  const flags = data.getUint8(0);
  const rate16Bits = flags & 0x1;
  const result = {};
  let index = 1;
  if (rate16Bits) {
    result.heartRate = data.getUint16(index, true);
    index += 2;
  } else {
    result.heartRate = data.getUint8(index);
    index += 1;
  }
  const contactDetected = flags & 0x2;
  const contactSensorPresent = flags & 0x4;
  if (contactSensorPresent) {
    result.contactDetected = !!contactDetected;
  }
  const energyPresent = flags & 0x8;
  if (energyPresent) {
    result.energyExpended = data.getUint16(index, true);
    index += 2;
  }
  const rrIntervalPresent = flags & 0x10;
  if (rrIntervalPresent) {
    const rrIntervals = [];
    for (; index + 1 < data.byteLength; index += 2) {
      rrIntervals.push(data.getUint16(index, true));
    }
    result.rrIntervals = rrIntervals;
  }
  return result;
}

export default HeartRateService;