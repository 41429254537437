import React from 'react';

import { OverflowMenu, OverflowMenuItem } from '@carbon/react';

import 'components/Observation/_GenericObservationsComponent.scss';

/**
 * Componente que permite visualizar el esqueleto de un componente GenericSingleObservationComponent para añadir 
 * una nueva ocurrencia de la observación.
 * 
 * @param {Object} observationDefinition ObservationDefinition FHIR con la descripción de la variable a tomar.
 * @param {func}   handleAddObservationOccurrence función que permite crear una nueva ocurrencia de una observación.
 */
export default function NewSingleObservationComponent({ observationDefinition, handleAddObservationOccurrence = {}, readOnly = false }) {
  return (
    <>
      {
        !readOnly &&
        <div className='coperia--observation-item-menu-new'>
          <OverflowMenu ariaLabel="overflow-menu">
            <OverflowMenuItem itemText={`Nueva medición ${observationDefinition.method.text}`} onClick={handleAddObservationOccurrence}/>
          </OverflowMenu>
        </div>
      }
    </>
  );
}