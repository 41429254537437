import cloneDeep from "lodash.clonedeep";
import {
  SYSTEM_ASSAY,
  SYSTEM_ASSAY_GROUP,
  SYSTEM_ASSAY_PHASE,
  ASSAY_MAP,
} from "api/constants/tags/AssayTags";
import { SYSTEM_OBSERVATION_OCCURRENCE } from "api/constants/tags/ObservationTags";
import { SYSTEM_OBSERVATION_DEFINITION_MAX_DURATION_MS } from "./ActivityDefinitionTag";

/**
 * Busca en un objeto meta todos los tags con los system indicados por parámetro y devuelve sus code correspondientes
 * @param {Object} meta - objeto meta de cualquier recurso fhir
 * @param {Array} systems - lista de systems
 * @returns Array de códigos de los system pasados por parámetro
 */
export function getTagCodesBySystem(meta, systems) {
  let values = [];
  if (meta && meta.tag) {
    values = meta.tag
      .filter((item) => systems.includes(item.system))
      .map((item) => item.code);
  }
  return values;
}

/**
 * Busca en un objeto meta todos los tags con los system indicados por parámetro y los devuelve
 * @param {Object} meta - objeto meta de cualquier recurso fhir
 * @param {Array} systems - lista de systems
 * @returns Array de códigos de los system pasados por parámetro
 */
export function getTagsBySystem(meta, systems) {
  let values = [];
  if (meta && meta.tag) {
    values = meta.tag
      .filter((item) => systems.includes(item.system))
      .map((item) => item);
  }
  return values;
}

/**
 * Genera la lista de tags de ensayo a partir de una lista con los códigos de ensayo a incluir.
 * @param {Array} assays lista de Strings con los códigos de ensayo.
 * @returns
 */
export const getAssayTagsFromAssayCodes = (assays) => {
  const assayAsTagList = assays?.map((assay) => {
    return {
      system: SYSTEM_ASSAY,
      code: assay,
      display: ASSAY_MAP.get(assay)?.display,
    };
  });
  return assayAsTagList;
};

/**
 * A partir del tag meta de cualquier recurso se determina si existe un objeto con un system y un code iguales a los pasados por parámetro
 * @param {Object} meta - parte de cualquier recurso de fhir en formato json con tags definidos
 * @param {String} system - url del sistema de codificación
 * @param {String} code - código del sistema de codificación
 * @returns true si existe el system y code en el json meta
 */
export function hasCode(meta, system, code) {
  return getTagCodesBySystem(meta, [system]).includes(code);
}

/**
 * Busca en un array de extension, los Objetos que se correspondan con la url pasada por parámetro
 * @param { array } extension - distintas extensiones de un recurso
 * @param { string } url - url que se busca
 * @returns Array con los distintos objetos que tienen la url coincidente
 */
export function getExtension(extension, url) {
  if (extension && extension.length > 0) {
    let values = extension.filter((e) => e.url === url);
    return values;
  } else {
    return [];
  }
}

/**
 * Busca en un array de extension, los códigos que se correspondan con la url pasada por parámetro
 * @param { array } extension - distintas extensiones de un recurso
 * @param { string } url - url que se busca
 * @returns Array con los distintos json que tienen la url coincidente
 */
export function getExtensionCoding(extension, url) {
  return getExtension(extension, url).map((e) => e.valueCodeableConcept);
}

/**
 * Busca en un array de extension, las fechas (formato DateTime) que se correspondan con la url pasada por parámetro
 * @param { array } extension - distintas extensiones de un recurso
 * @param { string } url - url que se busca
 * @returns Array con las fechas que tienen la url coincidente
 */
export function getExtensionDateTime(extension, url) {
  return getExtension(extension, url).map((e) => e.valueDateTime);
}

/**
 * Busca en un array de extension, las fechas (formato Date) que se correspondan con la url pasada por parámetro
 * @param { array } extension - distintas extensiones de un recurso
 * @param { string } url - url que se busca
 * @returns Array con las fechas que tienen la url coincidente
 */
export function getExtensionDate(extension, url) {
  return getExtension(extension, url).map((e) => e.valueDate);
}

/**
 * Busca en un array de extension, las fechas que se correspondan con la url pasada por parámetro
 * @param { array } extension - distintas extensiones de un recurso
 * @param { string } url - url que se busca
 * @returns Array con las fechas que tienen la url coincidente
 */
export function getExtensionString(extension, url) {
  return getExtension(extension, url).map((e) => e.valueString);
}

/**
 * Busca en un array de extension, los booleans que se correspondan con la url pasada por parámetro
 * @param { array } extension - distintas extensiones de un recurso
 * @param { string } url - url que se busca
 * @returns Array con los booleans que tienen la url coincidente
 */
export function getExtensionBoolean(extension, url) {
  return getExtension(extension, url).map((e) => e.valueBoolean);
}

/**
 * Añade una nueva extensión de tipo boolean a un array de extensiones o actualiza su valor si ya existe.
 * @param { array } extension - distintas extensiones de un recurso
 * @param { string } url - url de la nueva extensión
 * @param { string } value - valor de la nueva extensión
 * @returns Array con la nueva extensión
 */
export function addExtensionBoolean(extension, url, value) {
  const currentExtension = getExtensionBoolean(extension, url);
  if (currentExtension.length > 0) {
    extension
      .filter((item) => url.includes(item.url))
      .map((item) => (item.valueBoolean = value));
  } else {
    extension.push({
      url: url,
      valueBoolean: value,
    });
  }
  return extension;
}

/**
 * Añade una nueva extensión de tipo string a un array de extensiones o actualiza su valor si ya existe.
 * @param { array } extension - distintas extensiones de un recurso
 * @param { string } url - url de la nueva extensión
 * @param { string } value - valor de la nueva extensión
 * @returns Array con la nueva extensión
 */
export function addExtensionString(extension, url, value) {
  const currentExtension = getExtensionString(extension, url);
  if (currentExtension.length > 0) {
    extension
      .filter((item) => url.includes(item.url))
      .map((item) => (item.valueString = value));
  } else {
    extension.push({
      url: url,
      valueString: value,
    });
  }
  return extension;
}

/**
 * Inserta un tag en una lista de ellos.
 *
 * @param {Array} tags lista en la que introducir el tag.
 * @param {Url} system identificador del sistema que recoge los códigos
 * @param {String} code valor del tag
 * @param {String} display texto que describe el tag
 * @param {boolean} unique indica si solo puede existir un tag con el mismo system. En cuyo caso, no se inserta el nuevo valor.
 * @returns {Array} la lista de tags actualizada.
 */
export const insertTag = (tags, system, code, display, unique = false) => {
  if (unique && tags.some((item) => item.system === system)) {
    return tags;
  }
  tags.push({
    system: system,
    code: code,
    display: display,
  });
  return tags;
};

/**
 * Clona los tags de source que se corresponden a los system indicados si existen.
 *
 * @param {Array} source
 * @param  {...String} systems lista de system de los que se recuperarán los tags
 * @returns {Array} la lista de tags actualizada o lista vacía
 */
export const cloneTags = (source, ...systems) => {
  return source ?
    source.filter(item => ((systems && systems.includes(item.system)) || !systems)).map(item => cloneDeep(item))
    : [];
};

/**
 * Devuelve la lista de tags que debe contener una entidad FHIR generada a partir de un CarePlan para un paciente. Esto implica
 * que solo se incluirán los tags de paciente asociados al ensayo del CarePlan.
 *
 * @param {Object} patient
 * @param {Object} carePlan
 * @returns {Array} de tags
 */
export const buildTagListFromPatientAndCarePlan = (patient, carePlan) => {
  const finalTags = [];
  const assayTagsFromCarePlan = cloneTags(carePlan?.meta?.tag, SYSTEM_ASSAY);
  const assayTagsFromCarePlanAndPatient = cloneTags(carePlan?.meta?.tag, SYSTEM_ASSAY).filter(assayTag =>
    assayTagsFromCarePlan.some(carePlanAssayTag =>
      assayTag.code === carePlanAssayTag.code
    )
  );
  finalTags.push(...assayTagsFromCarePlanAndPatient);

  const assayPhaseTagsFromCarePlan = cloneTags(
    carePlan?.meta?.tag,
    SYSTEM_ASSAY_PHASE
  );
  finalTags.push(...assayPhaseTagsFromCarePlan);

  const assayGroupTagsFromPatientAndCarePlan = cloneTags(
    patient.meta.tag,
    SYSTEM_ASSAY_GROUP
  ).filter((assayGroupTag) =>
    assayTagsFromCarePlan.some((carePlanAssayTag) =>
      assayGroupTag?.code.includes(carePlanAssayTag?.code)
    )
  );
  finalTags.push(...assayGroupTagsFromPatientAndCarePlan);

  const otherPatientTags = patient.meta.tag.filter(tag => tag.system !== SYSTEM_ASSAY)
      .filter(tag => tag.system !== SYSTEM_ASSAY_GROUP);
  finalTags.push(...otherPatientTags);

  return finalTags;
};

/**
 * Función que devuelve un array conteniendo la fusión de tags de los arrays pasados como parámetro.
 * Se asegura que no existen objetos duplicados.
 *
 * @param {Array} arrayA
 * @param {Array} arrayB
 * @returns Array
 */
export const mergeTagList = (arrayA, arrayB) => {
  const mergedTagList = [...arrayA];
  arrayB.forEach((itemB) => {
    if (
      arrayA.every(
        (itemA) => itemB.system !== itemA.system || itemB.code !== itemA.code
      )
    ) {
      mergedTagList.push(itemB);
    }
  });
  return mergedTagList;
};

/**
 * Elimina los tags de source que se corresponden a los system indicados si existen.
 *
 * @param {Array} source
 * @param  {...String} systems lista de system de los que se elimirán los tags. Si está vacía se elimian todos los tags.
 * @returns {Array} la lista de tags actualizada o lista vacía
 */
export const deleteTags = (source, ...systems) => {
  const output = source
    ? source.filter((item) => systems?.length && !systems.includes(item.system))
    : [];
  return output;
};

/*
 * Añade el tag ocurrencia en un array de tags
 * @param { array } observationTags Array de tags ya existente
 * @param { string } code número de ocurrencia del componente
 */
export const insertOccurrenceTag = (observationTags, code) => {
  const OBSERVATION_OCCURRENCE_MAP = new Map([
    [
      SYSTEM_OBSERVATION_OCCURRENCE,
      {
        system: SYSTEM_OBSERVATION_OCCURRENCE,
        code: "",
        display: "Ocurrencia del componente que genera la observación",
      },
    ],
  ]);
  let occurrenceTag = OBSERVATION_OCCURRENCE_MAP.get(
    SYSTEM_OBSERVATION_OCCURRENCE
  );
  occurrenceTag.code = `${code}`;
  observationTags.push(occurrenceTag);
};

/**
 * Recuperar el primer tag del system indicado o null.
 *
 * @param {Object} meta del objeto FHIR
 * @param {String} system nombre del system asociado al tag
 * @returns el primer tag existente para ese system o null
 */
export const getFirstTag = (meta, system) => {
  const tags = getTagCodesBySystem(meta, system);
  return tags ? tags[0] : null;
};

/**
 * Construye un tag para un code y un system dados.
 *
 * @param {String} code nombre del code asociado al tag
 * @param {String} system nombre del system asociado al tag
 * @returns el tag con el code y el system como atributos
 *
 */
export const buildTagFromCodeAndSystem = (code, system) => {
  if (code && system) {
    return {
      code: code,
      system: system,
    };
  } else {
    return null;
  }
};

/**
 * Obtiene la duración de la prueba para un momento determinado.
 * @param {*} activityDefinition que se está procesando
 * @param {*} moment momento con valores before, during o after que se concatenará a la url de la extensión
 * @returns valor en milisegundos que idenfica la duración de la prueba para el momento indicado
 */
export const getTimeDurationFromActivityDefinition = (
  activityDefinition,
  moment
) => {
  if (activityDefinition && activityDefinition.extension) {
    const extension = getExtension(activityDefinition.extension, SYSTEM_OBSERVATION_DEFINITION_MAX_DURATION_MS + (moment ? "/" + moment : "")
    );
    return extension[0] ? extension[0].valueInteger : 0;
  } else {
    return 0;
  }
};
