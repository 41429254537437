import { ToastNotification } from "@carbon/react";
import { useEffect, useState } from "react"
import ReactDOM from 'react-dom'

const containerId = "toast-container";

export default function useNotification(){

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if( !document.getElementById(containerId) ){
      const newContainer = document.createElement("div");
      newContainer.id = containerId;
      document.body.appendChild(newContainer);
    }

    const container = document.getElementById(containerId);
    const ntfs = notifications.map(toast)
    ReactDOM.render(ntfs, container)
  }, [notifications])

  const toast = ({title='', caption='', kind='success', timeout=3000, key}) => {
    return <ToastNotification key={key} kind={kind} timeout={timeout} title={title} caption={caption} />
  }

  const show = (notification) => {
    setNotifications(current => [...current, notification])
  }

  return {
    success: (notification) => show({...notification, kind:"success", key:Date.now()}),
    warning: (notification) => show({...notification, kind:"warning", key:Date.now()}),
    error: (notification) => show({...notification, kind:"error", key:Date.now()})
  }
}