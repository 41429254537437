import React, { useState, useEffect } from 'react';
import PatientSingleAssay from './PatientSingleAssay';
import CloneComponentWithProps from 'common/CloneComponentWithProps';

export default function PatientAssays({ patient, assays, onAssaysChange, showAssayGroups, practitionerRole }) {
  const [componentAssays, setComponentAssays] = useState();

  /**
   * Función que actualiza la lista de ensayos con las modificaciones de un ensayo.
   * @param {Array} assays 
   */
  const onAssayChangeHandler = (assay) => {
    assays &&
      onAssaysChange(assays.map(item => (item.code === assay.code) ? assay : item));
  };

  /**
   * Inicializa el estado que contiene los datos de este componente.
   */
  useEffect(() => {
    if (assays) {
      setComponentAssays(assays);
    }
  }, [assays]);

  return (
    <div className='assays'>
      {
        componentAssays && componentAssays.map(assayItem =>
          <CloneComponentWithProps key={"patient-single-assay-" + assayItem.code} patient={patient} assay={assayItem} onAssayChange={onAssayChangeHandler} showAssayGroups={showAssayGroups} practitionerRole={practitionerRole}>
            <PatientSingleAssay />
          </CloneComponentWithProps>)
      }
    </div>
  );
}
