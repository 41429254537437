// Funcionalidades para gestión de recurso Bundle
import http from 'common/HttpService';

const Bundle = {
  executeBundle: async (data, returnRepresentation = false) => {
    const headers = returnRepresentation ? { headers: { 'Prefer': 'return=representation' } } : {};
    var res = await http.post('/', data, headers);
    return res;
  }
};

export default Bundle;