import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Tab, TabPanel, TabPanels, TabList, Tabs, Tile } from '@carbon/react';
import Overlay from 'layout/overlay/Overlay';
import Patient from 'api/Patient';
import PatientDataComponent from 'patients/PatientDataComponent';
import PatientAgenda from 'agenda/PatientAgenda';
import { PatientContext } from 'patients/PatientContext';
import useNotification from 'hooks/useNotification';
import PatientSummary from './PatientSummary';
import { SYSTEM_ASSAY, ASSAY_MAP, SYSTEM_ASSAY_GROUP } from 'api/constants/tags/AssayTags';
import { getExtensionString, getTagCodesBySystem, addExtensionBoolean, addExtensionString, deleteTags } from 'api/constants/tags/TagFunctions';
import { PATIENT_ONE_TIME_PASSWORD, PATIENT_USERNAME, RESET_PATIENT_PASSWORD } from 'api/constants/tags/PatientTags';
import PatientUserManagement from './PatientUserManagement';

export default function PatientDetails({ onDeactivate }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const notification = useNotification();
  const [patientData, setPatientData] = useState(null);
  const [patientAssays, setPatientAssays] = useState([]);
  const [patientUserInfo, setPatientUserInfo] = useState({});

  const [userAction, setUserAction] = useState({ activeTab: '', timestamp: null });
  const [searchValues, setSearchValues] = useState({ assay: searchParams.get('assay') || '', date: searchParams.get('date') || '' });
  const [tabIndex, setTabIndex] = useState(0);

  const deactivatePatient = () => {

    if (patientData) {
      let patient = patientData;
      patient.active = false;
      patient.meta.tag = deleteTags(patient.meta.tag, SYSTEM_ASSAY);
      patient.meta.tag = deleteTags(patient.meta.tag, SYSTEM_ASSAY_GROUP);
      Patient.updatePatient(patient).then(() => {
        onDeactivate();
        navigate('/patients');
      });
    }
  };

  const updateUserAction = (key) => {
    const updatedUserAction = {
      activeTab: key,
      timestamp: new Date()
    };
    setUserAction(updatedUserAction);
  };

  const savePatientUserInfo = (patientData) => {
    const user = getExtensionString(patientData.extension, PATIENT_USERNAME);
    const pwd = getExtensionString(patientData.extension, PATIENT_ONE_TIME_PASSWORD);
    if (user.length > 0 && pwd.length > 0) {
      setPatientUserInfo({
        user: user[0],
        password: pwd[0]
      });
    }
  };

  useEffect(() => {
    patientAssays.forEach((item, index) => {
      if (item.id === searchValues.assay) {
        updateUserAction(searchValues.assay);
        setTabIndex(index + 1);
      }
    });
  }
    , [searchValues, patientAssays]);

  useEffect(() => {
    if (patientData) {
      setPatientAssays(getTagCodesBySystem(patientData.meta, [SYSTEM_ASSAY]).map(item => { return { id: item }; }));
      savePatientUserInfo(patientData);
    }
  }
    , [patientData]);

  useEffect(() => {
    Patient.getPatient(id)
      .then(setPatientData)
      .catch(e => {
        console.log("Error cargando paciente", e);
        notification.error({ title: "Error cargando paciente", caption: "No ha sido posible cargar los datos del paciente. Contacte con el administrador." });
        navigate('/patients');
      });
  },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [id]);

  const changeTab = (index, assay = '') => {
    setTabIndex(index);
    if (assay) {
      updateUserAction(assay.id);
    }
  };

  const resetPassword = () => {
    const localPatient = patientData;
    addExtensionBoolean(localPatient.extension, RESET_PATIENT_PASSWORD, true);
    addExtensionString(localPatient.extension, PATIENT_ONE_TIME_PASSWORD, Patient.generateRandomPassword());
    setPatientData({ ...localPatient });

    Patient.updatePatient(localPatient).then(() => {
      notification.success({ title: "Operación realizada", caption: "El paciente ha sido modificado correctamente" });
    }).catch(() => {
      notification.error({ title: "Operación NO realizada", caption: "Ha ocurrido un error y no se ha podido modificar el paciente" });
    });
  };

  return (
    <PatientContext.Provider value={patientData}>
      <Overlay className="patient-details">
        <div className="heading">
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem><Link to="/patients">Pacientes</Link></BreadcrumbItem>
            <BreadcrumbItem href="#" isCurrentPage>Detalle de paciente</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <Tile className="patient-data-component">
          <PatientDataComponent />
        </Tile>
        <Tabs selectedIndex={tabIndex}>
          <TabList aria-label={'Pestañas con información del paciente y posibles acciones a ejecutar'}
            activation={'manual'}>
            <Tab onClick={() => changeTab(0)}>Resumen</Tab>
            {
              patientAssays && patientAssays.map((assay, index) =>
                <Tab key={assay.id} onClick={() => changeTab(index + 1, assay)}>{ASSAY_MAP.get(assay.id) ? ASSAY_MAP.get(assay.id).display : 'Ensayo'}</Tab>
              )
            }
            <Tab onClick={() => changeTab(patientAssays.length + 1)}>Administrar</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <PatientSummary patient={patientData} onPatientUpdate={setPatientData} />
            </TabPanel>
            {patientAssays && patientAssays.map(assay =>
              <TabPanel key={assay.id}>
                <PatientAgenda assay={assay.id} loadTimestamp={userAction.timestamp} activeTab={userAction.activeTab} date={searchValues.date} />
              </TabPanel>
            )
            }
            {Object.keys(patientUserInfo).length > 0 ?
              <PatientUserManagement patientUserInfo={patientUserInfo} resetPassword={resetPassword} deactivatePatient={deactivatePatient}></PatientUserManagement>
              :
              <TabPanel>
                <p>Tareas de gestión</p>
                <div>
                  <Button kind="danger" onClick={deactivatePatient}>Eliminar registro de paciente</Button>
                </div>
              </TabPanel>
            }
          </TabPanels>
        </Tabs>
      </Overlay>
    </PatientContext.Provider>
  );
}
