import React, { useState, useEffect, useCallback } from 'react';

import { SkeletonText, SkeletonIcon, Toggle, TextArea } from '@carbon/react';

import useNotification from 'hooks/useNotification';

import { OBSERVATION_STATUS_FINAL, OBSERVATION_STATUS_DEFAULT } from 'api/constants/ObservationStatus';

import { updateObservation, fillObservationDataValue }
  from 'components/Observation/ObservationService';
import { Item } from 'components/GenericFields';
import RecorderTitle from 'components/RecorderTitle';
import StatusTag from 'components/StatusTag';

import SpO2RecorderBeurerPO60, { RECORDER_DATA_TYPE as SPO2_RECORDER_DATA_TYPE, OBSERVATION_CODES } from 'components/SpO2Recorder/BeurerPO60/SpO2RecorderBeurerPO60';
import { CODE_OBSERVATION_PULSE_OXIMETER_SATURATION, CODE_OBSERVATION_PULSE_OXIMETER_SATURATION_MIN, CODE_OBSERVATION_PULSE_OXIMETER_SATURATION_MAX }
  from "api/constants/tags/ObservationTags";

import 'components/Observation/_GenericObservationsComponent.scss';

import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';

/**
 * Componente que permite visualizar y completar una medición genérica a partir de su definición 
 * y observaciones asociadas.
 * 
 * Este componente cuenta con 2 states principales:
 *  - observations: lista de Observation existentes en FHIR y que podrán modificarse.
 *  - observationsData: lista de los valores de las observation. Se utiliza como modelo para los inputs de datos que 
 *                  se muestran al usuario.
 * 
 * @param {Object} observationDefinition ObservationDefinition FHIR con la descripción de la variable a tomar.
 * @param {Object} observation observación a mostrar en este componente.
 * @param {Number} occurrence número de ocurrencia de la observación.
 * @param {Object} carePlan carePlan que contiene la ServiceRequest en la que se solicita el registro de la Observation.
 * @param {Object} serviceRequest ServiceRequest que contiene los datos de la cita para la creación/modificación de la Observation.
 * @param {Object} encounter Encounter que contiene los datos de la ejecución de la cita.
 * @param {Object} patient objeto FHIR con los datos del paciente.
 * @param {Object} practitionerRole objeto FHIR con los datos del practitioner.
 * @param {func}   handleChangeObservation función a ejecuta cada vez que se modifica algún dato de la observación para trasladar
 *                 la información al componente superior.
 * @param {boolean} readOnly indica si el componente de visualización de mediciones es de sólo lectura. Por defecto, no lo será
 */
export default function GenericSingleObservationComponent({ observationDefinition, observation: inputObservation = null, occurrence = 1,
  carePlan, serviceRequest, encounter, patient, practitionerRole, handleChangeObservation = () => { }, readOnly = false }) {

  const DEBOUNCE_TIMEOUT = 500;

  const [observation, setObservation] = useState();
  const [observationData, setObservationData] = useState();
  const [verifiedObservation, setVerifiedObservation] = useState(null);

  const [observationToSave, setObservationToSave] = useState(null);

  const [bluetoothLoadedValues, setBluetoothLoadedValues] = useState();

  const notification = useNotification();

  /**
   * Función que mapea una Observation a un ObservationData para que se puedan renderizar los datos
   * en los inputs y el usuario pueda verlos y modificarlos.
   * 
   * La estructura de un ObservationData es la siguiente:
   *  - id: identificador único de la Observation: campo id.
   *  - device: nombre del dispositivo que ha generado la observación. Campo device.display.
   *  - note: texto introducido por el usuario para comentar la observación. Solo se permite 1 a pesar
   *          de que FHIR permite una lista. Campo note.
   *  - code: objeto FHIR de tipo Code que identifica a la Observación. Puede coincidir o no con el código de la/s variable/s.
   *  - value: array de valores de la Observation. Cada elemento es una variable dependiente del tipo de dato de la 
   *          ObservationDefinition pero el código de cada variable depende de una extension de ObservationDefinition.
   *  - status: estado de la observación
   * @return {Object} ObservationsData con los datos de la observación.
   */
  const mapObservationToObservationData = () => {
    return {
      id: observation.id,
      device: observation.device ? observation.device.display : '',
      note: observation.note && observation.note.length ? observation.note[0] : null,
      code: observation.code,
      value: fillObservationDataValue(observationDefinition, observation.component ? observation.component : observation.value.component),
      status: observation.status
    };
  };

  /**
   * Función que mergea los datos de la Observacion cargada con los datos existentes en ObservationsData.
   * @param {Object} updatedObservationData objeto con los datos de la observación introducidos por 
   *                  el usuario.
   * 
   * @return {Object} Observation con los datos de la observación actualizados.
   */
  const updateObservationWithObservationData = (updatedObservationData) => {
    let finalObservation = null;
    if (observation) {
      finalObservation = updateObservation(observationDefinition, observation,
        carePlan, patient, practitionerRole, updatedObservationData);
    }

    if (!finalObservation) {
      notification.error(
        {
          title: `Error generando  ${observationDefinition.method.text}`,
          caption: `No ha sido posible generar los datos de la medición ${observationDefinition.method.text}. Contacte con el administrador.`
        }
      );
    }
    return finalObservation;
  };

  /**
   * Actualiza la Observation con el valor del parámetro de entrada.
   */
  useEffect(() => {
    if (inputObservation) {
      setObservation(inputObservation);
    }
  }, [inputObservation]);

  /**
   * Inicializa el ObservationData que servirá como modelo 
   * para los inputs de datos que los usuarios pueden modificar.
   * Se actualiza el ObservationData cada vez que la Observation se modifica.
   */
  useEffect(() => {
    if (observation) {
      setObservationData(mapObservationToObservationData());
    }
  }, [observation]);

  /**
   * Lanza la modificación de la observación cuando existe valor en observationToSave.
   * 
   * Necesario para:
   * - no entrar en bucle cuando el usuario actualiza los datos
   * - lanzar el debounce la la actualización de las notas.
   */
  useEffect(() => {
    if (observationToSave && !readOnly) {
      handleChangeObservation(observationToSave);
    }
  }, [observationToSave]);

  /**
   * Actualiza la Observation con los datos introducidos por el usuario.
   * 
   * @param {Object} event con los datos actualizados del input cuyo id corresponde 
   * a una ObservationData y a una Observation.
   */
  const handleInputChange = (event) => {
    if (!readOnly) {
      const target = event.target;
      const id = target.id; // identificar único
      const index = id.substring(id.lastIndexOf(":") + 1); // la última parte del identificador en el índice del component
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const updatedObservation = updateObservationComponentByIndex(index, value);
      if (updatedObservation) {
        setObservationToSave(updatedObservation);
      }
    }
  };

  const updateObservationComponentByIndex = (index, value) => {
    if (value) {
      let updatedObservationData = cloneDeep(observationData);
      updatedObservationData.value[index].value = value;
      updatedObservationData.status = verifiedObservation ? OBSERVATION_STATUS_FINAL : updatedObservationData.status;
      return updateObservationWithObservationData(updatedObservationData);
    }
    return;
  };

  /**
  * Actualiza la observación si se marca/desmarca la verificación de esta.
  * 
  * @param {boolean} checked valor que indica si el input está activo o no.
  */
  const handleVerified = (checked) => {
    if(!readOnly){
      let statusUpdatedObservationData = cloneDeep(observationData);
      statusUpdatedObservationData.status = checked ? OBSERVATION_STATUS_FINAL : OBSERVATION_STATUS_DEFAULT;
      setVerifiedObservation(checked);
      const updatedObservation = updateObservationWithObservationData(statusUpdatedObservationData);
      if (updatedObservation) {
        setObservationToSave(updatedObservation);
      }
    }
  };

  /**
   * Retrasa la actualización de la observation con los datos introducidos por el usuario.
   */
  const debouncedObservationUpdate = useCallback(
    debounce(nextValue => setObservationToSave(nextValue), DEBOUNCE_TIMEOUT)
    , [observation]);

  /** clean up */
  useEffect(() => {
    return () => {
      debouncedObservationUpdate.cancel();
    };
  }, []);

  /**
   * Actualiza la nota de una observación mediante una función que retrasa el envío
   * de los datos.
   * 
   * @param {Object} event evento con el texto insertado por el usuario.
   */
  const handleNoteChange = (event) => {
    if(!readOnly){
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      let updatedObservationData = cloneDeep(observationData);
      updatedObservationData.note = { text: value || '' };
      setObservationData(updatedObservationData); // actualizamos para los inputs
      const updatedObservation = updateObservationWithObservationData(updatedObservationData);
      debouncedObservationUpdate(updatedObservation);
    }
    
  };


  /** 
   * Función que se envía al componente de Bluetooth para recuperar los datos y dejarlos disponibles 
   * en un objeto dentro de un state.
  */
  const retrieveBluetoothValues = (bluetoothValues) => {
    const mapperBluetoothToObservationComponent = {
      sPO2Max: CODE_OBSERVATION_PULSE_OXIMETER_SATURATION_MAX,
      sPO2Min: CODE_OBSERVATION_PULSE_OXIMETER_SATURATION_MIN,
      sPO2Avg: CODE_OBSERVATION_PULSE_OXIMETER_SATURATION,
    };

    const returnedValues = { device: bluetoothValues.device };
    for (const [key, value] of Object.entries(bluetoothValues)) {
      const mappedField = mapperBluetoothToObservationComponent[key];
      if (mappedField) {
        returnedValues[mappedField] = value;
      }
    }
    setBluetoothLoadedValues(returnedValues);
  };

  useEffect(() => {

    if (!bluetoothLoadedValues) {
      return;
    }

    let updatedObservationData = cloneDeep(observationData);
    updatedObservationData.device = bluetoothLoadedValues.device;
    Object.entries(bluetoothLoadedValues).forEach(([key, value]) => {
      const indexToUpdate = updatedObservationData.value?.findIndex(item => item.code.coding[0].code === key);
      if (indexToUpdate >= 0) {
        updatedObservationData.value[indexToUpdate].value = value;
      }
    });

    const updatedObservation = updateObservationWithObservationData(updatedObservationData);
    if (updatedObservation) {
      notification.success(
        {
          title: `Cargados datos de ${observation.code.text}`,
          caption: `Se han cargado datos de ${observation.code.text} desde el dispositivo ${updatedObservationData.device}.`
        }
      );
      setObservationToSave(updatedObservation);
    }

  }, [bluetoothLoadedValues]);

  return (
    <div className="recorder coperia--observation-item">
      <RecorderTitle title={(observationDefinition.code.text) + ' #' + (occurrence + 1)} >
        {
          observationData ?
            <StatusTag status={observationData ? observationData.status : OBSERVATION_STATUS_DEFAULT} />
            : <SkeletonIcon />
        }
      </RecorderTitle>
      <div className='coperia--observation-item-row'>
        {
          observationData && observationData.value ?
            observationData.value.map((item, index) => {
              return <div key={`div:${observationData.id}:${index}`}>
                <Item key={`${observationData.id}:${index}`} props={{
                  id: `${observationData.id}:${index}`,
                  type: observationDefinition.permittedDataType[0],
                  label: observationDefinition.multipleResultsAllowed ? item.code.text : observationDefinition.code.text,
                  item: item,
                  value: item.value,
                  values: [],
                  onChange: handleInputChange,
                  generateId: (id) => id,
                  unit: observationDefinition.quantitativeDetails ? observationDefinition.quantitativeDetails.unit.text : null,
                  readOnly: readOnly
                }} />
              </div>;
            })
            : <div className="item"><SkeletonText /></div>
        }
        {
          (OBSERVATION_CODES.includes(observationDefinition.code.coding?.[0].code) && SPO2_RECORDER_DATA_TYPE === observationDefinition.permittedDataType[0]) &&
          <div>
            <SpO2RecorderBeurerPO60 updater={retrieveBluetoothValues} readOnly={readOnly}/>
          </div>
        }
      </div>
      <div className='coperia--observation-item-row'>
        {
          observationData ? <Toggle id={`${observationData.id}:toggle:${occurrence}`}
            labelText="Marque para indicar que esta medición es correcta."
            labelA="No verificado" labelB="Verificado"
            onToggle={handleVerified}
            toggled={(observationData) ? observationData.status === OBSERVATION_STATUS_FINAL : false}
          />
            : <SkeletonIcon />
        }
      </div>
      <div className='coperia--observation-item-row'>
        {
          observationData ?
            <TextArea labelText="Notas" cols={50} rows={4} id={`${observationData.id}:note:${occurrence}`}
              value={observationData.note ? observationData.note.text : ''} onChange={handleNoteChange}
              className='coperia--note'
            />
            : <SkeletonText />
        }
      </div>
    </div>);
}