import http from 'common/HttpRocketChatService';


const RocketChat = {
  login: async (body) => {
    const res = await http.post('/login', body);
    return res?.data;
  },
  listUsersFilteredByUsername: async (username, headers) => {
    const query = `query={"username":"${username}"}`;
    const res = await http.get(`/users.list?${query}`, headers);
    return res.data;
  },
  createDirectMessageRoom: async (body, headers) => {
    const res = await http.post('/im.create', body, headers);
    return res.data;
  },
};

export default RocketChat;