import { Button, InlineLoading } from "@carbon/react";
import { Reset } from "@carbon/react/icons";
import CovidPersistentPrediction from "api/CovidPersistentPrediction";
import useNotification from "hooks/useNotification";
import { useState } from "react";

export default function PatientCovidPersistentPrediction({ patient }) {
  const notification = useNotification();
  const [prediction, setPrediction] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showPrediction, setShowPrediction] = useState(false);
  const [hasNoPrediction, setHasNoPredictionData] = useState(false);

  async function predict(patient) {
    setIsSubmitting(true);
    await CovidPersistentPrediction.getPrediction(patient.id)
      .then((response) => {
        if (response.data) {
          setPrediction(response.data.prediction);
          setShowButton(false);
          setShowPrediction(true);
        } else {
          notification.error({
            title: "Operación NO realizada",
            caption: "Error en la respuesta de la predicción",
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setShowButton(false);
          setHasNoPredictionData(true);
        } else {
          notification.error({
            title: "Operación NO realizada",
            caption: "Error al realizar la predicción",
          });
        }
      });

    setIsSubmitting(false);
  }

  const handleSubmit = () => {
    predict(patient);
  };

  return (
    <>
      {isSubmitting ? (
          <InlineLoading
          />
      ) : showButton ? (
        <Button
          onClick={handleSubmit}
          renderIcon={Reset}
          iconDescription="Reset"
        >
          Predicción COVID persistente
        </Button>
      ) : (
        <></>
      )}
      {showPrediction && !isSubmitting ? (
                  <Button disabled={true} kind="ghost">{prediction}</Button>
      ) : (
        hasNoPrediction && (
          <Button disabled={true} kind="ghost">Sin datos para la predicción</Button>
        )
      )}
    </>
  );
}
