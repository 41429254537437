import React, { useState, useEffect } from 'react';
import { FileUploaderDropContainer, Toggle, Tooltip } from '@carbon/react';
import { DocumentAttachment, CloseOutline } from '@carbon/react/icons';
import RecorderTitle from 'components/RecorderTitle';
import StatusTag from 'components/StatusTag';
import { getFirstTag } from 'api/constants/tags/TagFunctions';
import { updateObservation } from 'components/Observation/ObservationService';
import { toISOString } from 'common/DateTimeUtils';
import { OBSERVATION_STATUS_DEFAULT, OBSERVATION_STATUS_FINAL, OBSERVATION_STATUS_PRELIMINARY } from 'api/constants/ObservationStatus';
import generateUuid from 'common/GenerateUuid';
import { b64ToBlob } from 'common/BlobUtils';
import { SYSTEM_OBSERVATION_MOMENT } from 'api/constants/tags/ObservationTags';

export const RECORDER_DATA_TYPE = "CodeableConcept"; // FHIR DataType managed by this component

export default function FileRecorder({ observationDefinition, observation, occurrence, carePlan, serviceRequest, encounter, patient, practitionerRole, handleChangeObservation = () => { }, readOnly = false}) {
  const [file, setFile] = useState({ filename: null, fileUrl: null, updated: false });
  const [localObservation, setLocalObservation] = useState(null);
  const [status, setStatus] = useState(OBSERVATION_STATUS_DEFAULT);
  const observationMoment = getFirstTag(observationDefinition.meta, SYSTEM_OBSERVATION_MOMENT);

  useEffect(() => {
    if (!file.updated && (observation && observation.contained && observation.contained[0].content[0].attachment.data)) {
      setStatus(observation.status || OBSERVATION_STATUS_DEFAULT);
      setLocalObservation(observation);
      const fileUrl = window.URL.createObjectURL(b64ToBlob(observation.contained[0].content[0].attachment.data));
      setFile({ filename: observation.contained[0].content[0].attachment.title, fileUrl: fileUrl, updated: false });
    }
  },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [observation]);

  useEffect(() => {
    if (file.updated) {
      setStatus(OBSERVATION_STATUS_DEFAULT);
    }
  }, [file.updated]);

  function addFile(addedFile) {
    if(!readOnly){
      createDocumentReference(generateUuid(), addedFile).then((documentReference) => {
        const obvData = {
          status: OBSERVATION_STATUS_PRELIMINARY,
          value: [
            {
              derivedFrom: documentReference
            }
          ]
        };
        const obv = updateObservation(observationDefinition, observation, carePlan, patient, practitionerRole, obvData);
        setLocalObservation(obv);
        const url = window.URL.createObjectURL(addedFile);
        setFile({ filename: addedFile.name, fileUrl: url, updated: true });
        handleChangeObservation(obv);
      });
    }
    
  }

  function removeFile() {
    if(!readOnly){
      localObservation.contained = [];
      localObservation.derivedFrom = [];
      setLocalObservation({ ...localObservation });
      handleChangeObservation({ ...localObservation });
      setFile({ filename: null, fileUrl: null, updated: true });
    }
    
  }

  const handleVerified = (checked) => {
    if(!readOnly){
      let status = OBSERVATION_STATUS_DEFAULT;
      if (checked) {
        status = OBSERVATION_STATUS_FINAL;
      } else if (!checked && (localObservation && !localObservation.id.startsWith('temp-'))) {
        status = OBSERVATION_STATUS_PRELIMINARY;
      }
      setFile(prevFile => { return { ...prevFile, updated: true }; });
      setStatus(status);
      const newLocalObservation = { ...localObservation, status: status };
      setLocalObservation(newLocalObservation);
      handleChangeObservation(newLocalObservation);
    }
  };

  return (
    <div className="file-recorder recorder coperia--observation-item">
      <RecorderTitle title={observationDefinition.code.text + ' #' + (occurrence + 1) }>
        <StatusTag status={status} />
      </RecorderTitle>
      <div className="file-wrapper coperia--observation-item-row">
        {
          !file.filename ? <FileUploaderDropContainer className="drop-container"
            labelText={"Suelta un fichero aquí o haz click para seleccionar uno"}
            accept={['text/plain']} onAddFiles={(e, { addedFiles }) => { addFile(addedFiles[0]); }} />
            :
            <div className='file-verif'>
              <div className="file">
                <DocumentAttachment className="icon" size={64} />
                <div className='filename'><a href={file.fileUrl} download={file.filename}>{file.filename}</a></div>
                <span className='closeButton'>
                  <Tooltip align="bottom" label={'Eliminar medición'}>
                    <button><CloseOutline size={20} onClick={removeFile} /></button>
                  </Tooltip>
                </span>
              </div>
              <Toggle labelText="Marque para indicar que esta medición es correcta." labelA="No verificado" labelB="Verificado"
                id={`${observationDefinition.method.coding[0].code}:${occurrence}:${observationMoment}:toggle`}
                onToggle={handleVerified}
                toggled={localObservation ? localObservation.status === OBSERVATION_STATUS_FINAL : false} />
            </div>
        }
      </div>
    </div>
  );

  function createDocumentReference(uuid, file) {
    return new Promise(resolve => {
      const documentReference = {
        resourceType: "DocumentReference",
        id: uuid,
        status: 'current',
        date: toISOString(new Date()).split('.')[0] + "Z",
        content: [{
          attachment: "",
          format: {
            "system": "http://ihe.net/fhir/ValueSet/IHE.FormatCode.codesystem",
            "code": "urn:ihe:iti:xds:2017:mimeTypeSufficient",
            "display": "mimeType Sufficient"
          }
        }]
      };

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        documentReference.content[0].attachment = {
          data: reader.result.split(',')[1],
          title: file.name,
          contentType: "text/plain"
        };
        resolve(documentReference);
      };
    });
  }
};
