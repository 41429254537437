import React from 'react';
import { Button } from '@carbon/react';
import { CloseFilled, Save, MicrophoneFilled } from '@carbon/react/icons';
import Timer from 'components/Timer';

export default function RecorderControls({ recorderState, handlers, config, readOnly = false }) {
  const { initRecording } = recorderState;
  const { startRecording, saveRecording, cancelRecording, stopTimer } = handlers;

  return (
    <div className='recorder-controls'>
      <div className='d-flex center-vertically center-horizontally'>
        {initRecording ? (
          <Button kind="ghost" hasIconOnly renderIcon={Save} iconDescription='Guardar grabación'
            className="start-button" title="Guardar grabación" tooltipPosition="bottom" onClick={stopTimer} disabled={readOnly}/>
        ) : (
          <Button kind="ghost" hasIconOnly renderIcon={MicrophoneFilled} iconDescription="Comenzar grabación"
            className="start-button" title="Comenzar grabación" tooltipPosition="bottom" onClick={startRecording} disabled={readOnly}/>
        )}
        <div className="recording-time">
          <Timer length={config.maxRecordTime} run={initRecording} direction={config.countDirection} format={'mm:ss'} onFinish={saveRecording} disabled={readOnly}/>
        </div>
      </div>
      {initRecording && (
        <div className="cancel-button-container">
          <Button kind="ghost" hasIconOnly renderIcon={CloseFilled} iconDescription="Cancelar grabación"
            className="cancel-button" title="Cancelar grabación" tooltipPosition="bottom" onClick={cancelRecording} disabled={readOnly}/>
        </div>
      )}
    </div>
  );
}