export const ASSAY_GROUP_COPERIA_REHAB_CONTROL = "coperia-rehab-control";
export const ASSAY_GROUP_COPERIA_REHAB_INTERVENTION = "coperia-rehab-intervention";
export const ASSAY_GROUP_COPERIA_COG_CONTROL = "coperia-cog-control";
export const ASSAY_GROUP_COPERIA_COG_INTERVENTION = "coperia-cog-intervention";
export const SYSTEM_ASSAY = "http://coperia.es/fhir/assay";
export const SYSTEM_ASSAY_INITIAL_DATE = SYSTEM_ASSAY + "/initial-date-";
export const SYSTEM_ASSAY_INITIAL_DATE_DICOPERIA = SYSTEM_ASSAY_INITIAL_DATE + "dicoperia";
export const SYSTEM_ASSAY_INITIAL_DATE_COPERIA_COG = SYSTEM_ASSAY_INITIAL_DATE + "coperia-cog";
export const SYSTEM_ASSAY_INITIAL_DATE_COPERIA_REHAB = SYSTEM_ASSAY_INITIAL_DATE + "coperia-rehab";
export const SYSTEM_ASSAY_GROUP = "http://coperia.es/fhir/assay-group";
export const SYSTEM_ASSAY_PHASE = "http://coperia.es/fhir/assay-phase";

export const ASSAY_GROUP_MAP = new Map([
  [ASSAY_GROUP_COPERIA_REHAB_CONTROL, { system: SYSTEM_ASSAY_GROUP, code: ASSAY_GROUP_COPERIA_REHAB_CONTROL, display: "Grupo de control en el ensayo de recuperación física" }],
  [ASSAY_GROUP_COPERIA_REHAB_INTERVENTION, { system: SYSTEM_ASSAY_GROUP, code: ASSAY_GROUP_COPERIA_REHAB_INTERVENTION, display: "Grupo de intervención en el ensayo de recuperación física" }],
  [ASSAY_GROUP_COPERIA_COG_CONTROL, { system: SYSTEM_ASSAY_GROUP, code: ASSAY_GROUP_COPERIA_COG_CONTROL, display: "Grupo de control en el ensayo de recuperación cognitiva" }],
  [ASSAY_GROUP_COPERIA_COG_INTERVENTION, { system: SYSTEM_ASSAY_GROUP, code: ASSAY_GROUP_COPERIA_COG_INTERVENTION, display: "Grupo de intervención en el ensayo de recuperación cognitiva" }]
]);

export const ASSAY_DICOPERIA = "dicoperia";
export const ASSAY_COPERIA_REHAB = "coperia-rehab";
export const ASSAY_COPERIA_COG = "coperia-cog";

export const ASSAY_MAP = new Map([
  [ASSAY_DICOPERIA, { system: SYSTEM_ASSAY, code: ASSAY_DICOPERIA, display: "DICOPERIA" }],
  [ASSAY_COPERIA_REHAB, { system: SYSTEM_ASSAY, code: ASSAY_COPERIA_REHAB, display: "COPERIA-REHAB" }],
  [ASSAY_COPERIA_COG, { system: SYSTEM_ASSAY, code: ASSAY_COPERIA_COG, display: "COPERIA-COG" }]
]);

export const ASSAYS_INITIAL_DATE_MAP = new Map([
  [ASSAY_DICOPERIA, { system: SYSTEM_ASSAY_INITIAL_DATE_DICOPERIA }],
  [ASSAY_COPERIA_REHAB, { system: SYSTEM_ASSAY_INITIAL_DATE_COPERIA_REHAB }],
  [ASSAY_COPERIA_COG, { system: SYSTEM_ASSAY_INITIAL_DATE_COPERIA_COG }]
]);

export const ASSAYS_INITIAL_DATE_URL_LIST = [SYSTEM_ASSAY_INITIAL_DATE_DICOPERIA, SYSTEM_ASSAY_INITIAL_DATE_COPERIA_COG, SYSTEM_ASSAY_INITIAL_DATE_COPERIA_REHAB];

export const ASSAY_PHASE_INITIAL = "initial-evaluation";
export const ASSAY_PHASE_TREATMENT = "treatment";
export const ASSAY_PHASE_FINAL = "final-evaluation";
export const ASSAY_PHASE_COMMUNICATION = "communication";

export const ASSAY_PHASE_MAP = new Map([
  [ASSAY_PHASE_INITIAL, { system: SYSTEM_ASSAY_PHASE, code: ASSAY_PHASE_INITIAL, display: "Fase de evaluación inicial del paciente" }],
  [ASSAY_PHASE_TREATMENT, { system: SYSTEM_ASSAY_PHASE, code: ASSAY_PHASE_TREATMENT, display: "Fase de tratamiento del paciente" }],
  [ASSAY_PHASE_FINAL, { system: SYSTEM_ASSAY_PHASE, code: ASSAY_PHASE_FINAL, display: "Fase de evaluación final del paciente" }]
]);