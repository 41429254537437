// Funcionalidades para gestión de recurso Questionnaire
import http from 'common/HttpService';

const Questionnaire = {

    getQuestionnaires: async () => {
        const res = await http.get('/Questionnaire?_count=500');
        return res.data;
    },
    getQuestionnaire: async (id, elements) => {
        let query = `/Questionnaire/${id}?`;
        if (elements) {
            query = elements.reduce(
                (previousValue, currentValue) => previousValue + ',' + currentValue,
                query
            );
        }
        const res = await http.get(query);
        return res.data;
    }
};

export default Questionnaire;