import React from 'react';

export default function RecorderTitle({ title, children }) {
  return (
    <div className='title d-flex center-horizontally center-vertically coperia-observation-title'>
      <div className='title-text coperia-observation-title-text'>{title}</div>
      <div className='coperia-observation-title-tag'>{children}</div>
    </div>
  );
}
