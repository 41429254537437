import React, { useEffect, useState } from 'react';
import BluetoothButton from 'components/BluetoothButton/BluetoothButton';
import { Toggle, Tooltip } from '@carbon/react';
import { CloseOutline } from '@carbon/react/icons';
import cloneDeep from 'lodash/cloneDeep';
import { OBSERVATION_STATUS_DEFAULT, OBSERVATION_STATUS_FINAL, OBSERVATION_STATUS_PRELIMINARY } from 'api/constants/ObservationStatus';
import RecorderTitle from 'components/RecorderTitle';
import { getExtensionCoding, getFirstTag } from 'api/constants/tags/TagFunctions';
import StatusTag from 'components/StatusTag';
import { SYSTEM_OBSERVATION_MOMENT } from 'api/constants/tags/ObservationTags';
import { updateObservation, OBSERVATION_EFFECTIVE_TYPE } from 'components/Observation/ObservationService';
import { SYSTEM_OBSERVATION_DEFINITION_COMPONENT } from 'api/constants/tags/ObservationDefinitionTags';
import LineChart from 'components/LineChart';

export const RECORDER_DATA_TYPE = "SampledData"; // FHIR DataType managed by this component

export default function HrvRecorder({ observationDefinition, observation, carePlan, serviceRequest, encounter, patient,
  practitionerRole, handleChangeObservation = () => { }, runState, bluetoothState, readOnly = false }) {
  const [localObservation, setLocalObservation] = useState(null);
  const [status, setStatus] = useState(null);
  const [observationModified, setObservationModified] = useState(false);
  const observationMoment = getFirstTag(observationDefinition.meta, SYSTEM_OBSERVATION_MOMENT);
  const occurrence = 1;

  useEffect(() => {
    if ((observation?.value)) {
      if (observation.value.id?.startsWith('temp-') || !hasData(observation.value) || !observation.value.status) {
        setObservationModified(true);
      }
      setLocalObservation(observation.value);
    } else if(observation){
      if (observation?.id?.startsWith('temp-') || !hasData(observation) || !observation.status) {
        setObservationModified(true);
      }
      setLocalObservation(observation);
    }
  },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [observation]);

  useEffect(() => {
    if (runState?.started && !hasData(observation)) {
      setStatus(OBSERVATION_STATUS_DEFAULT);
    }
  },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [runState?.started]);

  useEffect(() => {
    if (observationModified) {
      setStatus(OBSERVATION_STATUS_DEFAULT);
    } else {
      setStatus(observation.status);
    }
  },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [observationModified]);

  useEffect(() => {
    if (runState?.finished && bluetoothState?.stopTime ) {
      const observationValues = getObservationValues(bluetoothState, observationDefinition);
      const obv = updateObservation(observationDefinition, observation, carePlan, patient, practitionerRole, observationValues);
      setLocalObservation(obv);
      setObservationModified(true);
      handleChangeObservation(obv);
    }
  },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [runState?.finished, bluetoothState?.stopTime]);

  const handleVerified = (checked) => {
    if(!readOnly){
      let status = OBSERVATION_STATUS_DEFAULT;
      if (checked) {
        status = OBSERVATION_STATUS_FINAL;
      } else if (!checked && (localObservation && !localObservation.id.startsWith('temp-'))) {
        status = OBSERVATION_STATUS_PRELIMINARY;
      }
      setStatus(OBSERVATION_STATUS_DEFAULT);
      localObservation.status = status;
      setLocalObservation(localObservation);
      handleChangeObservation(localObservation);
    }
  };

  function removeMeassurement() {
    if(!readOnly){
      const newObservation = cloneDeep(localObservation);
      delete newObservation.component;
      delete newObservation.effectivePeriod;
      delete newObservation.device;
      setLocalObservation(newObservation);
      setObservationModified(true);
      handleChangeObservation(newObservation);
    }
  }

  return (
    <div className="hrv-recorder recorder coperia--observation-item">
      <RecorderTitle title={observationDefinition.code.text}>
        <StatusTag status={status} />
      </RecorderTitle>
      <div className="coperia--observation-item-row">
        {
          hasData(localObservation)
            ?
            <>
              {
                localObservation.component.map((component) => {
                  return <LineChart key={`${component.code.coding[0].code}:${occurrence}:${observationMoment}`}
                    component={component} observation={localObservation} />;
                })
              }
              <span className='closeButton d-flex center-horizontally center-vertically'>
                <Tooltip align="bottom" label={'Eliminar medición'}>
                  <button><CloseOutline size={20} onClick={() => { removeMeassurement(); }} /></button>
                </Tooltip>
              </span>
              <Toggle labelText="Marque para indicar que esta medición es correcta." labelA="No verificado" labelB="Verificado"
                id={`${observationDefinition.code.coding[0].code}:${occurrence}:${observationMoment}:toggle`}
                onToggle={handleVerified}
                toggled={localObservation ? localObservation.status === OBSERVATION_STATUS_FINAL : false} />
            </>
            :
            <div className="common--wrapper d-flex center-horizontally center-vertically">
              <BluetoothButton animation={'pulse'} bluetoothState={bluetoothState} />
            </div>
        }
      </div>
    </div>
  );
}

function hasData(localObservation) {
  let hasData = false;
  if (localObservation && localObservation.component) {
    localObservation.component.forEach((component) => {
      if (component.valueSampledData.data) hasData = true;
    });
  }
  return hasData;
}

function getObservationValues(bluetoothState, observationDefinition) {
  const obvservationValues = {
    device: bluetoothState.deviceName,
    status: OBSERVATION_STATUS_PRELIMINARY,
    effective: {
      type: OBSERVATION_EFFECTIVE_TYPE.PERIOD,
      value: {
        start: bluetoothState.startTime,
        end: bluetoothState.stopTime
      }
    },
    value: []
  };
  let valueCodeableConcepts = [];
  if (observationDefinition.multipleResultsAllowed) {
    valueCodeableConcepts = getExtensionCoding(observationDefinition.extension, SYSTEM_OBSERVATION_DEFINITION_COMPONENT);
  } else {
    valueCodeableConcepts.push(observationDefinition.code);
  }
  for (const valueCodeableConcept of valueCodeableConcepts) {
    const obvData = {
      code: { coding: [valueCodeableConcept.coding[0]], text: valueCodeableConcept.text },
      value: bluetoothState.data.get(valueCodeableConcept.coding[0].code) ? bluetoothState.data.get(valueCodeableConcept.coding[0].code).join(' ') : ''
    };
    obvservationValues.value.push(obvData);
  }
  return obvservationValues;
}