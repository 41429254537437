import React, { useState, useEffect } from 'react';
import RecorderControls from 'components/VoiceRecorder/RecorderControls';
import RecordingsList from 'components/VoiceRecorder/RecordingsList';
import { startRecording, saveRecording } from "components/VoiceRecorder/handlers/RecorderControlHandlers";
import RecorderTitle from 'components/RecorderTitle';
import StatusTag from 'components/StatusTag';

const initialState = {
  initRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  audio: null,
  duration: 0
};

export const RECORDER_DATA_TYPE = "CodeableConcept"; // FHIR DataType managed by this component

export default function VoiceRecorder({ observationDefinition, observation, occurrence, carePlan, serviceRequest, encounter, patient,
  practitionerRole, handleChangeObservation = () => { }, maxRecordTime = 300000, timerCountDirection = 'down', maxRecordings = 5, readOnly = false }) {
  const [recorderState, setRecorderState] = useState(initialState);
  const { audio, duration } = recorderState;
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (recorderState.mediaStream)
      setRecorderState((prevState) => {
        return {
          ...prevState,
          mediaRecorder: new MediaRecorder(prevState.mediaStream),
        };
      });
  }, [recorderState.mediaStream]);

  useEffect(() => {
    const recorder = recorderState.mediaRecorder;
    let chunks = [];

    if (recorder && recorder.state === "inactive") {
      recorder.start();

      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' });
        chunks = [];
        setRecorderState((prevState) => {
          if (prevState.mediaRecorder)
            return {
              ...initialState,
              duration: prevState.duration,
              audio: window.URL.createObjectURL(blob)
            };
          else return initialState;
        });
      };
    }

    return () => {
      if (recorder) recorder.stream.getAudioTracks().forEach((track) => track.stop());
    };
  }, [recorderState.mediaRecorder]);

  const handlers = {
    startRecording: () => startRecording(setRecorderState),
    cancelRecording: () => setRecorderState(initialState),
    stopTimer: () => {
      setRecorderState((prevState) => {
        return {
          ...prevState,
          initRecording: false
        };
      });
    },
    saveRecording: (duration) => {
      if (recorderState.mediaRecorder) {
        setRecorderState((prevState) => {
          return {
            ...prevState,
            duration: duration,
          };
        });
        saveRecording(recorderState.mediaRecorder);
      }
    }
  };

  return (
    <div className='voice-recorder recorder coperia--observation-item'>
      <RecorderTitle title={observationDefinition.code.text + ' #' + (occurrence + 1)}>
        <StatusTag status={status} />
      </RecorderTitle>
      <div className='common--wrapper'>
        <RecorderControls recorderState={recorderState} handlers={handlers}
          config={{ maxRecordTime: maxRecordTime, countDirection: timerCountDirection }} readOnly={readOnly} />
        <RecordingsList
          observationDefinition={observationDefinition} observation={observation} occurrence={occurrence} carePlan={carePlan} serviceRequest={serviceRequest} encounter={encounter}
          patient={patient} practitionerRole={practitionerRole} handleChangeObservation={handleChangeObservation} onStatusChange={setStatus}
          audio={audio} duration={duration} maxRecordings={maxRecordings} readOnly={readOnly}/>
      </div>
    </div>
  );
}