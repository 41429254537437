/**
 * Comprueba que dos códigos FHIR sean iguales. Se comprueba si algún elemento del campo coding es coincidente (solo code y ssytem).
 * @param {Object} codeA 
 * @param {Object} codeB 
 * 
 * @returns true en caso de que compartan algo código en común y false en caso contrario.
 */
export const equivalentCodes = (codeA, codeB) => {
  if(!codeA || !codeB) {
    return false;
  }
  if(codeA.url !== codeB.url) {
    return false;
  }

  return codeA.coding.some(itemA => codeB.coding.some(itemB => itemA.system === itemB.system && itemA.code === itemB.code));
};