export async function startRecording(setRecorderState) {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })

    setRecorderState((prevState) => {
      return {
        ...prevState,
        audio:null, 
        duration: 0,
        initRecording: true,
        mediaStream: stream,
      }
    })
  } catch (err) {
    console.log(err)
  }
}

export function saveRecording(recorder) {
  if (recorder.state !== "inactive") recorder.stop()
}
