// Acceso a datos del recurso Communication
import http from 'common/HttpService';

const ENTITY_NAME = 'Communication';

const Communication = {
  get: async (id) => {
    const res = await http.get(`/${ENTITY_NAME}/${id}`);
    return res.data;
  },
  findBy: async (query, count = 200) => {
    const res = await http.get(`/${ENTITY_NAME}?_count=${count}&_page=1&${query}`);
    const { entry = [] } = res.data;
    return entry;
  },
  findAllDataBy: async (query) => {
    const res = await http.get(`/${ENTITY_NAME}?${query}`);
    return res.data;
  },
  search: async (query) => {
    const res = await http.get(`/${ENTITY_NAME}/_search?${query}`);
    const { entry = [] } = res.data;
    return entry;
  },
  update: (communication) => {
    const headers = { headers: { 'Prefer': 'return=representation'} };
    return http.put(`/Communication/${communication.id}`, communication, headers);
  }
};

export default Communication;