import http from 'common/HttpService';

const PractitionerRole = {
  getPractitionerRole : async (practitionerRoleId) => {
    if(practitionerRoleId){
      const res = await http.get(`/PractitionerRole/${practitionerRoleId}`);
      return res.data;
    }
    return "";    
  },
  getPractitionerRoleCodeId : (resource) => {
    if(!resource.specialty || resource.specialty.length === 0){
      return "";
    }
    return PractitionerRole.getCodeFromCoding(resource.specialty[0].coding);
  },
  getCodeFromCoding : (coding) => {
    if(!coding || coding.length === 0){
      return "";
    }
    return coding[0].code;
  }
};


export default PractitionerRole;