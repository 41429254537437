import React from 'react';
import { Bluetooth, BluetoothOff } from '@carbon/react/icons';

export default function BluetoothButton({ bluetoothState, animation, readOnly = false }) {
  return (
    <div className='animation-wrapper'>
      <div className='animation'>
        <div className={`innercircle ${"innercircle__" + animation} ${bluetoothState?.dataToShow && bluetoothState?.dataToShow !== '' ? 'innercircle__start__' + animation : ''}`}></div>
        <div className={`outercircle ${"outercircle__" + animation} ${bluetoothState?.dataToShow && bluetoothState?.dataToShow !== '' ? 'outercircle__start__' + animation : ''}`}></div>
        <div className='info'>
          <div className='bluetoothIcon'>{bluetoothState?.device && !readOnly ? <Bluetooth size={16} /> : <BluetoothOff size={16} />}</div>
          <div className='animationValue'><h1>{bluetoothState ? bluetoothState.dataToShow : ''}</h1></div>
        </div>
        <div className='deviceName'>
          {bluetoothState?.deviceName}
        </div>
      </div>
    </div>
  );
}