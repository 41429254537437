import React, { useState, useEffect } from 'react';
import { Button, Toggle } from '@carbon/react';
import { TrashCan } from '@carbon/react/icons';
import GenerateUuid from "common/GenerateUuid";
import useNotification from "hooks/useNotification";
import { b64ToBlob, getBlobFromUrl } from 'common/BlobUtils';
import { updateObservation } from 'components/Observation/ObservationService';
import { SYSTEM_OBSERVATION_MOMENT } from 'api/constants/tags/ObservationTags';
import { getFirstTag } from 'api/constants/tags/TagFunctions';
import { OBSERVATION_STATUS_DEFAULT, OBSERVATION_STATUS_FINAL, OBSERVATION_STATUS_PRELIMINARY } from 'api/constants/ObservationStatus';
import generateUuid from 'common/GenerateUuid';
import { toISOString } from 'common/DateTimeUtils';

export default function RecordingsList({ observationDefinition, observation, occurrence, carePlan, serviceRequest, encounter, patient,
  practitionerRole, handleChangeObservation = () => { }, onStatusChange, audio, duration, maxRecordings, readOnly = false  }) {
  const [recordings, setRecordings] = useState([]);
  const observationMoment = getFirstTag(observationDefinition.meta, SYSTEM_OBSERVATION_MOMENT);
  const notification = useNotification();

  useEffect(() => {
    const mediaArray = [];
    onStatusChange(observation.status && (observation.contained && observation.contained.length > 0) ? observation.status : OBSERVATION_STATUS_DEFAULT);
    if (observation.contained) {
      observation.contained.forEach(mediaResource => {
        const audio = window.URL.createObjectURL(b64ToBlob(mediaResource.content.data));
        mediaArray.push({ key: mediaResource.id, duration: mediaResource.duration, persisted: true, createdAt: new Date(mediaResource.createdDateTime).getTime(), audio });
      });
      setRecordings(sortRecordings(mediaArray));
    } else if(observation?.value?.contained){
      observation.value.contained.forEach(mediaResource => {
        const audio = window.URL.createObjectURL(b64ToBlob(mediaResource.content.data));
        mediaArray.push({ key: mediaResource.id, duration: mediaResource.duration, persisted: true, createdAt: new Date(mediaResource.createdDateTime).getTime(), audio });
      });
      setRecordings(sortRecordings(mediaArray));
    }
  },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [observation]);

  useEffect(() => {
    if (audio) {
      if (recordings.length === maxRecordings - 2) {
        notification.warning({ title: "Aviso", caption: "Solo podrá registrar un audio más." });
      }
      if (recordings.length <= maxRecordings - 1) {
        const record = { key: GenerateUuid(), duration: duration, persisted: false, final: false, createdAt: new Date().getTime(), audio };
        setRecordings((prevState) => {
          return sortRecordings([...prevState, record]);
        });
        createMediaResource(record).then(mediaResource => {
          const obvData = {
            status: OBSERVATION_STATUS_DEFAULT,
            value: [{ derivedFrom: mediaResource }]
          };
          const obv = updateObservation(observationDefinition, observation, carePlan, patient, practitionerRole, obvData);
          handleChangeObservation(obv);
        });
      }
    }
  },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [audio, duration, maxRecordings]);

  function deleteAudio(audioKey) {
    setRecordings((prevState) => sortRecordings(prevState.filter((record) => record.key !== audioKey)));
    if (observation.contained && observation.contained.length > 0) {
      const contained = observation.contained.filter(media => media.id !== audioKey);
      observation.contained = contained;
      const derivedFrom = observation.derivedFrom.filter(derivedFrom => derivedFrom.reference !== '#' + audioKey);
      observation.derivedFrom = derivedFrom;
      observation.status = OBSERVATION_STATUS_DEFAULT;
      handleChangeObservation({ ...observation });
    }
  }

  const handleVerified = (checked) => {
    let status = OBSERVATION_STATUS_DEFAULT;
    if (checked) {
      status = OBSERVATION_STATUS_FINAL;
    } else if (!checked && (observation && !observation.id.startsWith('temp-'))) {
      status = OBSERVATION_STATUS_PRELIMINARY;
    }
    onStatusChange(OBSERVATION_STATUS_DEFAULT);
    observation.status = status;
    handleChangeObservation(observation);
  };

  return (
    <>
      <div className="recordings-container">
        {recordings.length > 0 ? (
          <div className="recordings-list">
            {recordings.map((record) => (
              <div className="record d-flex" key={record.key}>
                <audio controls src={record.audio} />
                <div className="delete-button-container">
                  <Button kind="ghost" hasIconOnly renderIcon={TrashCan} iconDescription="Borrar grabación"
                    className="delete-button" title="Borrar grabación"
                    onClick={() => deleteAudio(record.key, setRecordings)} disabled={readOnly}/>
                </div>
              </div>
            ))}
            <Toggle labelText="Marque para indicar que esta medición es correcta." labelA="No verificado" labelB="Verificado"
              id={`${observationDefinition.code.coding[0].code}:${occurrence}:${observationMoment}:toggle`}
              onToggle={handleVerified}
              toggled={observation ? observation.status === OBSERVATION_STATUS_FINAL : false} disabled={readOnly}/>
          </div>
        ) : (
          <div className="d-flex center-vertically center-horizontally">
            <span>No hay grabaciones</span>
          </div>
        )}
      </div>
    </>
  );
}

function createMediaResource(record) {
  return new Promise(resolve => {
    const uuid = generateUuid();
    const media = {
      resourceType: "Media",
      id: "",
      status: "completed",
      type: {
        coding: [
          {
            system: "http://terminology.hl7.org/CodeSystem/media-type",
            code: "audio",
            display: "Audio"
          }
        ]
      },
      createdDateTime: "",
      duration: 0,
      content: {
        contentType: "audio/wav",
        data: ""
      }
    };
    media.id = `${uuid}`;
    media.duration = record.duration / 1000;
    media.createdDateTime = `${toISOString(new Date(record.createdAt))}`;
    getBlobFromUrl(record.audio).then(function (response) {
      var reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = function () {
        media.content.data = reader.result.split(',')[1];
        resolve(media);
      };
    });
  });
}

function sortRecordings(recordings) {
  recordings.sort((a, b) => a.createdAt - b.createdAt);
  return recordings;
}