import React, { useState, useEffect, useCallback } from "react";

import debounce from "lodash.debounce";

import generateUuid from "common/GenerateUuid";
import { SYSTEM_OBSERVATION_OCCURRENCE } from "api/constants/tags/ObservationTags";

import { createObservation } from "components/Observation/ObservationService";

import { getFirstTag } from "api/constants/tags/TagFunctions";
import ObservationComponentFactory, {
  NO_OCCURRENCE_SPECIFIC_COMPONENT_METHOD_CODES,
} from "components/Observation/ObservationComponentFactory";
import NewSingleObservationComponent from "components/Observation/NewSingleObservationComponent";

import "components/Observation/_GenericObservationsComponent.scss";
import SkeletonObservationComponent from "./SkeletonObservationComponent";

import useNotification from "hooks/useNotification";

/**
 * Componente que organiza todas las ocurrencias de una medición. Se encarga de renderizar componentes para
 * visualizar y modificar las ocurrencias de una Observation.
 *
 * Este componente no modifica datos internamente, simplemente se encarga de enviar todas las observaciones
 * de cada ocurrencia al mismo componente.
 *
 * @param {Object} observationDefinition ObservationDefinition FHIR con la descripción de la variable a tomar.
 * @param {Array}  observations lista de observaciones a mostrar en este componente.
 * @param {Object} carePlan carePlan del que procede la ServiceRequest en la que se recoge a la Observation.
 * @param {Object} serviceRequest ServiceRequest que contiene los datos de la cita.
 * @param {Object} encounter Encounter que contiene los datos de la ejecución de la cita.
 * @param {Object} patient
 * @param {Object} practitionerRole
 * @param {func}   handleChangeObservations función que traslada los datos modificados al componente superior.
 *
 */
export default function GenericObservationsComponent({
  observationDefinition,
  observations: inputObservations = null,
  carePlan,
  serviceRequest,
  encounter,
  patient,
  practitionerRole,
  handleChangeObservations = () => {},
  ...rest
}) {
  const DEBOUNCE_TIMEOUT = 1000;

  const [observations, setObservations] = useState();

  const notification = useNotification();

  const createNewObservation = (occurrence) => {
    let newObservation = createObservation(
      observationDefinition,
      occurrence,
      carePlan,
      serviceRequest,
      encounter,
      patient,
      practitionerRole
    );
    newObservation.id = `temp-${generateUuid()}`;
    return newObservation;
  };

  const orderObservations = (unorderedObservations) => {
    return unorderedObservations.sort((a, b) => 
      parseInt(getFirstTag(a.meta, SYSTEM_OBSERVATION_OCCURRENCE) || 0) -
      parseInt(getFirstTag(b.meta, SYSTEM_OBSERVATION_OCCURRENCE) || 0)
    );
  };

  const debouncedObservationsSet = useCallback(
    debounce(nextValue => setObservations(nextValue), DEBOUNCE_TIMEOUT),
    []
  );

  /** clean up */
  useEffect(() => {
    return () => {
      debouncedObservationsSet.cancel();
    };
  }, []);

  useEffect(() => {
    if (inputObservations && inputObservations.length) {
      debouncedObservationsSet.cancel();
      setObservations(orderObservations(inputObservations));
    } else {
      debouncedObservationsSet([createNewObservation(1)]);
    }
  }, [inputObservations]);

  const handleAddObservationOccurrence = (event) => {
    const newObservation = createNewObservation(observations.length + 1);
    setObservations(currentList => [...currentList, newObservation]);
  };

  const handleNotSupported = () => {
    notification.warning({
      title: "Operación no soportada",
      caption: "No se permite la generación de más mediciones de este tipo durante esta fase de la prueba",
    });
  };

  /**
   * Actualiza la lista de Observations del componente superior agregando
   * o actualizando la Observation proporcionada por el componente hijo.
   *
   * @param {Object} updatedObservation
   */
  const handleChangeSingleObservation = (updatedObservation) => {
    if (updatedObservation) {
      const listOfUpdatedObservations = observations.filter(item => item.id !== updatedObservation.id);
      listOfUpdatedObservations.push(updatedObservation);
      handleChangeObservations(listOfUpdatedObservations);
    }
  };

  return (
    <div className="coperia--observation-content">
      {
      observations ? (
        observations.length > 0 ? (
          observations.map((observation, index) => {
            return (
              <ObservationComponentFactory
                key={`${observationDefinition.id}:${index}`}
                observationDefinition={observationDefinition}
                observation={observation}
                occurrence={index}
                carePlan={carePlan}
                serviceRequest={serviceRequest}
                encounter={encounter}
                patient={patient}
                practitionerRole={practitionerRole}
                handleChangeObservation={handleChangeSingleObservation}
                handleAddObservationOccurrence={handleAddObservationOccurrence}
                {...rest}
              />
            );
          })
        ) : (
          <ObservationComponentFactory
            key={`${observationDefinition}:1`}
            observationDefinition={observationDefinition}
            observation={null}
            occurrence={1}
            carePlan={carePlan}
            serviceRequest={serviceRequest}
            encounter={encounter}
            patient={patient}
            practitionerRole={practitionerRole}
            handleChangeObservation={handleChangeSingleObservation}
            handleAddObservationOccurrence={handleAddObservationOccurrence}
            {...rest}
          />
        )
      ) : (
        <SkeletonObservationComponent />
      )}
      {
        !NO_OCCURRENCE_SPECIFIC_COMPONENT_METHOD_CODES.includes(observationDefinition.method.coding[0].code) &&
        observations?.length ? 
          <NewSingleObservationComponent
            observationDefinition={observationDefinition}
            handleAddObservationOccurrence={handleAddObservationOccurrence}
            {...rest}
          />
          : 
          <NewSingleObservationComponent
            observationDefinition={observationDefinition}
            handleAddObservationOccurrence={handleNotSupported}
            {...rest}
          />
        }
    </div>
  );
}
