import { ClickableTile } from "@carbon/react";
import { dateTimeToLocalDate, dateTimeToLocalTimeWithoutSeconds } from 'common/DateTimeUtils';
import 'layout/notification/_notification.scss';
import { Warning_01 } from '@carbon/pictograms-react';
import { useState } from "react";
import CommunicationComponent from "components/Communication/CommunicationComponent";
import { useAuth } from "auth/Auth";

export default function NotificationCommunicationLauncher({ notification, onClose }) {
  const [openNotification, setOpenNotification] = useState(false);
  const auth = useAuth();

  return (<>
    {
      openNotification &&
      <CommunicationComponent
        communication={notification}
        practitionerRole={auth.practitionerRole}
        onClose={() => {
          onClose();
          setOpenNotification(false);
        }}>

      </CommunicationComponent>
    }
    {
      <div className="notification-component">
        <ClickableTile onClick={() => setOpenNotification(true)}>
          <div className="notification-info">
            <div className="notification-row">
              <div className="notification-icon">
                <Warning_01 key={'notification-icon'} className="alert" aria-label="Episodio de malestar" />
              </div>
              <div className="notification-item">
                Episodio de malestar
              </div>
            </div>
            <div className="notification-row">
              <div className="notification-item">
                {notification.subject.display}
              </div>
              <div className="notification-item">
                {dateTimeToLocalDate(notification.sent)}
              </div>
              <div className="notification-item">
                {dateTimeToLocalTimeWithoutSeconds(notification.sent)}
              </div>
            </div>
          </div>
        </ClickableTile>
      </div>
    }
  </>
  );
}