import React from 'react';
import { Information } from '@carbon/react/icons';

import { DatePicker, DatePickerInput, Select, SelectItem, TextArea, TextInput, TimePicker, Tooltip, MultiSelect } from '@carbon/react';

export function Item({ props }) {
  const { id, type, label, item, value, values, repeats, onChange, generateId, unit, readOnly } = props;
  switch (type) {
    case 'group':
      return (
        <Group title={label}>
          {
            item.item && item.item.map(i => {
              const linkId = generateId(i.linkId);
              const props = {
                id: linkId,
                type: i.type,
                label: i.text,
                item: i,
                value: values[linkId] || '',
                values: values,
                onChange: onChange,
                generateId: generateId,
                unit: unit
              };
              return <Item key={i.linkId} props={props} />;
            })
          }
        </Group>
      );
    case 'display':
      return <Display item={item} />;
    case 'boolean':
      return <Boolean id={id} label={label} value={value} item={item} onChange={onChange} readOnly={readOnly} />;
    case 'choice':
      return <Choice id={id} label={label} value={value} item={item} repeats={repeats} onChange={onChange} options={item.answerOption} readOnly={readOnly} />;
    case 'dateTime':
    case 'date':
      return <DateField id={id} label={label} value={value} item={item} onChange={onChange} readOnly={readOnly} />;
    case 'integer':
      return <Integer id={id} label={label} value={value} item={item} onChange={onChange} readOnly={readOnly} />;
    case 'decimal':
      return <Decimal id={id} label={label} value={value} item={item} onChange={onChange} readOnly={readOnly} />;
    case 'open-choice':
      return <OpenChoice id={id} label={label} value={value} item={item} onChange={onChange} readOnly={readOnly} />;
    case 'string':
      return <String id={id} label={label} value={value} item={item} onChange={onChange} readOnly={readOnly} />;
    case 'SampledData':
    case 'text':
      return <Text id={id} label={label} value={value} item={item} onChange={onChange} readOnly={readOnly} />;
    case 'time':
      return <Time id={id} label={label} value={value} item={item} onChange={onChange} readOnly={readOnly} />;
    case 'Quantity':
    case 'quantity':
      return <Quantity id={id} label={label} value={value} item={item} unit={unit} onChange={onChange} readOnly={readOnly} />;

    default:
      return <div>Item not recognized ({type})</div>;
  }
}

export function Group({ title, children }) {
  return (
    <div className="group">
      <div className="group--title">{title}</div>
      <div className="group--items">
        {children}
      </div>
    </div>
  );
}

export function Display({ item }) {
  return (
    <div className='item item-display'>
      <span className="item-display--label">{item.text}</span>
      {item.item && item.item.map((i, index) => <span key={index}>{i.text}</span>)}
    </div>
  );
}

export function Field({ label, className, children, item = '' }) {
  let additionalInfo = '';
  item.item && item.item.forEach(i => {
    i.extension && i.extension.forEach(e => {
      if (e.valueCodeableConcept.coding[0].code === "help") {
        additionalInfo = i.text;
      }
    });
  });

  if (additionalInfo) {
    return (
      <div className={className}>
        <label className="form-label">
          {label}
          {additionalInfo &&
            <Tooltip label={additionalInfo} align={"right"} >
              <button style={styles}>
                <Information />
              </button>
            </Tooltip>
          }

        </label>
        {children}
      </div>
    );
  }

  return (
    <div className={className}>
      <label className="form-label">
        {label}
      </label>
      {children}
    </div>
  );
}

export function Boolean({ label, id, value, item, onChange, readOnly }) {
  return (
    <Field className="item item-boolean" item={item} label={label}>
      <input type="checkbox" id={id} checked={value === true} onChange={onChange} readOnly={readOnly} />
    </Field>
  );
}

export function Choice({ label, id, value, repeats, onChange, options, readOnly }) {
  let code;
  if (value) {
    code = value.code;
  }
  if (repeats) {
    let selectedItems = [];
    options.forEach(o => {
      if (Array.isArray(value)) {
        value.forEach(v => {
          if (o.valueCoding.display === v.code) {
            selectedItems.push(o);
          }
        });

      } else if (o.valueCoding.display === value?.code) {
        selectedItems.push(o);
      }
    });
    return (
      <Field className="item item-choice" label={label}>
        {
          readOnly ?
            <MultiSelect
              id={id}
              items={options}
              label={label}
              selectedItems={selectedItems}
              itemToString={(option) => (option ? option.valueCoding.display : '')}
            />
            :
            <MultiSelect
              id={id}
              items={options}
              label={label}
              onChange={e => {
                const event = { target: { value: e.selectedItems, id: id } };
                onChange(event);
              }}
              initialSelectedItems={selectedItems}
              itemToString={(option) => (option ? option.valueCoding.display : '')}
            />
        }
      </Field>
    );
  }
  return (
    <Field className="item item-choice" label={label}>
      {readOnly ?
      <Select id={id} value={code} readOnly={readOnly} noLabel>
          {
            options && options.filter(option=>option.valueCoding.display === code).map((option, index) => {
              return <SelectItem key={`${id}-item-${option.valueCoding.code || index}`} value={option.valueCoding.display} text={option.valueCoding.display || option.valueCoding.code} />;
            })
          }
        </Select>
        :
        <Select id={id} value={code} onChange={onChange} noLabel>
          <SelectItem />
          {
            options && options.map((option, index) => {
              return <SelectItem key={`${id}-item-${option.valueCoding.code || index}`} value={option.valueCoding.display} text={option.valueCoding.display || option.valueCoding.code} />;
            })
          }
        </Select>}
    </Field>
  );
}

export function Integer({ label, id, value, item, onChange, readOnly }) {
  return (
    <Field className="item item-integer" label={label} item={item}>
      <TextInput labelText="" type="number" id={id} value={value} onChange={onChange} readOnly={readOnly} />
    </Field>
  );
}

export function Decimal({ label, id, value, item, onChange, readOnly }) {
  return (
    <Field className="item item-decimal" label={label} item={item}>
      <TextInput labelText="" type="number" id={id} value={value} onChange={onChange} readOnly={readOnly} />
    </Field>
  );
}

export function Quantity({ label, id, value, item, unit, onChange, readOnly }) {
  return (
    <Field className="item item-quantity" label={label} item={item}>
      <TextInput labelText="" type="number" id={id} value={value} onChange={onChange} readOnly={readOnly} /><span className='coperia-unit'>{unit || ''}</span>
    </Field>
  );
}

export function Text({ label, id, value, item, onChange, readOnly }) {
  return (
    <Field className="item item-text" label={label} item={item}>
      {readOnly ?
        <TextArea labelText="" id={id} value={""} />
        :
        <TextArea labelText="" id={id} value={value} onChange={onChange} />
      }
    </Field>
  );
}

export function String({ label, id, value, item, onChange, readOnly }) {
  return (
    <Field className="item item-string" label={label} item={item}>
      <TextInput labelText="" type="text" id={id} value={value} onChange={onChange} readOnly={readOnly} />
    </Field>
  );
}

export function OpenChoice({ label, id, value, item, onChange, readOnly }) {
  return (
    <Field className="item item-open-choice" label={label} item={item}>
      <TextInput labelText="" type="text" id={id} value={value} onChange={onChange} readOnly={readOnly} />
    </Field>
  );
}

export function DateField({ label, id, value, item, onChange, readOnly }) {
  return (
    <Field className="item item-date" item={item} label={label}>
      {readOnly ?
        <TextInput labelText="" type="text" id={id} value={value} readOnly={readOnly} />
        :
        <DatePicker
          locale={'es'}
          datePickerType="single"
          dateFormat="d/m/Y"
          id={id}
          value={value}
          onChange={(date, value) => {
            onChange({ target: { id, value } });
          }}
        >
          <DatePickerInput labelText="" id={`${id}-input`} placeholder="dd/mm/yyyy" autoComplete="off" />
        </DatePicker>
      }
    </Field>
  );
}

export function Time({ label, id, value, item, onChange, readOnly }) {
  return (
    <Field className="item item-time" item={item} label={label}>
      {readOnly ?
        <TextInput labelText="" type="text" id={id} value={value} readOnly={readOnly} />
        :
        <TimePicker id={id} value={value} onChange={onChange} />
      }
    </Field>
  );
}


const styles = {
  border: "none",
  background: "none"
};