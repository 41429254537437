// Funcionalidades para gestión de recurso CarePlan
import http from 'common/HttpService';
import { SYSTEM_ASSAY, SYSTEM_ASSAY_PHASE } from "api/constants/tags/AssayTags";

const CarePlan = {
  findByIdentifier: async (identifier) => {
    const res = await http.get(`/CarePlan/${identifier}`);
    const { entry = [] } = res.data;
    return entry[0].resource;
  },
  getCarePlansByPatientIdentifier: async (patientIdentifier) => {
    // TODO: refactorizar
    const res = await http.get(`/CarePlan?patient=${patientIdentifier}&status=active`);
    const { entry = [] } = res.data;
    return entry;
  },
  getCarePlansByPatientIdentifierAndAssays: async (patientIdentifier, assays) => {
    // TODO: refactorizar
    let queryPatient = `/CarePlan?patient=${patientIdentifier}&status=active`;
    let queryAssays = queryPatient;
    if (assays.length) {
      queryPatient += '&_tag=';
      queryAssays = assays.reduce(
        (previousValue, currentValue) => previousValue + SYSTEM_ASSAY + '|' + currentValue + ',',
        queryPatient
      );
    }
    const res = await http.get(queryAssays);
    const { entry = [] } = res.data;
    return entry;
  },
  getCarePlansByPatientIdentifierAndAssaysAndAssayPhases: async (patientIdentifier, assays, assayPhases) => {
    // TODO: refactorizar
    let queryPatient = `/CarePlan?patient=${patientIdentifier}&status=active`;
    let queryAssays = queryPatient;
    if (assays.length) {
      queryPatient += '&_tag=';
      queryAssays = assays.reduce(
        (previousValue, currentValue) => previousValue + `${SYSTEM_ASSAY}|` + currentValue + ',',
        queryPatient
      );
    }

    let queryAssayPhases = queryAssays;
    if (assayPhases.length) {
      queryAssays += '&_tag=';
      queryAssayPhases = assayPhases.reduce(
        (previousValue, currentValue) => previousValue + `${SYSTEM_ASSAY_PHASE}|` + currentValue + ',',
        queryAssays
      );
    }

    const res = await http.get(queryAssayPhases);
    const { entry = [] } = res.data;
    return entry;
  },
  getCarePlansByPatientByFilters: async (filters) => {
    const res = await http.get(`/CarePlan` + filters);
    const { entry = [] } = res.data;
    return entry;
  },
  updatePatch: (dataPatch) => {
    const headers = { headers: { 'Prefer': 'return=representation', 'Content-Type': 'application/json-patch+json' } };
    return http.patch(`/CarePlan/${dataPatch.id}`, dataPatch.patch, headers);
  }
};

export default CarePlan;