export const NURSE_CODE = "106292003";
export const PHYSIOTHERAPIST_CODE = "36682004";
export const DOCTOR_CODE = "112247003";
export const ICT_PROFESSIONAL_CODE = "ict";

export const SYSTEM_SNOMED = "http://snomed.info/sct";
export const SYSTEM_HL7 = "http://terminology.hl7.org/CodeSystem/practitioner-role";

export const PRACTITIONER_ROLE_MAP = new Map([
  [NURSE_CODE, { system: SYSTEM_SNOMED, code: NURSE_CODE, display: "Enfermero/a" }],
  [PHYSIOTHERAPIST_CODE, { system: SYSTEM_SNOMED, code: PHYSIOTHERAPIST_CODE, display: "Fisioterapeuta" }],
  [DOCTOR_CODE, { system: SYSTEM_SNOMED, code: DOCTOR_CODE, display: "Doctor/a" }],
  [ICT_PROFESSIONAL_CODE, { system: SYSTEM_HL7, code: ICT_PROFESSIONAL_CODE, display: "Profesional TIC" }]
]);