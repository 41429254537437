export default class Authentication {

    constructor(user, roles, accessToken) {
      this.user = user;
      this.roles = roles || [];
      this.accessToken = accessToken;
    }
  
    hasRole(role) {
      if (!role) {
        return false;
      }
  
      return this.roles.find(r => r === role)
    }
  
    hasAnyRole(roles){
      if(!roles){
        return false;
      }
  
      try {
        return roles.find( r=> this.hasRole(r) )
      } catch (error) {
        return false;
      } 
    }
  
    toHeader(){
      if(!this.accessToken){
        return '';
      }
  
      return `Bearer ${this.accessToken}`;
    }
  
  }