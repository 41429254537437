import { SkeletonText, SkeletonIcon } from "@carbon/react";

import  RecorderTitle from 'components/RecorderTitle';

export default function SkeletonObservationComponent() {

return (
  <div className="recorder coperia--observation-item">
    <RecorderTitle> 
      <SkeletonIcon /> 
    </RecorderTitle>
    <div className='coperia--observation-item-row'>
      <div className="item">
        <SkeletonText />
      </div>
    </div>
    <div className='coperia--observation-item-row'>
      <SkeletonIcon/>
    </div>
    <div className='coperia--observation-item-row'>
      <SkeletonText />
    </div>
    <div className='coperia--observation-item-row'>
      <SkeletonText />
    </div>
    <div className='coperia--observation-item-row'>
      <SkeletonText />
    </div>
    <div className='coperia--observation-item-row'>
      <SkeletonIcon />
    </div>
    <div className='coperia--observation-item-row'>
      <SkeletonIcon />
    </div>
  </div>
  );
}

