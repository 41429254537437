import React from 'react';
import ActivityDetailComponent from './ActivityDetailComponent';

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export default function ActivityDetailsComponent({ activity, patient, carePlan, practitionerRole, handleDeleteActivity }) {
  return (
    <>
      {
        activity?.outcomes &&
        <div>
          <ul>
            {
              activity.outcomes.map((item) => {
                if (activity.type === 'Communication') {
                  return <li className='activity-outcome-row' key={`launcher:${activity.reference}:${item.reference}`}>{item.display}</li>;
                } else {
                  return <ActivityDetailComponent key={`launcher:${item?.reference}`} id={`${item?.reference}-activity-info`}
                  activity={activity}
                  patient={patient}
                  carePlan={carePlan}
                  practitionerRole={practitionerRole}
                  outcome={item}
                  handleDeleteActivity={handleDeleteActivity}
                />;
                }
              })
            }
          </ul>
        </div>
      }
    </>
  );
}