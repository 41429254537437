import { useReducer, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import useNotification from 'hooks/useNotification';
import BluetoothApi from 'api/bluetooth/BluetoothApi';
import bluetoothReducer, { REDUCER_ACTIONS } from 'hooks/bluetooth/BluetoothReducer';

const BLUETOOTH_INITIAL_STATE = {
  loading: false,
  device: null,
  deviceName: '',
  characteristic: null,
  startTime: null,
  stopTime: null,
  dataToShow: '',
  data: new Map(),
};

useBluetooth.propTypes = {
  btConnectionParams: PropTypes.object,
  started: PropTypes.bool,
  dataParser: PropTypes.func,
};

/**
 * Hook que gestiona la conexión con dispositivos y admite la suscripción a notificaciones que emiten los dispositivos.
 * Bajo el bluetoothState se gestiona el dispositivo y el nombre, el dato que se mostrará al usuario, por ejemplo el pulso,
 * las características del dispositivo que nos permitirán suscribirnos a las notificaciones y un objeto compuesto por un 
 * mapa en donde se almacenarán los datos notificados para enviar a fhir, por ejemplo los datos de la variabilidad. Se usa 
 * un mapa de arrays y no un array porque en determinados componentes es necesario recoger dos tipos de datos, por ejemplo,
 * en HrvRecorder se puede requerir almacenar los distintos valores del pulso y la variabilidad cardíaca por lo que el mapa
 * tendría el siguiente aspecto:
 *  [
 *    ['pulso', [61,61,62,65,67,70,...]]
 *    ['variabilidad', [891,765,950,850,...]]
 *  ]
 * 
 * @param {object} btConnectionParams - datos de conexión al dispositivo (nombre del servicio que proporciona el bluetooth 
 * para filtrar la lista de dispositivos, nombre del servicio del dispositivo al que nos queremos conectar y nombre de la 
 * característica a la que nos vamos a conectar )
 * @param {bool} started - Flag que indica si el usuario ha echo click en comenzar la prueba. En base a esta variable nos 
 * suscribimos a las notificaciones o no
 * @param {func} dataParser - Función que nos permite pasear la información obtenida por el dispositivo. Deberá devolver un 
 * objeto con los campos dataToShow y data
 * @returns Objecto con el estado gestionado por este hook y la función connect que nos permitirá conectar y desconectar un 
 * dispositivo.
 */
useBluetooth.propTypes = {
  btConnectionParams: PropTypes.object,
  started: PropTypes.bool,
  dataParser: PropTypes.func,
};

export default function useBluetooth(btConnectionParams, started, dataParser) {
  const [bluetoothState, dispatch] = useReducer(bluetoothReducer, BLUETOOTH_INITIAL_STATE);
  const notification = useNotification();

  useEffect(() => {
    return () => {
      if (bluetoothState.device?.gatt.connected) {
        console.log(`Se desmonta componente y se desconecta el dispositivo: ${bluetoothState.device.name}`);
        BluetoothApi.disconnectDevice(bluetoothState.device);
      }
    };
  }, [bluetoothState.device]);

  const onNotifyData = useCallback((event) => {
    if (started) {
      const characteristic = event.target;
      const payload = dataParser(characteristic.value);
      dispatch({ type: REDUCER_ACTIONS.ADD_DATA, payload: payload });
    }
  }, [started, dataParser, dispatch]);

  useEffect(() => {
    if (started) {
      if (bluetoothState.characteristic) {
        bluetoothState.characteristic.addEventListener('characteristicvaluechanged', onNotifyData);
        dispatch({ type: REDUCER_ACTIONS.START_NOTIFICATIONS });
      }
    } else {
      if (bluetoothState.characteristic) {
        dispatch({ type: REDUCER_ACTIONS.STOP_NOTIFICATIONS });
      }
    }
    return () => {
      if (bluetoothState.characteristic) {
        bluetoothState.characteristic.removeEventListener('characteristicvaluechanged', onNotifyData);
      }
    };
  }, [started, bluetoothState.device, bluetoothState.characteristic, dispatch, onNotifyData]);


  function connect() {
    if (bluetoothState.device?.gatt.connected) {
      dispatch({ type: REDUCER_ACTIONS.DISCONNECT, payload: { manual: true } });
    } else {
      if (navigator.bluetooth) {
        BluetoothApi.getDevice(btConnectionParams.serviceName).then(device => {
          dispatch({ type: REDUCER_ACTIONS.PAIR, payload: { loading: true } });
          BluetoothApi.connectServer(device).then(server => {
            console.log('Bluetooth.server');
            console.log(server);
            BluetoothApi.getCharacteristic(server, btConnectionParams.primaryServiceName, btConnectionParams.characteristicName)
              .then(characteristic => {
                device.addEventListener('gattserverdisconnected', onDeviceDisconnected);
                dispatch({ type: REDUCER_ACTIONS.CONNECT, payload: { device: device, characteristic: characteristic, loading: false } });
              }).catch(e => {
                console.error(e);
                onErrorConnectingDevice();
              });
          }).catch(e => {
          console.error(e);
          onErrorConnectingDevice();
        });
        }).catch(e => {
          console.error(e);
          onErrorConnectingDevice();
        });
      } else {
        notification.error({ title: "Error", caption: "El bluetooth no se encuentra activo en este navegador." });
      }
    }
  };

  const onErrorConnectingDevice = () => {
    notification.error({ title: "Error iniciando Dispositivo", caption: "No se ha podido iniciar el dispositivo Bluetooth." });
    dispatch({ type: REDUCER_ACTIONS.DISCONNECT, payload: { manual: false } });
  };

  const onDeviceDisconnected = (event) => {
    //Este evento se lanza tantas veces como conexiones/desconexiones se realizasen. Es como si nunca se acumulasen los eventos.
    //Para evitar este comportamiento se ejecuta stopImmediatePropagation que evita que otros listeners del mismo tipo se llamen
    event.stopImmediatePropagation();
    notification.error({ title: "Dispositivo desconectado", caption: `El dispositivo ${event.target.name} está desconectado` });
    dispatch({ type: REDUCER_ACTIONS.DISCONNECT, payload: { manual: false } });
  };

  return {
    bluetoothState,
    connect
  };
}
