import { ProgressIndicator, ProgressStep } from "@carbon/react";
import { CODE_OBSERVATION_MOMENT_BEFORE, CODE_OBSERVATION_MOMENT_DURING, CODE_OBSERVATION_MOMENT_AFTER } from 'api/constants/tags/ObservationTags';
import { useEffect, useState } from "react";


export default function ServiceRequestMomentViewer({ observationMoment, observationMoments, onChange = () => { } }) {

  const [existingMoments, setExistingMoments] = useState([]);

  useEffect(() => {
    const moments = [];
    if (observationMoments.includes(CODE_OBSERVATION_MOMENT_BEFORE)) {
      moments.push({
        label: "Recogida de datos antes de la prueba",
        code: CODE_OBSERVATION_MOMENT_BEFORE
      });
    }

    if (observationMoments.includes(CODE_OBSERVATION_MOMENT_DURING)) {
      moments.push({
        label: "Recogida de datos durante la prueba",
        code: CODE_OBSERVATION_MOMENT_DURING
      });
    }

    if (observationMoments.includes(CODE_OBSERVATION_MOMENT_AFTER)) {
      moments.push({
        label: "Recogida de datos después de la prueba",
        code: CODE_OBSERVATION_MOMENT_AFTER
      });
    }
    setExistingMoments(moments);
  }, []);

  return (
    <>
      {existingMoments && <ProgressIndicator className="servicerequest-component-progress-bar" onChange={e => onChange(observationMoments[e]) } spaceEqually currentIndex={observationMoments.indexOf(observationMoment)}>
        {
          existingMoments.map(moment =>
            <ProgressStep key={moment.code} label={moment.label}></ProgressStep>
          )
        }
      </ProgressIndicator>}
    </>
  );
}