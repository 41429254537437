import React, { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, DataTableSkeleton, Pagination } from '@carbon/react';
import { Route, Routes, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import PatientsTable from 'patients/PatientsTable';
import EnrolPatientForm from 'patients/EnrolPatientForm';
import PatientDetails from 'patients/PatientDetails';
import Patient from 'api/Patient';

import { getTagCodesBySystem } from 'api/constants/tags/TagFunctions';
import { SYSTEM_ASSAY } from 'api/constants/tags/AssayTags';
import AuthProvider from 'auth/AuthProvider';
import PractitionerRole from 'api/PractitionerRole';
import { DEFAULT_SORTING } from './PatientSortingFields';


const headers = [
  {
    key: 'identifier',
    header: 'Identificador'
  },
  {
    key: 'alias',
    header: 'Alias'
  },
  {
    key: 'birthDate',
    header: 'Año de nacimiento'
  },
  {
    key: 'gender',
    header: 'Género'
  },
  {
    key: 'assays',
    header: 'Ensayos'
  }
];

export default function Patients() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(25);
  const [searchValues, setSearchValues] = useState({ term: searchParams.get('q') || '' });

  const provider = new AuthProvider();

  const getAssaysFromPractitioner = async () => {
    const practitionerRole = await PractitionerRole.getPractitionerRole(provider.getPractitionerRoleId());
    let assaysFromPractitionerOrNone = [];
    if (practitionerRole?.meta) {
      assaysFromPractitionerOrNone = getTagCodesBySystem(practitionerRole.meta, [SYSTEM_ASSAY]);
    }
    return assaysFromPractitionerOrNone;
  };


  const search = async () => {
    try {
      let practitionerAssays = await getAssaysFromPractitioner();
      let practitionerAssaysIDs = practitionerAssays.map(item => { return { id: item }; });

      if (!searchValues?.assays?.length) {
        searchValues.assays = practitionerAssaysIDs;
      }

      if (!searchValues?.sort) {
        searchValues.sort = DEFAULT_SORTING;
      }

      const data = await Patient.getPatients({ page: currentPage, pageSize: currentPageSize, searchValues });
      const entries = data.entry || [];
      const total = data.total || entries.length;
      // revisamos si el número total de registros puede verse en la página actual
      if (total < ((currentPage - 1) * currentPageSize) + 1) {
        setCurrentPage(1);
      }

      // Adaptar datos a estructura plana de tabla
      const rows = entries.map(entry => {
        const resource = entry.resource;

        // id es el id de recurso generado por server
        // identifier es el identificador de negocio
        return {
          id: resource.id,
          identifier: Patient.getIdentifier(resource),
          name: Patient.getName(resource),
          gender: Patient.getGender(resource),
          alias: Patient.getAlias(resource),
          birthDate: Patient.getBirthDate(resource),
          assays: Patient.getAssays(resource)
        };
      });
      setRows(rows);
      setTotalItems(total);
    } catch (e) {
      console.log(e);
    }
  };

  // Primera carga de la página. Mostramos skeleton
  useEffect(() => {
    setLoading(true);
    search().finally(() => {
      setLoading(false);
    });
  }, []);

  // Con cualquier cambio de página, tamaño de página o filtro actualizamos
  // TODO Incluir ordenación
  useEffect(() => {
    setLoading(true);
    search().finally(() => {
      setLoading(false);
    });
  }, [searchValues, currentPage, currentPageSize]);

  const onAdd = () => {
    navigate('/patients/enrol-patient');
  };

  const onEdit = (id) => {
    navigate(`/patients/${id}`);
  };

  const onSearch = (values) => {
    setSearchValues(values);
    // Indicamos filtro en URL para crear URLs con significado.
    // Permite compartir la URL con un filtro aplicado o hacer un F5 manteniendo
    setSearchParams(values.term ? { q: values.term } : "");
  };

  return (
    <div className="patients">
      <div className="heading">
        <Breadcrumb noTrailingSlash>
          <BreadcrumbItem isCurrentPage><Link to="/patients">Pacientes</Link></BreadcrumbItem>
        </Breadcrumb>
      </div>

      {loading
        ?
        <DataTableSkeleton rowCount={currentPageSize} headers={headers} />
        :
        <>
          <PatientsTable
            headers={headers}
            rows={rows}
            onAdd={onAdd}
            onEdit={onEdit}
            onSearch={onSearch}
            defaultSearchValues={searchValues}
          />
          <Pagination
            backwardText="Página anterior"
            forwardText="Siguiente página"
            pageSizes={[5, 10, 15, 25]}
            itemsPerPageText="Filas por página"
            page={currentPage}
            totalItems={totalItems}
            pageSize={currentPageSize}
            onChange={({ page, pageSize }) => {
              if (pageSize !== currentPageSize) {
                setCurrentPageSize(pageSize);
              }
              setCurrentPage(page);
            }}
          />
        </>
      }

      {/* Nested routes */}
      <Routes>
        <Route path="enrol-patient" element={<EnrolPatientForm />} />
        <Route path=":id" element={<PatientDetails onDeactivate={search} />} />
      </Routes>
    </div>
  );
}
