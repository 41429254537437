import React from 'react';

import { Content, GlobalTheme } from '@carbon/react';
import { Route, Routes } from 'react-router';
import Navbar from 'layout/navbar';
import AuthProvider from 'auth/AuthProvider';
import Protected from 'auth/Protected';
import Patients from 'patients';
//import NotFound from 'not-found/NotFound';
import ContentSite from 'content-site/ContentSite';
import Analytics from 'analytics/Analytics';
import Home from 'home/Home';
import AppAdmin from 'appAdmin/AppAdmin';

import 'app.scss';

function App() {
  return (
    <GlobalTheme theme="white">
      <AuthProvider>
        <Protected>
          <Navbar />
          <Content>
            <Routes>
              <Route path="/inicio/*" element={<Home />} />
              <Route path="/patients/*" element={<Patients />} />
              <Route path="/analytics/*" element={<Analytics />} />
              <Route path="/docs/*" element={<ContentSite />} />
              <Route path="/admin" element={<AppAdmin />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </Content>
        </Protected>
      </AuthProvider>
    </GlobalTheme>
  );
}

export default App;
