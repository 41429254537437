// Funciones comunes para el uso del API FHIR
const TAG_PARAMETER = '&_tag';

/**
 * Genera un string con los parametros de búsqueda que permiten buscar en FHIR
 * las entidades que contengan los tags pasados por parámetro.
 * 
 * @param {Array} tags 
 * @param {String} [system] System del tag. Se recibe cuando el tag no lo posee.
 * @returns {String}
 */
export const buildQueryByTags = (tags, system) => {
  let query = '';
  if (tags?.length) {
    query += tags.reduce((prev, curr) => { 
      return prev + (prev ? ',' : '') + `${system ? system : curr.system}|${system ? curr : curr.code}`;
    }, '');
    query = `${TAG_PARAMETER}=${query}`;
  }
  return query;
};

/**
 * Recupera la lista de recursos a  partir de una promesa de consulta sobre FHIR.
 * @param {*} promise 
 * @returns {Promise} promesa que devolverá la lista de communicaciones
 */
export const getResourceList = (promise) => {
  return promise.then(data => { return data.map(item => item.resource) } )
  .catch((e) => {
    console.log(e);
    return [];
  });
};

/**
 * Recupera el número de recursos de una promesa de consulta sobre FHIR.
 * @param {*} promise 
 * @returns {Promise} promesa que devolverá la lista de communicaciones
 */
 export const getResourceCount = (promise) => {
  return promise.then(data => { return data.total })
  .catch((e) => {
    console.log(e);
    return [];
  });
};