import CarePlan from "api/CarePlan";
import { buildQueryByTags, getResourceList } from "api/ApiCommons";
import { SYSTEM_ASSAY_PHASE } from "api/constants/tags/AssayTags";
import { addDaysToDate, formatDate, isDateInRange } from "common/DateTimeUtils";

export function findCarePlanByPatientIdActivityDateAndAssayPhase(patientId, activityDate, assayPhases) {
  const nextDay = formatDate(addDaysToDate(activityDate, 1));
  const previousDay = formatDate(addDaysToDate(activityDate, -1));
  let queryCarePlan = `?patient=${patientId}&activity-date=${activityDate},${nextDay},${previousDay}&status=active`;
  if (assayPhases.length) {
    queryCarePlan = queryCarePlan + buildQueryByTags(assayPhases, SYSTEM_ASSAY_PHASE);
  }
  return getResourceList(CarePlan.getCarePlansByPatientByFilters(queryCarePlan));
}

export function updateActivityQuestionnaireCarePlan(carePlan, referenceId, outcomeReferenceId, activityDate) {
  const indexToUpdate = carePlan.activity.findIndex(a => a.detail && a.detail.instantiatesCanonical[0] === referenceId);
  const activityToUpdate = carePlan.activity.filter(a => a.detail && a.detail.instantiatesCanonical[0] === (referenceId))[0];

  if (outcomeReferenceId) {
    const fullOutcomeReference = activityToUpdate && activityToUpdate.outcomeReference ? activityToUpdate.outcomeReference : [];
    fullOutcomeReference.push({ reference: `QuestionnaireResponse/${outcomeReferenceId}` });
    activityToUpdate.outcomeReference = fullOutcomeReference;
    activityToUpdate.detail.status = 'completed';
  }

  if (activityDate) {
    activityToUpdate.detail.scheduledPeriod.start = activityDate;
  }

  return {
    id: carePlan.id,
    patch: [{
      op: "replace",
      path: `/activity/${indexToUpdate}`,
      value: activityToUpdate
    }]
  };
}

export function updateActivityServiceRequestCarePlan(carePlan, referenceId, outcomeReferenceId) {
  const indexToUpdate = carePlan.activity.findIndex(a => a.reference && a.reference.type === "ServiceRequest" && a.reference.reference === referenceId);
  const activityToUpdate = carePlan.activity.filter(a => a.reference && a.reference.type === "ServiceRequest" && a.reference.reference === referenceId)[0];
  const fullOutcomeReference = activityToUpdate && activityToUpdate.outcomeReference ? activityToUpdate.outcomeReference : [];
  fullOutcomeReference.push({ reference: `Encounter/${outcomeReferenceId}` });
  return {
    id: carePlan.id,
    patch: [{
      op: "add",
      path: `/activity/${indexToUpdate}/outcomeReference`,
      value: fullOutcomeReference
    }]
  };
}

export function deleteActivityCarePlan(carePlan, activityType, referenceId, outcome) {
  let activityIndex;
  let outcomeIndex;

  if (activityType === "ServiceRequest") {
    activityIndex = carePlan.activity.findIndex(a => a?.reference.reference === 'ServiceRequest/' + referenceId);
    outcomeIndex = carePlan.activity[activityIndex]?.outcomeReference.findIndex(a => a?.reference === outcome.reference);
  }

  if (activityType === "Questionnaire") {
    activityIndex = carePlan.activity.findIndex(a => a.detail && a.detail.instantiatesCanonical[0] === 'Questionnaire/' + referenceId);
    outcomeIndex = carePlan.activity[activityIndex]?.outcomeReference.findIndex(a => a?.reference === outcome.reference);
  }

  const dataPatch = {
    id: carePlan.id,
    patch: [{
      op: "remove",
      path: `/activity/${activityIndex}/outcomeReference/${outcomeIndex}`
    }]
  };

  return CarePlan.updatePatch(dataPatch);
}

export function addActivityQuestionnaireCarePlan(carePlan, referenceId, activityDate) {
  const indexToUpdate = carePlan.activity ? carePlan.activity.length : 0;
  return {
    id: carePlan.id,
    patch: [{
      op: "add",
      path: `/activity/${indexToUpdate}`,
      value: {
        detail: {
          kind: "Appointment",
          status: "scheduled",
          instantiatesCanonical: ["Questionnaire/" + referenceId],
          scheduledPeriod: {
            start: activityDate,
            end: activityDate
          }
        },
      }
    }]
  };
}

export function addActivityServiceRequestCarePlan(carePlan, serviceRequest) {
  const indexToUpdate = carePlan.activity ? carePlan.activity.length : 0;
  return {
    id: carePlan.id,
    patch: [{
      op: "add",
      path: `/activity/${indexToUpdate}`,
      value: {
        reference: {
          reference: "ServiceRequest/" + serviceRequest.data.id,
          type: "ServiceRequest"
        }
      }
    }]
  };
}

export function getQuestionnairesInDate(carePlan, date) {
  let localQuestionnaires = [];
  carePlan.activity
    .filter(entry => (entry.detail?.kind === 'Appointment'))
    .filter(entry => {
      const startDate = new Date(entry.detail.scheduledPeriod.start);
      const endDate = new Date(entry.detail.scheduledPeriod.end);
      return isDateInRange(date, startDate, endDate);
    })
    ?.forEach(entry => {
      localQuestionnaires.push(entry);
    });
  return localQuestionnaires;
};

export function updateStatusActivityQuestionnaireCarePlan(carePlan, referenceId, status) {
  const indexToUpdate = carePlan.activity.findIndex(a => a.detail && a.detail.instantiatesCanonical[0] === 'Questionnaire/' + referenceId);
  const dataPatch = {
    id: carePlan.id,
    patch: [{
      op: "replace",
      path: `/activity/${indexToUpdate}/detail/status`,
      value: status
    }]
  };

  return CarePlan.updatePatch(dataPatch);
}