// Funcionalidades para gestión de recurso Consent
import http from 'common/HttpService';
import Patient from 'api/Patient';

const Consent = {
    
    createConsent: async (values) => {
        const PatientFound = await Patient.findByIdentifier(values.identifier);
        const data={
            resourceType: 'Consent',
            status: 'active',
            dateTime: values.signDate,
            scope: {
                coding: [
                  {
                    system: 'http://terminology.hl7.org/CodeSystem/consentscope',
                    code: 'research',
                    display: 'Research'
                  }
                ]
            },
            category: [
              {
                  coding: [
                      {
                          system: 'http://loinc.org',
                          code: '59284-0'
                      }
                  ]
              }
            ],
            patient: {
                
                reference: `Patient/${PatientFound.id}`,
                display: PatientFound.name[0].text
                
            },
            policyRule: {
                coding: [{
                    system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
                    code: 'GDPRCD',
                    display: 'GDPR Consent Directive'
                }]
            },
            sourceAttachment: {
                title: values.registerNumber
            }
              
        }

        return data;
    },
    deleteConsent: (id) => {
        return http.delete(`/Consent/${id}`);
    }
    
};

export default Consent;