import ActivityDefinition from 'api/ActivityDefinition';
import Observation from 'api/Observation';
import useNotification from 'hooks/useNotification';
import { useState, useEffect } from 'react';
import HeartRateService, { HRV_CONNECTION_PARAMS } from 'api/bluetooth/HeartRateService';
import useBluetooth from 'hooks/bluetooth/useBluetooth';
import GenericObservationsComponent from 'components/Observation/GenericObservationsComponent';
/**
 * Componente que orquesta la renderización de todas las Observation asociadas a una ObservationDefinition de una Communication.
 * Sólo se visualizan las observaciones, no es posible modificarlas
 *
 * @param {Object} activityDefinitionIDs Lista de IDs de las activityDefinitions a mostrar referenciadas en el campo instantiatesCanonical de la communication
 * @param {Array}  observationIDs lista de IDs de observaciones a mostrar en este componente.
 * @param {Object} patient contiene los datos del paciente que realiza las observationes
 * @param {Object} practitionerRole
 *
 */
export default function CommunicationObservationComponent({ activityDefinitionIDs, observationIDs, patient, practitionerRole}) {

  const [observations, setObservations] = useState();
  const [activityDefinitions, setActivityDefinitions] = useState();
  const [observationDefinitions, setObservationDefinitions] = useState();
  const [observationsObject,setObservationsObject] = useState();
  const notification = useNotification();

  const isObservationDefinition = (identifier) => { return identifier.includes('ObservationDefinition'); };

  useEffect(() => {
    if (activityDefinitionIDs && observationIDs) {
      loadObservations();
      loadActivityDefinitions();
    }
    return() =>{
      setObservations();
      setActivityDefinitions();
    }
  }, [activityDefinitionIDs]);

  useEffect(() => {
    if (activityDefinitions) {
      loadObservationDefinitions(activityDefinitions);
    }
    return() =>{
      setObservationDefinitions();
    }
  }, [activityDefinitions]);

  useEffect(() => {
    if (observationDefinitions || observations) {
      loadObservationsObject(observationDefinitions, observations);
    }
    return() =>{
      setObservationsObject();
    }
  }, [observationDefinitions, observations]);

  const loadObservations = async () => {
    const observationPromises = [];
    observationIDs?.forEach((observationID) => {
        observationPromises.push(Observation.get(observationID));
    });
    await Promise.allSettled(observationPromises).then((responses) =>{
      setObservations(responses);
    }).catch(e => {
      console.log("Error cargando las observations ", e);
      notification.error({
        title: "Error cargando las actividades",
        caption: "No ha sido posible cargar los datos de las actividades. Contacte con el administrador."
      });
    });
  };

  const loadActivityDefinitions = async () =>{
    const activityDefinitionPromises = [];
    activityDefinitionIDs?.forEach((activityDefinitionID) => {
        activityDefinitionPromises.push(ActivityDefinition.findById(activityDefinitionID));
    });
    await Promise.allSettled(activityDefinitionPromises).then((responses) =>{
      setActivityDefinitions(responses);
    }).catch(e => {
      console.log("Error cargando las activityDefinitions : ", e);
      notification.error({
        title: "Error cargando las actividades",
        caption: "No ha sido posible cargar los datos de las actividades. Contacte con el administrador."
      });
    });
  };

  const loadObservationDefinitions = (activityDefinitionList) =>{
    let observationDefinitionsList = [];
    activityDefinitionList?.forEach(activityDefinition =>{
      activityDefinition?.value?.contained?.forEach(containedItem => {
        if(isObservationDefinition(containedItem.resourceType)){
          observationDefinitionsList.push(containedItem);
        }
      });
    });
    setObservationDefinitions(observationDefinitionsList);
  };

  const loadObservationsObject = (observationsDefinition, observations) => {
    let observationArray = [];

    if (observations && observationsDefinition) {
      observationsDefinition
        .sort((a, b) => a.id.localeCompare(b.id))
        .forEach((observationDefinition) => 
          observationArray.push({
            observationDefinition: observationDefinition,
            observations: observations?.filter(item => item.value.code.coding[0].code === observationDefinition.code.coding[0].code)
          })
        );
    }
    setObservationsObject(observationArray);
  };


  return (
    <>

      {
        observationsObject && 
        <div className='observations-viewer'>
          {observationsObject.map((observationObject) =>
            <GenericObservationsComponent key={observationObject.observationDefinition.id}
              observationDefinition={observationObject.observationDefinition}
              observations={observationObject.observations}
              patient={patient}
              practitionerRole={practitionerRole}
              readOnly={true}
              /> 
        )}
        </div>
      }

     
    </>
    );

}