import { isDateRange, dateRangeLocaleStringToDate, addDaysToDate } from 'common/DateTimeUtils';

const CAMPO_FECHA_FILTRADO = 'date';

/**
 * Custom implementation of how we filter rows internally. The idea behind this
 * implementation is to use the given list of row ids and headers to get the
 * individual cell values for a row. Then, we go through each cell value and see
 * if any of them includes the given inputValue or, if the input value is a 
 * range of dates we compare the date column.
 *
 * @param {object} config
 * @param {Array<string>} config.rowIds array of all the row ids in the table
 * @param {Array<object>} config.headers
 * @param {object} config.cellsById object containing a map of cell id to cell
 * @param {string} config.inputValue the current input value in the Table Search
 * @param {Function} config.getCellId
 * @returns {Array<string>} rowIds
 */
 export const customFilterRows = ({
  rowIds,
  headers,
  cellsById,
  inputValue,
  getCellId,
}) =>
  rowIds.filter((rowId) =>
    {
      const inputIsDateRange = isDateRange(inputValue);
      return headers.some(({ key }) => {
        const id = getCellId(rowId, key);
        if (typeof cellsById[id].value === 'boolean') {
          return false;
        }

        if(inputIsDateRange && key === CAMPO_FECHA_FILTRADO) {
          return checkDateInRange(inputValue, '' + cellsById[id].value);
        } else {
          return compareString(inputValue, '' + cellsById[id].value);
        }
      })
    }
  );

const compareString = (input, value) => {
  return value.toLowerCase().includes(input.toLowerCase());
}

const checkDateInRange = (input, value) => {
  const fechas = dateRangeLocaleStringToDate(input);
  if(fechas && fechas.length === 2 ) {
    const valueAsDate = new Date(value);
    return valueAsDate >= fechas[0] && valueAsDate < addDaysToDate(fechas[1], 1);
  }

  return false;
}

export const searchValueInRow = (row, searchValue) => {
  if (searchValue) {
    const result = row.cells[2].value.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.cells[3].value.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.cells[4].value.toLowerCase().includes(searchValue.toLowerCase());
    return result;
  } else {
    return true;
  }
};