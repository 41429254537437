import React from 'react';
import {
  CODE_GALVANIC_SKIN_RESPONSE, CODE_MONITORING_CARDIAC_OUTPUT, CODE_PULSE_OXIMETRY, CODE_RECORD_VOICE
} from 'api/constants/tags/ObservationTags';

import CloneComponentWithProps from 'common/CloneComponentWithProps';
import HrvRecorder, { RECORDER_DATA_TYPE as HRV_RECORDER_DATA_TYPE } from 'components/HrvRecorder';
import FileRecorder, { RECORDER_DATA_TYPE as FILE_RECORDER_DATA_TYPE } from 'components/FileRecorder/FileRecorder';
import VoiceRecorder, { RECORDER_DATA_TYPE as VOICE_RECORDER_DATA_TYPE } from 'components/VoiceRecorder/VoiceRecorder';
import GenericSingleObservationComponent from './GenericSingleObservationComponent';

const SPECIFIC_COMPONENTS_TO_RENDER = [
  { code: CODE_MONITORING_CARDIAC_OUTPUT, dataType: HRV_RECORDER_DATA_TYPE, component: <HrvRecorder /> },
  { code: CODE_GALVANIC_SKIN_RESPONSE, dataType: FILE_RECORDER_DATA_TYPE, component: <FileRecorder /> },
  { code: CODE_RECORD_VOICE, dataType: VOICE_RECORDER_DATA_TYPE, component: <VoiceRecorder /> },
  { code: CODE_RECORD_VOICE, dataType: VOICE_RECORDER_DATA_TYPE, component: <VoiceRecorder /> },
];

export const NO_OCCURRENCE_SPECIFIC_COMPONENT_METHOD_CODES = [CODE_MONITORING_CARDIAC_OUTPUT, CODE_PULSE_OXIMETRY];

/**
 * Factory que instancia el componente adecuado para ver/registrar una observación. Se tiene en cuenta el tipo
 * de medición según su ObservationDefinition.code y el tipo de dato ObservationDefinition.permittedDataType.
 * 
 * La constante SPECIFIC_COMPONENTS_TO_RENDER define los componentes específicos que gestionan Observaciones 
 * en función del código LOINC de la observacion y el tipo de dato definido en la ObservationDefinition.
 * 
 * @param {Object} observationDefinition que describe la observación a renderizar.
 * @param {Object} bluetoothStateHrv que contiene la conexión al dispositivo de HRV.
 * @param {Object} bluetoothStateSpO2 que contiene la conexión al dispositivo de Saturación de oxígeno.
 * @param {*} props resto de propiedades a trasladar a los children.
 * @returns {Object} componente que permite registrar y visualizar datos de una Observation.
 */
 export default function ObservationComponentFactory({ observationDefinition, bluetoothStateHrv, ...props }) {

  function getBluetoothState(code) {
    if (code === CODE_MONITORING_CARDIAC_OUTPUT) {
      return bluetoothStateHrv;
    } else {
      return null;
    }
  }
  /**
   * Buscar el componente específico para una ObservationDefinition si lo hubiese.
   * 
   * @param {*} observationCodeParam código LOINC de la observación.
   * @param {*} observationDataTypeParam tipo de dato FHIR de la observación 
   * @returns {Object} componente o null
   */
  function findSpecificComponentToRender(observationCodeParam, observationDataTypeParam) {
    return SPECIFIC_COMPONENTS_TO_RENDER.find((component) =>
      component.code === observationCodeParam && component.dataType === observationDataTypeParam
    ) || null;
  };

  /**
   * Checkea las condiciones necesarias para instanciar un componente genérico o específico
   * en función de la ObservationDefinition.
   * @returns el componente adecuado para la observación.
   */
  function returnComponent() {
    const observationMethodCode = observationDefinition.method.coding[0].code;
    const observationDataType = observationDefinition.permittedDataType[0];
    const specificComponentToRender = findSpecificComponentToRender(observationMethodCode, observationDataType);

    return specificComponentToRender ?
      <CloneComponentWithProps observationDefinition={observationDefinition} {...props} bluetoothState={getBluetoothState(observationMethodCode)}>
        {specificComponentToRender.component}
      </CloneComponentWithProps>
      : <GenericSingleObservationComponent observationDefinition={observationDefinition} {...props} />;
  }

  return returnComponent();
}