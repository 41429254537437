import CloneComponentWithProps from 'common/CloneComponentWithProps';
import React, { useRef } from 'react';

export default function ProgressBar({ width = '100%', height = '3rem', children }) {

  const refProgressBar = useRef();

  const updateProgressBar = (progress) => {
    refProgressBar.current.style.width = progress;
  };

  return (
    <div className="progress-bar" style={{ width: width, height: height }}>
      <div className="progress-bar__base" style={{ width: width, height: height }}></div>
      <div className='progress-bar__progress' style={{ width: '0%', height: height }} ref={refProgressBar}></div>
      <h5 className="children">
        <CloneComponentWithProps onProgress={updateProgressBar}>
          {children}
        </CloneComponentWithProps>
      </h5>
    </div >
  );
}
