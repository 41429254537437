import axios from 'axios';
import AuthStore from "auth/AuthStore";
import AuthProvider from 'auth/AuthProvider';

const instance = axios.create({
  baseURL: process.env.REACT_APP_FHIR_SERVER_URL,
  timeout: 30000
});

instance.interceptors.request.use(cfg => {
  const provider = new AuthProvider();
  if(provider.isTokenExpired()){
    provider.updateToken();
  }
  cfg.headers.common['Cache-Control'] = 'no-cache';
  cfg.headers.common['Authorization'] = AuthStore.getAuthentication().toHeader();
  return cfg;
});

export default instance;