import ReactDOM from 'react-dom';
import { Loading, Modal } from '@carbon/react';
import NotificationLauncher from './NotificationLauncher';

export default function NotificationVisualizer({ notifications, searchNotifications, onClose }) {

  return (
    <>
      {
        typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <Modal
              open
              onRequestClose={onClose}
              onRequestSubmit={onClose}
              primaryButtonText="Cerrar"
              size="sm"
              modalHeading={"Notificaciones"}
              preventCloseOnClickOutside
            >
              {
                notifications.length > 0 ?
                notifications.map((notification) => {
                  return (
                    <NotificationLauncher key={notification.id} notification={notification} handleCloseNotification={searchNotifications} />
                  );
                })
                : 
                <Loading withOverlay={true} small={true} />
              }
            </Modal>
            , document.body
          )
      }
    </>
  );
}