// Funcionalidades para gestión de recurso QuestionnaireResponse
import http from 'common/HttpService';

const QuestionnaireResponse = {

    getQuestionnaireResponse: async (questionnaireResponseId) => {
        const res = await http.get(`/QuestionnaireResponse/${questionnaireResponseId}`);
        return res.data;
    },
    findAllDataBy: async (query) => {
        const res = await http.get(`/QuestionnaireResponse/${query}`);
        return res.data;
    },
    getQuestionnaireResponses: async () => {
        const res = await http.get('/QuestionnaireResponse');
        return res.data;
    },
    getQuestionnaireResponseById: async (questionnaireId, patient) => {
        const res = await http.get(`/QuestionnaireResponse?questionnaire=Questionnaire/${questionnaireId}&patient=${patient}`);
        return res.data;
    },
    createQuestionnaireResponse: (questionnaireResponse) => {
        const headers = { headers: { 'Prefer': 'return=representation' } };
        return http.post('/QuestionnaireResponse', questionnaireResponse, headers);
    },
    updateQuestionnaireResponse: (questionnaireResponse) => {
        const headers = { headers: { 'Prefer': 'return=representation' } };
        return http.put(`/QuestionnaireResponse/${questionnaireResponse.id}`, questionnaireResponse, headers);
    },
    updatePatch: (dataPatch) => {
        const headers = { headers: { 'Prefer': 'return=representation', 'Content-Type': 'application/json-patch+json' } };
        return http.patch(`/QuestionnaireResponse/${dataPatch.id}`, dataPatch.patch, headers);
    }

};

export default QuestionnaireResponse;