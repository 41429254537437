import React from 'react';

function Copyright({ questionnaire }) {
  let copyright = questionnaire.copyright;

  return (
    copyright ? 
    <div className='copyright' dangerouslySetInnerHTML={{__html: copyright}}></div>
    :
    <></> 
  );
}

export default Copyright;