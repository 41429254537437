import { toISOString, dateTimeToLocalDate } from 'common/DateTimeUtils';
import { equivalentCodes } from 'common/CodeUtils';

import { getFirstTag, insertOccurrenceTag } from 'api/constants/tags/TagFunctions';
import { buildTagListFromPatientAndCarePlan, mergeTagList, deleteTags } from 'api/constants/tags/TagFunctions';
import Observation from 'api/Observation';
import Patient from 'api/Patient';
import { OBSERVATION_STATUS_PRELIMINARY, OBSERVATION_STATUS_DEFAULT } from 'api/constants/ObservationStatus';
import { SYSTEM_OBSERVATION_DEFINITION_COMPONENT } from 'api/constants/tags/ObservationDefinitionTags';
import {
  PATIENT_TYPE_SYSTEM
} from "api/constants/tags/PatientTags";

import cloneDeep from 'lodash.clonedeep';
import { SYSTEM_OBSERVATION_MOMENT, SYSTEM_OBSERVATION_OCCURRENCE } from 'api/constants/tags/ObservationTags';

export const OBSERVATION_EFFECTIVE_TYPE = {
  TIME: 'time',
  PERIOD: 'period'
};

/**
 * Recupera el valor de una variable de una observation según el tipo de dato.
 * @param {*} dataType 
 * @param {*} observation 
 * @returns 
 */
export function getObservationValue(dataType, observation) {
  if (!observation) {
    return '';
  }

  switch (dataType) {
    case 'Quantity':
      return observation.valueQuantity.value;
    case 'integer':
      return observation.valueInteger;
    case 'SampledData':
      return observation.valueSampledData.data;
    case 'string':
      return observation.valueString;
    case 'boolean':
      return observation.valueBoolean;
    case 'time':
      return observation.valueTime ? observation.valueTime.substr(0, observation.valueTime.length - 3) : '';
    case 'dateTime':
      return dateTimeToLocalDate(observation.valueDateTime);
    default:
      return null;
  }
}

/**
 * Crea un objeto de Observation a partir de los objetos que definen su contexto. Si se incluyen
 * los valores de la observación, la observación se creará con todos los datos.
 * 
 * @param {Object} observationDefinition 
 * @param {integer} occurrence 
 * @param {Object} carePlan 
 * @param {Object} serviceRequest 
 * @param {Object} encounter 
 * @param {Object} patient 
 * @param {Object} practitionerRole 
 * @param {Object} observationData objeto compuesto de los datos a almacenar de una observación concreta.
 * @returns la observción creada o null si hubo algún error
 */
export function createObservation(observationDefinition, occurrence, carePlan, serviceRequest, encounter, patient, practitionerRole,
  observationData = null) {

  let observation = Observation.getTemplate(observationDefinition);
  observation.basedOn = [{
    reference: `ServiceRequest/${serviceRequest?.id}`,
    type: 'ServiceRequest'
  }];
  observation.subject = {
    reference: `Patient/${patient.id}`,
    type: 'Patient',
    display: Patient.getAlias(patient)
  };
  observation.performer = [{
    reference: `PractitionerRole/${practitionerRole.id}`,
    type: 'PractitionerRole',
    display: practitionerRole.practitioner ? practitionerRole.practitioner.display : null
  }];
  observation.encounter = {
    reference: `Encounter/${encounter?.id}`,
    type: 'Encounter'
  };
  observation.note = [];
  if (!observationDefinition.permittedDataType.includes('CodeableConcept')) {
    observation.component = [];
  }
  if (!observation.meta?.tag) {
    observation.meta = { tag: [] };
  }
  insertOccurrenceTag(observation.meta.tag, occurrence);

  const newObservationData = observationData || { value: fillObservationDataValue(observationDefinition) };

  return updateObservation(observationDefinition, observation, carePlan, patient, practitionerRole, newObservationData);
}

/**
 * Actualiza los valores de una observación en función de los parámetros de entrada. Se actulizan los siguientes campos:
 *  - meta.tags: obtenidos de los tags del paciente y del carePlan
 *  - nota
 *  - nombre del dispositivo
 *  - valor de la observación
 * 
 * @param {Object} observationDefinition 
 * @param {Object} observation observcación a actualizar
 * @param {Object} observationData objeto compuesto de los datos a almacenar de una observación concreta.
 *  - device: nombre del dispositivo que se usa para tomar los datos
 *  - value: String con el valor a almacenar. Tiene que ser compatible con el parseo del tipo de dato que indica la ObservationDefinition.
 *  - note: String con el texto a almacenar como nota de la observación.
 *  - status: String con el valor del estado a asignar a la observación.
 * @param {Object} patient 
 * @param {Object} carePlan  
 * @param {Object} practitionerRole 
 * @returns la Observation actualizada o null si hubo algún error actualizando.
 */
export function updateObservation(observationDefinition, observation, carePlan, patient, practitionerRole, observationData) {
  if (!observationData || !observation) {
    return null;
  }
  let observationClone = cloneDeep(observation);
  const tagSetFromPatientAndCarePlan = buildTagListFromPatientAndCarePlan(patient, carePlan);
  observationClone.meta.tag = deleteTags(observationClone.meta.tag, PATIENT_TYPE_SYSTEM);
  observationClone.meta.tag = mergeTagList(observationClone.meta.tag, tagSetFromPatientAndCarePlan);

  observationClone.status = observationData.status || OBSERVATION_STATUS_DEFAULT;

  if (observationData.effective && observationData.effective.type === OBSERVATION_EFFECTIVE_TYPE.TIME) {
    observationClone.effectiveDateTime = toISOString(new Date(observationData.effective.value));
  } else if (observationData.effective && observationData.effective.type === OBSERVATION_EFFECTIVE_TYPE.PERIOD) {
    if (observationClone.effectiveDateTime) {
      delete observationClone.effectiveDateTime;
    }
    observationClone.effectivePeriod = {
      start: toISOString(new Date(observationData.effective.value.start)),
      end: toISOString(new Date(observationData.effective.value.end))
    };
  } else {
    observationClone.effectiveDateTime = toISOString(new Date());
  }

  if (observationData.note && observationData.note.text) {
    observationClone.note = [{
      authorString: observationData.note.authorString ||
        (practitionerRole.practitioner ? practitionerRole.practitioner.display : ''),
      time: observationData.note.time || toISOString(new Date()),
      text: observationData.note.text
    }];
  } else {
    delete observationClone.note;
  }

  if (observationData.device) {
    observationClone.device = { display: observationData.device };
  }

  const newValues = observationData.value;
  if (!observationDefinition.permittedDataType.includes('CodeableConcept')) {
    observationClone.component = [];
  }
  newValues.forEach(val => {
    switch (observationDefinition.permittedDataType[0]) {
      case 'Quantity':
      case 'quantity':
        observationClone.component.push({
          code: val.code,
          valueQuantity: {
            value: parseFloat(val.value || 0),
            unit: observationDefinition.quantitativeDetails.unit.text
          }
        });
        break;
      case 'integer':
        observationClone.component.push({
          code: val.code,
          valueInteger: parseInt(val.value || 0)
        });
        break;
      case 'SampledData':
        observationClone.component.push({
          code: val.code,
          valueSampledData: {
            origin: { value: 2048 },
            period: 1000,
            dimensions: 1,
            data: val.value
          }
        });
        break;
      case 'CodeableConcept':
        if (val.derivedFrom) {
          if (observationClone.contained) {
            observationClone.contained.push(val.derivedFrom);
          } else {
            observationClone.contained = [
              val.derivedFrom
            ];
          }
          if (observationClone.derivedFrom) {
            observationClone.derivedFrom.push({ reference: `#${val.derivedFrom.id}` });
          } else {
            observationClone.derivedFrom = [
              { reference: `#${val.derivedFrom.id}` }
            ];
          }
        }
        break;
      case 'string':
        observationClone.component.push({
          code: val.code,
          valueString: val.value
        });
        break;
      case 'boolean':
        observationClone.component.push({
          code: val.code,
          valueBoolean: val.value
        });
        return true;
      case 'time':
        let timeValue = val.value;
        if (timeValue) {
          timeValue = `${val.value}:00`;
        }
        observationClone.component.push({
          code: val.code,
          valueTime: timeValue
        });
        break;
      case 'date':
      case 'dateTime':
        let dateTimeValue = val.value;
        if (dateTimeValue) {
          const [dia, mes, ano] = val.value.split('/');
          dateTimeValue = toISOString(new Date(ano, mes - 1, dia)).split('.')[0] + "Z";
        }
        observationClone.component.push({
          code: val.code,
          valueDateTime: dateTimeValue
        });
        break;
      default:
      // nothing to do
    }
  });
  return observationClone;
}

/**
 * Busca las observaciones que cumplen los criterios pasados por parámetros.
 * 
 * @param {Object} encounter encounter al que asociar la observación.
 * @param {Array} tags lista de tags que debe contener la observación. Es necesario que se cumplan todos (AND).
 * @returns 
 */
export const findByEncounterAndObservationMomentTag = async (encounter, tags) => {
  if (!encounter) {
    return [];
  }
  let query = `encounter=Encounter/${encounter.id}`;

  if (tags && tags.length) {
    query += tags.reduce((prev, curr) => { return prev + `,${curr.system}|${curr.code}`; }, '&_tag=');
  }
  return await Observation.findBy(query);
};

/**
 * Realiza actualizaciones sobre los campos del objeto Observation antes de guardarlo/actualizarlo.
 *  - elimina los ids temporales
 *  - sustituye el estado de la observación si es DEFAULT
 * 
 * @param {Object} observation 
 * @returns 
 */
const updateObservationWhenSaving = (observation) => {
  if (!observation) {
    return;
  }

  let updatedObservation = cloneDeep(observation);

  if (updatedObservation.id.startsWith('temp-')) {
    delete updatedObservation.id;
  }

  if (observation.status === OBSERVATION_STATUS_DEFAULT) {
    updatedObservation.status = OBSERVATION_STATUS_PRELIMINARY;
  }
  return updatedObservation;
};

/**
 * Guarda en FHIR a través del API REST las observaciones pasadas como parámetro. 
 * 
 * Se intenta guardan todas las observaciones aunque falle alguna se continúa 
 * con el resto de peticiones.
 * 
 * @param {Array} observations 
 * @returns Promise
 */
const saveAll = async (observations) => {
  return await Promise.allSettled(observations.map(async observation => {
    let observationToSave = updateObservationWhenSaving(observation);

    if (observationToSave.id) {
      return await Observation.updateObservation(observationToSave);
    } else {
      //TODO: Cuando se crea la observación FHIR devuelve el objeto creado, habría que coger esa observación y actualizarla en el estado
      // con esto se renderízarían de nuevo todos los componentes y se le pasaría la observación creada al componente hijo correspondiente.
      // De esta forma el componente hijo sabría que se guardó correctamente el resultado.
      return await Observation.createObservation(observationToSave, true);
    }
  }));
};

/**
 * Guarda en FHIR las observaciones.
 * 
 * El comportamiento del guardado se implementa en saveAll. Ver documentación para determinar
 * su comportamiento.
 * 
 * @param {Array} observations 
 * @returns true en caso de que todas las Observaciones hayan sido guardadas correctamente y 
 *          false si existe algún fallo.
 */
export const saveObservations = async (observations) => {
  const observationsCreatedUpdated = new Map();
  const observationsRejected = [];
  return new Promise((resolve, reject) => {
    saveAll(observations).then((responseArray) => {
      responseArray.forEach((response) => {
        if (response.status === 'rejected') {
          observationsRejected.push(response);
        } else {
          const observationResponse = response.value.data ? response.value.data : JSON.parse(response.value.config.data);
          if (response.value.data) {
            const observationSource = observations.filter(o => {
              const oMoment = getFirstTag(o.meta, SYSTEM_OBSERVATION_MOMENT);
              const oResponseMoment = getFirstTag(observationResponse.meta, SYSTEM_OBSERVATION_MOMENT);
              const oOcurrence = getFirstTag(o.meta, SYSTEM_OBSERVATION_OCCURRENCE);
              const oResponseOcurrence = getFirstTag(observationResponse.meta, SYSTEM_OBSERVATION_OCCURRENCE);
              return o.code.coding[0].code === observationResponse.code.coding[0].code && oMoment === oResponseMoment && oOcurrence === oResponseOcurrence;
            })[0];
            observationsCreatedUpdated.set(observationSource.id, observationResponse);
          } else {
            observationsCreatedUpdated.set(observationResponse.id, observationResponse);
          }
        }
      });
      if (observationsRejected.length > 0) {
        reject(observationsRejected);
      } else {
        resolve(observationsCreatedUpdated);
      }
    }).catch((e) => {
      console.log(e);
      reject(e);
    });
  });
};

/**
 * Devuelve la cadena de texto con el estado si todas las observaciones tienen ese mismo estado.
 * 
 * @param {String} checkedStatus estado a comprobar
 * @returns el estado común a todas las observaciones o null.
 */
export const returnCommonStatus = (observations, checkedStatus) => {
  return observations.reduce((prev, curr) => { return prev && curr.status === checkedStatus; }, true) ?
    checkedStatus : null;
};

/**
 * Constuye e inicializa el array Observation.component a partir de la observationDefinition.
 */
const buildObservationDataValue = (observationDefinition) => {
  let observationComponent = null;
  if (observationDefinition.multipleResultsAllowed && JSON.parse(observationDefinition.multipleResultsAllowed)
    && observationDefinition.extension) {
    observationComponent = observationDefinition.extension
      .filter(item => { return item.url === SYSTEM_OBSERVATION_DEFINITION_COMPONENT; })
      .map(item => {
        return {
          code: item.valueCodeableConcept,
          value: ''
        };
      });
  } else {
    observationComponent = [{ code: observationDefinition.code, value: '' }];
  }
  return observationComponent;
};

/**
 * Rellena la lista de variables que una ObservationData debe tener a partir de las variables
 * definidas en la ObservationDefinition y una lista previa de valores.
 * @param {Object} observationDefinition ObservationDefinition con la descripción de la Observation.
 * @param {Array} existingObservationComponent lista de variables con valor previamente existentes.
 * @returns array de variables recuperados de la ObservationDefinition y cumplimentados con 
 *          los valores existentes previamente.
 */
export const fillObservationDataValue = (observationDefinition, existingObservationComponent = null) => {
  const allObservationComponent = buildObservationDataValue(observationDefinition);

  if (existingObservationComponent) {
    existingObservationComponent.forEach(existingObservationComponentItem => {
      let foundIndex = allObservationComponent.findIndex(item => item.value === '' && equivalentCodes(item.code, existingObservationComponentItem.code));
      if(foundIndex >= 0) {
        allObservationComponent[foundIndex].value = getObservationValue(observationDefinition.permittedDataType[0], existingObservationComponentItem);
      }
    });
  }

  return allObservationComponent;
};