// Funcionalidades para gestión de recurso Activity definition
import http from 'common/HttpService';
import { hasCode } from 'api/constants/tags/TagFunctions';
import { SYSTEM_OBSERVATION_MOMENT } from 'api/constants/tags/ObservationTags';

const ActivityDefinition = {
  getActivities: async () => {
    const res = await http.get('/ActivityDefinition?_count=500');
    const { entry = [] } = res.data;
    return entry;
  },
  findByIdentifier: async (identifier) => {
    const res = await http.get(`/ActivityDefinition?identifier=${identifier}`);
    const { entry = [] } = res.data;
    return entry[0].resource;
  },
  findById: async (id) => {
    const res = await http.get(`/ActivityDefinition/${id}`);
    return res.data;
  },
  filterAndSortObservationDefinitionsByMoment: (observationDefinitions, observationMoment) => {
    if (!observationMoment && observationDefinitions) {
      return observationDefinitions;
    }
    return observationDefinitions && observationDefinitions.filter((observationDefinition) => {
      if (hasCode(observationDefinition.meta, SYSTEM_OBSERVATION_MOMENT, observationMoment)) {
        return observationDefinition;
      } else {
        return null;
      }
    });
  }
};

export default ActivityDefinition;