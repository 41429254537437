import React, { useEffect, useState } from 'react';
import { Header, HeaderContainer, HeaderName, HeaderNavigation, HeaderMenuButton, HeaderMenuItem, HeaderGlobalBar, HeaderGlobalAction, SideNav, SideNavItems, HeaderSideNavItems, Tag } from '@carbon/react';
import { Notification, NotificationNew, UserAvatar, Home, Events, ChartCustom, Dashboard, Settings } from '@carbon/react/icons';
import { Link, NavLink } from 'react-router-dom';
import logoCoperia from 'layout/navbar/horz-parafondosclaros.png';
import AuthProvider from 'auth/AuthProvider';
import AuthStore from "auth/AuthStore";
import AccountOptions from './AccountOptions';
import { findAllCommunicationsNotDone, findCountOfAllCommunicationsNotDone } from 'components/Communication/CommunicationService';
import NotificationVisualizer from '../notification/NotificationVisualizer';


export default function Navbar() {
  const provider = new AuthProvider();
  const [accountOptionsModal, setAccountOptionsModal] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [openVisualizer, setOpenVisualizer] = useState(false);

  const MINUTE_MS = 900000;
  const supersetUrl = process.env.REACT_APP_SUPERSET_DASHBOARD_URL


  useEffect(() => {
    const interval = setInterval(() => {
      searchNotificationsCount();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  useEffect(() => {
    searchNotificationsCount();
  });

  const handleCloseAccountOptions = () => {
    setAccountOptionsModal(false);
  };

  const handleCloseNotificationVisualizer = () => {
    setOpenVisualizer(false);
    setNotifications([]);
  };

  const searchNotifications = () => {
    findAllCommunicationsNotDone().then(response => {
      if (response?.length > 0) {
        setNotifications(response);
      }
    });
  };

  const searchNotificationsCount = () => {
    findCountOfAllCommunicationsNotDone().then(response => {
      if (response > 0) {
        setNotificationCount(response);
      }
    });
  };

  const isAdmin = AuthStore.getAuthentication()?.hasRole("admin");

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="COPERIA">
          <HeaderMenuButton
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName element={Link} to="/" prefix="">
            <img src={logoCoperia} alt="Logo COPERIA" className="coperia-logo"></img>
          </HeaderName>
          <HeaderNavigation aria-label="COPERIA">
            <HeaderMenuItem aria-label="Inicio" element={NavLink} to="/inicio"><div className="d-flex center-vertically"><Home size={20} />&nbsp; Inicio</div></HeaderMenuItem>
            <HeaderMenuItem element={NavLink} to="/patients"><div className="d-flex center-vertically"><Events size={20} />&nbsp; Pacientes</div></HeaderMenuItem>
            <HeaderMenuItem element={NavLink} to="/docs"><div className="d-flex center-vertically"><ChartCustom size={20} />&nbsp; Contenidos</div></HeaderMenuItem>
            {supersetUrl && <HeaderMenuItem element={NavLink} to="/analytics"><div className="d-flex center-vertically"><Dashboard size={20} />&nbsp; Analítica</div></HeaderMenuItem>}
            {isAdmin && <HeaderMenuItem element={NavLink} to="/admin"><div className="d-flex center-vertically"><Settings />&nbsp; Configuración</div></HeaderMenuItem>}
          </HeaderNavigation>
          <HeaderGlobalBar>
            <HeaderGlobalAction aria-label="Notificationes" onClick={() => {
              if (notificationCount > 0) {
                setOpenVisualizer(true);
                searchNotifications();
              }
            }}>
              {
                notificationCount > 0 ?
                  <NotificationNew size={20} /> :
                  <Notification size={20} />
              }
            </HeaderGlobalAction>
            <HeaderGlobalAction aria-label="Mi perfil" onClick={() => { setAccountOptionsModal(true); }}>
              <UserAvatar size={20} />
            </HeaderGlobalAction>
            <Tag type="gray" title="Versión"> {process.env.REACT_APP_VERSION} </Tag>
          </HeaderGlobalBar>
          {accountOptionsModal && <AccountOptions
            name={provider.getName()}
            roleId={provider.getPractitionerRoleId()}
            onClose={handleCloseAccountOptions}
            logout={provider.logout}
            changePassword={provider.changePassword}
          />}
          {openVisualizer > 0 && <NotificationVisualizer
            notifications={notifications}
            searchNotifications={searchNotifications}
            onClose={handleCloseNotificationVisualizer}
          />
          }
          <SideNav
            aria-label="Side navigation"
            expanded={isSideNavExpanded}
            isPersistent={false}>
            <SideNavItems>
              <HeaderSideNavItems>
                <HeaderMenuItem element={NavLink} to="/inicio">Inicio</HeaderMenuItem>
                <HeaderMenuItem element={NavLink} to="/patients">Pacientes</HeaderMenuItem>
                <HeaderMenuItem element={NavLink} to="/docs">Contenidos</HeaderMenuItem>
                <HeaderMenuItem element={NavLink} to="/analytics">Analítica</HeaderMenuItem>
              </HeaderSideNavItems>
            </SideNavItems>
          </SideNav>
        </Header>
      )}
    />
  );
}