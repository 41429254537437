// Funcionalidades para gestión de recurso Communication
import Communication from 'api/Communication';

import { SYSTEM_ASSAY_PHASE, ASSAY_PHASE_COMMUNICATION } from 'api/constants/tags/AssayTags';
import { getAssayTagsFromAssayCodes, buildTagFromCodeAndSystem } from 'api/constants/tags/TagFunctions';
import { buildQueryByTags, getResourceCount, getResourceList } from 'api/ApiCommons';

/**
 * Recupera una Communication a partir de su id.
 * 
 * @param {String} id 
 * @return {Communication}
 */
export const findCommunicationById = (id) => {
  return Communication.get(id);
};

/**
 * Recupera una lista de Communication a partir del identificador de
 * paciente y los ensayos a los que pertenezca.
 * 
 * @param {String} patientId identificador único de paciente en FHIR
 * @param {Array} assays lista con los códigos de los ensayos en formato String
 * @return {Promise} promesa que devolverá la lista de communicaciones
 */
export const findCommunicationsByPatientAndAssay = (patientId, assays) => {
  const assayAsTagList = getAssayTagsFromAssayCodes(assays);
  const communicationTagList = [ buildTagFromCodeAndSystem(ASSAY_PHASE_COMMUNICATION, SYSTEM_ASSAY_PHASE) ];
  const query = `patient=Patient/${patientId}` 
    + buildQueryByTags(assayAsTagList)
    + buildQueryByTags(communicationTagList);
  //console.log('Communication: ' + query);
  return getResourceList(Communication.findBy(query));
};

/**
 * Recupera la lista de notificaciones cuyo estado es 'not-done'
 * 
 * @returns {Promise} promesa que devolverá la lista de communicaciones
 */
export const findAllCommunicationsNotDone = () => {
  const query = `status=not-done` 
  return getResourceList(Communication.findBy(query));
};


/**
 * Recupera el númeoro de notificaciones cuyo estado es 'not-done'
 * 
 * @returns {Promise} promesa que devolverá la lista de communicaciones
 */
 export const findCountOfAllCommunicationsNotDone = () => {
  const query = `status=not-done&_summary=count`
  return getResourceCount(Communication.findAllDataBy(query));
};