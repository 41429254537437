
import http from "common/HttpCovidPersistentPredictionService";

const CovidPersistentPrediction = {
  getPrediction: async (patientId) => {
    const headers = { headers: { 'Prefer': 'return=representation' } };
    return http.post(`/dicoperia_predict`, {'patient_id':patientId}, headers);
  },

}


export default CovidPersistentPrediction;