import React from 'react';
import { Breadcrumb, BreadcrumbItem } from '@carbon/react';
import logoCoperia from 'layout/navbar/horz-parafondosclaros.png';
import { Link } from 'react-router-dom';



export default function Home() {

  return (
    <>
      <div className="heading">
        <Breadcrumb noTrailingSlash>
          <BreadcrumbItem isCurrentPage><Link to="/inicio">Inicio</Link></BreadcrumbItem>
        </Breadcrumb>
      </div>
      <img src={logoCoperia} alt="Logo COPERIA" className="coperia-home-logo"></img>
    </>
  );

}