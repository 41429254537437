import ServiceRequest from 'api/ServiceRequest';
import { buildQueryByTags, getResourceList } from 'api/ApiCommons';
import { SYSTEM_ASSAY_PHASE } from 'api/constants/tags/AssayTags';

/**
 * Busca las ServiceRequest asociadas (basedOn) un CarePlan a partir del id FHIR de este.
 * 
 * @param {String} carePlanId identificador uuid del CarePlan.
 * @returns {Promise} promesa que devolverá la lista de communicaciones
 */
export const findServiceRequestByCarePlanId = (carePlanId) => {
  return getResourceList(ServiceRequest.findByCarePlanIdentifier(carePlanId));
};

/**
 * Busca las ServiceRequest a partir de la fehca planificada y del id de un paciente.
 * 
 * @param {String} patientId identificador uuid del Patient.
 *  * @param {String} date fecha programda de la ServiceRequest.
 * @returns {Promise} promesa que devolverá la lista de ServiceRequest
 */
export const findServiceRequestByPatientIdActivityDateAndAssayPhase = (patientId, date, assayPhases) => {
  let queryServiceRequest = `?patient=${patientId}&occurrence=${date}`;
  if (assayPhases.length) {
    queryServiceRequest = queryServiceRequest + buildQueryByTags(assayPhases, SYSTEM_ASSAY_PHASE);
  }
  return getResourceList(ServiceRequest.findByFilters(queryServiceRequest));
};

/**
 * Actualiza el campo status de una service request
 * 
 * @param {String} serviceRequestId id del recurso fhir
 * @param {String} status campo status del recurso fhir
 * @returns 
 */
export function updateServiceRequestStatus(serviceRequestId, status) {

  const dataPatch = {
    id: serviceRequestId,
    patch: [{
      op: "replace",
      path: `/status`,
      value: status
    }]
  };

  return ServiceRequest.updatePatch(dataPatch);
}