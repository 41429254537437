const DATE_RANGE_SEPARATOR = '#';
const DATE_PART_SEPARATOR = '/';
const TIME_PART_SEPARATOR = ':';
const DATE_HOUR_SEPARATOR = 'T';

export function toISOString(date) {
  return (new Date(date)).toISOString();
}

/**
 * Convierte un string en ISO format a un local Date teniendo en cuenta el timezone
 */
export function isoStringToLocalDateTime(stringDateInIsoFormat) {
  const utcDate = new Date(stringDateInIsoFormat);
  return new Date(utcDate.getTime() + (utcDate.getTimezoneOffset() * 60000));
}

export function dateTimeToLocalDate(datetime) {
  let dateStr = (typeof datetime === 'string') ? new Date(datetime) : datetime;
  return dateStr ? dateStr.toLocaleDateString() : '';
}

/**
 * Obtiene un string con la hora en formato hh:mm:ss de la fecha que se le pasa por parámetros
 * 
 * @param {*} datetime 
 * @returns 
 */
export function dateTimeToLocalTime(datetime) {
  let dateStr = (typeof datetime === 'string') ? new Date(datetime) : datetime;
  return dateStr ? dateStr.toLocaleTimeString('es-ES', { hour12: false }) : '';
}

/**
 * Obtiene un string con la hora en formato hh:mm de la fecha que se le pasa por parámetros
 * 
 * @param {*} datetime 
 * @returns 
 */
 export function dateTimeToLocalTimeWithoutSeconds(datetime) {
  let dateStr = (typeof datetime === 'string') ? new Date(datetime) : datetime;
  return dateStr ? dateStr.toLocaleTimeString('es-ES', { hour: '2-digit', minute:'2-digit', hour12: false }) : '';
}

export function isDate(value) {
  return value.match(/(?<dd>\d+)\/\s*(?<mm>\d+)\/\s*(?<yy>\d+)/);
}

/**
 * Función que transforma el rango de fechas seleccionado en un String para que 
 * el componente nativo DataTable permita usarlo en como filtro en su función getFilteredRowIds.
 */
export const dateRangeToLocaleString = (dates) => {
  const fechasComoStringLocal = dates.map(item => dateTimeToLocalDate(item))
    .reduce((previousValue, currentValue) => previousValue + (currentValue ? (DATE_RANGE_SEPARATOR + currentValue) : ''));
  return fechasComoStringLocal;
};

/**
 * Función que transforma el string de rango de fechas en un array de fechas. Complementa a 
 * la función dateRangeToLocaleString.
 */
export const dateRangeLocaleStringToDate = (dateRangeString) => {
  const fechas = dateRangeString.split(DATE_RANGE_SEPARATOR);
  const fechas2 = fechas.map(item => toDate(item));
  return fechas2;
};

/**
 * Función que transforma el string de una fecha a un rango de fechas.
 */
export const dateStringToRange = (dateRangeString) => {
  const parsedDate = isoStringToLocalDateTime(dateRangeString);
  return [parsedDate, parsedDate];
};

/**
 * Indica si el String pasado por parametro es un rango de fechas o no. Un rango de fechas debe tener el formato
 * dd/mm/yyyy#dd/mm/yyyy.
 * 
 * @param {String} value 
 * @returns {boolean}
 */
export function isDateRange(value) {
  if (!value) {
    return false;
  }
  const splitted = value.split(DATE_RANGE_SEPARATOR);
  if (splitted.length !== 2) {
    return false;
  }

  return splitted.reduce((previous, current) => previous && isDate(current), true);
}

/**
 * Devuelve un objeto Date a partir de un string con la fecha dd/mm/yyyy
 * 
 * @param {String} value en formato dd/mm/yyyy
 * @returns {Date} con la fecha
 */
export function toDate(value) {
  const splitted = value.split(DATE_PART_SEPARATOR);
  return new Date(splitted[2] + DATE_PART_SEPARATOR + splitted[1] + DATE_PART_SEPARATOR + splitted[0]);
}

export function compareDateTimes(dateA, dateB) {
  const dateA2Date = new Date(dateA);
  const dateB2Date = new Date(dateB);
  return dateA2Date.getTime() - dateB2Date.getTime();
}

export function secondsTommss(seconds) {
  if (seconds < 60) {
    return '00:' + (seconds < 10 ? "0" : "") + seconds;
  } else {
    const minutes = seconds / 60;
    const sign = minutes < 0 ? "-" : "";
    const min = Math.floor(Math.abs(minutes));
    const sec = Math.floor((Math.abs(minutes) * 60) % 60);
    return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
  }
}

export function addDaysToDate(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

/**
 * Devuelve un String con la fecha formateada como yyyy-mm-dd a partir
 * de una fecha recibida como un Date o como un String con el formato dd/mm/yyyy
 * 
 * @param {String||Date} fecha en formato dd/mm/yyyy u objeto Date
 * @returns {String} con la fecha en formato yyyy-mm-dd
 */
export function formatDate(date) {
  let month, day, year = '';

  if (typeof date === 'string') {
    [day, month, year] = date.split(DATE_PART_SEPARATOR);
  } else {
    month = '' + (date.getMonth() + 1);
    day = '' + date.getDate();
    year = date.getFullYear();
  }

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}

export function addHoursToDate(date, hour) {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();
  return isoStringToLocalDateTime(new Date(yyyy + "-" + mm + "-" + dd + "T" + hour + ":00-00:00"));
}

export function isHour(hour) {
  const timeFormat = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;
  return timeFormat.test(hour);
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

/**
 * Formatea las horas y minutos con dos dígitos separados por ":"
 * 
 * @param {String} time con horas y minutos separados por ":""
 * @returns horas y minutos en formato hh:mm
 */
export const formatTime = (time) => {
  const hh = time.split(TIME_PART_SEPARATOR)[0].padStart(2, '0');
  const mm = time.split(TIME_PART_SEPARATOR)[1].padStart(2, '0');
  return hh + TIME_PART_SEPARATOR + mm;
};

/**
 * Comprueba si una fecha está en un determinado rango de fechas.
 * 
 * @param {Date} date fecha a comprobar si está en el rango
 * @param {Date} lowerLimit límite inferior del rango de fechas
 * @param {Date} upperLimit límite superior del rango de fechas
 * @returns {Boolean} con el resultado de la comprobación
 */
export function isDateInRange(date, lowerLimit, upperLimit) {
  return date.toDateString() >= lowerLimit.toDateString() && date.toDateString() <= upperLimit.toDateString();
}

/**
 * Función onvierte un ojeto Date a un String en formato yyyy-mm-dd
 * 
 * @param {Date} date 
 * @returns  {String}
 */
export function dateToString(date) {
  const offset = date.getTimezoneOffset();
  let result = new Date(date.getTime() - (offset * 60 * 1000));
  return result.toISOString().split(DATE_HOUR_SEPARATOR)[0];
}
