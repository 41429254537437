import React, { useState, useEffect } from 'react';
import { Tag } from '@carbon/react';
import { Checkmark, Error, CheckmarkOutline, CheckmarkOutlineWarning, Save } from '@carbon/react/icons';

import { OBSERVATION_STATUS_DEFAULT } from 'api/constants/ObservationStatus';

function StatusTag({ status }) {
  const icons = {
    default: { type: 'gray', title: 'No guardado', text: 'No guardado', renderIcon: Save },
    registered: { type: 'outline', title: 'Guardado', text: 'Guardado', renderIcon: CheckmarkOutlineWarning },
    preliminary: { type: 'blue', title: 'Guardado', text: 'Guardado', renderIcon: Checkmark },
    error: { type: 'red', title: 'Error guardando', text: 'Error guardando', renderIcon: Error },
    completed: { type: 'green', title: 'Verificado', text: 'Verificado', renderIcon: CheckmarkOutline },
    final: { type: 'green', title: 'Verificado', text: 'Verificado', renderIcon: CheckmarkOutline }
  };
  const [currentStatus, setCurrentStatus] = useState(OBSERVATION_STATUS_DEFAULT);
  const [tagProps, setTagProps] = useState(icons[OBSERVATION_STATUS_DEFAULT]);

  useEffect(() => {
    if (status) {
      setCurrentStatus(status);
    }
  }, [status]);

  useEffect(() => {
    setTagProps(icons[currentStatus || OBSERVATION_STATUS_DEFAULT]
      || icons[OBSERVATION_STATUS_DEFAULT]);
  }, [currentStatus]);

  return (
    <div className='tag'>
      <Tag type={tagProps.type} renderIcon={tagProps.renderIcon} title={tagProps.title}>
        {tagProps.text}
      </Tag>
    </div>
  );
}

export default StatusTag;