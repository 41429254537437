import { useEffect, useRef, useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableRow,
  TextArea,
  TableBody,
  Accordion,
  AccordionItem,
  TableCell,
  Breadcrumb,
  BreadcrumbItem,
  ButtonSet,
  Tile,
  SkeletonText,
} from "@carbon/react";
import { ArrowRight, ArrowLeft, Save } from "@carbon/react/icons";
import useNotification from "hooks/useNotification";
import GenericObservationsComponent from "components/Observation/GenericObservationsComponent";
import {
  saveObservations,
  findByEncounterAndObservationMomentTag,
} from "components/Observation/ObservationService";
import {
  getTagsBySystem,
  buildTagFromCodeAndSystem,
  getTimeDurationFromActivityDefinition,
} from "api/constants/tags/TagFunctions";
import {
  CODE_OBSERVATION_MOMENT_AFTER,
  CODE_OBSERVATION_MOMENT_BEFORE,
  CODE_OBSERVATION_MOMENT_DURING,
  SYSTEM_OBSERVATION_MOMENT,
} from "api/constants/tags/ObservationTags";
import ActivityDefinition from "api/ActivityDefinition";
import "serviceRequest/_ServiceRequestComponent.scss";
import ServiceRequestMomentViewer from "./ServiceRequestMomentViewer";
import Overlay from "layout/overlay/Overlay";
import { Link } from "react-router-dom";
import { toISOString } from "common/DateTimeUtils";
import Encounter from "api/Encounter";
import PatientDataComponent from "patients/PatientDataComponent";
import useBluetooth from "hooks/bluetooth/useBluetooth";
import HeartRateService, {
  HRV_CONNECTION_PARAMS,
} from "api/bluetooth/HeartRateService";
import BluetoothControlBar from "components/BluetoothControlBar/BluetoothControlBar";
import ConfirmAction from "components/ConfirmAction/ConfirmAction";

const INITIAL_RUN_STATE = {
  started: false,
  finished: false,
};

export default function ServiceRequestComponent({
  carePlan,
  serviceRequest,
  encounter,
  patient,
  practitionerRole,
  onSubmit = () => {},
  onClose = () => {},
  ...rest
}) {
  const [loading, setLoading] = useState(true);
  const [observationMoment, setObservationMoment] = useState();
  const [observationMoments, setObservationMoments] = useState();
  const [runState, setRunState] = useState(INITIAL_RUN_STATE);
  const [activityDefinition, setActivityDefinition] = useState();
  const [observationsDefinition, setObservationsDefinition] = useState();
  const [observations, setObservations] = useState();
  const [observationsObject, setObservationsObject] = useState();
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const { bluetoothState: bluetoothStateHrv, connect: connectHrv } =
    useBluetooth(
      HRV_CONNECTION_PARAMS,
      runState.started,
      HeartRateService.dataParser
    );
  const notification = useNotification();
  const [showOverlay, setShowOverlay] = useState(true);
  const notesTextArea = useRef();

  /**
   * Función que busca las observaciones almacenadas en FHIR a partir de los parámetros de
   * entrada. Almacena los resultados en el state "observations".
   */
  const retrieveObservations = () => {
    if (!encounter || !observationsDefinition?.[0]) {
      return;
    }
    const encounterMoment = buildTagFromCodeAndSystem(observationMoment, SYSTEM_OBSERVATION_MOMENT);
    findByEncounterAndObservationMomentTag( encounter, encounterMoment ? [encounterMoment] : encounterMoment)
      .then(data => {
        const retrievedObservations = new Map(data.map(item => [item.resource.id, item.resource]));
        setObservations(retrievedObservations);
      })
      .catch(e => {
        console.log(e);
        notification.error({
          title: "Error cargando los datos de registrados",
          caption: `No ha sido posible cargar los datos de ${observationsDefinition?.code?.text}. Contacte con el administrador.`,
        });
        setObservations(new Map());
      });
  };

  const closeWindow = () => {
    setShowOverlay(false);
    onClose();
  };

  useEffect(() => {
    setShowOverlay(true);
  }, []);

  useEffect( () => {
    if (serviceRequest) {
      ActivityDefinition.findById(serviceRequest.instantiatesCanonical[0].replace("ActivityDefinition/",""))
      .then(data => setActivityDefinition(data))
      .catch(e =>
        notification.error({
          title: "Error cargando la descripción de la actividad",
          caption:
            "No ha sido posible cargar la definición de la actividad. Contacte con el administrador. " + e.message
        }));
    }
  },
  //eslint-disable-next-line react-hooks/exhaustive-deps
  [serviceRequest]);

  useEffect(() => {    
    if (activityDefinition) {
      notesTextArea.current.value = getNotes(encounter, patient).note[0].text;

      const moments = [];
      const momentsOrderedByTime = [];
      const existingMoments = [ CODE_OBSERVATION_MOMENT_BEFORE, CODE_OBSERVATION_MOMENT_DURING, CODE_OBSERVATION_MOMENT_AFTER ]; // TODO: pouco flexible
      
      activityDefinition.contained.forEach(observation => {
        const moment = getTagsBySystem(observation.meta, SYSTEM_OBSERVATION_MOMENT)[0]?.code;
        if (!moments.includes(moment)) {
          moments.push(moment);
        }
      });
      // TODO: redundante e NON ordenados
      existingMoments.forEach((moment) => {
        if (moments.includes(moment)) {
          momentsOrderedByTime.push(moment);
        }
      });
      setObservationMoments(momentsOrderedByTime);
    }
  }, [activityDefinition]);

  useEffect(() => {
    if (observationMoments) {
      setObservationMoment(observationMoments[0] || null);
    }
  }, [observationMoments]);

  useEffect(() => {
    if (activityDefinition) {
      const observationsDefinitionInMoment =
        ActivityDefinition.filterAndSortObservationDefinitionsByMoment(
          activityDefinition.contained, observationMoment);
      setObservationsDefinition(observationsDefinitionInMoment);
    }
  }, [activityDefinition, observationMoment]);

  useEffect(() => {
    if (observationsDefinition) {
      retrieveObservations();
    }
  }, [observationsDefinition]);

  useEffect(() => {
    let observationObjects = [];

    if (observations && observationsDefinition) {
      observationsDefinition
        .sort((a, b) => a.id.localeCompare(b.id))
        .forEach(observationDefinition =>
          observationObjects.push({
            observationDefinition: observationDefinition,
            observations: Array.from(observations.values()).filter(item =>
                item.code.coding[0].code === observationDefinition.code.coding[0].code
            )
          })
        );
    }
    setObservationsObject(observationObjects);
  }, [observations]);

  useEffect(() => {
    if (observationsObject?.length) {
      setLoading(false);
    }
  }, [observationsObject]);

  const updateObservationMoment = (observationMoment) => {
    setLoading(true);

    handleSave()
      .then(() => setObservationMoment(observationMoment))
      .catch(() => notification.error(
        {
          title: "Error guardando los datos",
          caption: "Ha ocurrido un error guardando los datos. Pruebe a guardar de nuevo o contacte con el soporte técnico.",
        }
      ))
      .finally(() => setLoading(false));
  };

  const updateEncounter = async (close) => {
    if (close) {
      encounter.status = "finished";
      encounter.period.end = toISOString(new Date());
    }

    addNotes(notesTextArea.current.value, encounter, patient);
    await Encounter.updateEncounter(encounter);
  };

  const handleSave = async (close) => {
    const observationsAsList = Array.from(observations.values());
    updateEncounter(close);

    return await saveObservations(observationsAsList);
  };

    /**
   * Actualiza la lista de Observations del momento que se está visualizando con los valores 
   * de las Observations actualizadas.
   * 
   * @param {*} updatedObservations
   */
    const handleUpdatedObservations = (updatedObservationsFromChild) => {
      if (updatedObservationsFromChild) {
        let updateableObservations = new Map(observations);
        updatedObservationsFromChild.forEach(item => updateableObservations.set(item.id, item));
        setObservations(updateableObservations);
      }
    };

  const saveAndCloseWindow = () => {
    handleSave(true).then(() => {
      notification.success({
        title: "Operación realizada",
        caption: "Prueba guardada correctamente",
      });
      setShowOverlay(false);
      onSubmit();
    });
  };

  const propsConfirmSave = {
    text: "¿Desea guardar los cambios?",
    onSubmit: () => {
      saveAndCloseWindow();
      setShowConfirmSave(false);
    },
    onClose: () => setShowConfirmSave(false),
  };

  const propsConfirmCancel = {
    text: "¿Desea volver a la agenda del paciente?",
    onSubmit: () => {
      closeWindow();
      setShowConfirmCancel(false);
    },
    onClose: () => setShowConfirmCancel(false),
  };

  return (
    <>
      {
        activityDefinition && showOverlay && (
        <Overlay>
          <div className="heading">
            <Breadcrumb noTrailingSlash>
              <BreadcrumbItem>
                <Link to="/patients">Pacientes</Link>
              </BreadcrumbItem>
              <BreadcrumbItem onClick={() => {closeWindow();}}>Detalle de paciente</BreadcrumbItem>
              <BreadcrumbItem href="#" isCurrentPage>{activityDefinition.title}</BreadcrumbItem>
            </Breadcrumb>
          </div>

          <Tile className="patient-data-component">
            <PatientDataComponent />
          </Tile>

          <TableContainer
            className="coperia--data-table-container"
            title={activityDefinition ? activityDefinition.title : "TEXT"}
            description={activityDefinition ? activityDefinition.description : "Descripción de la prueba" }
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div className="servicerequest-component-user-notes">
                      <Accordion>
                        <AccordionItem title="Notas">
                          <TextArea labelText=" " ref={notesTextArea} className="coperia--note"/>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            { 
              observationMoments && 
                <ServiceRequestMomentViewer observationMoments={observationMoments} 
                    observationMoment={observationMoment} onChange={updateObservationMoment} />
            }
          </TableContainer>
          { 
            activityDefinition && <BluetoothControlBar
              duration={ getTimeDurationFromActivityDefinition( activityDefinition, observationMoment )}
              observationsDefinition={observationsDefinition}
              bluetoothStateHrv={bluetoothStateHrv}
              connectHrv={connectHrv}
              setRunState={setRunState}
              runState={runState} />
          }
          <div className="servicerequest-component">
            <div className="servicerequest-component--body">
              <div className="coperia--observation-content-wrapper">
                { 
                  !loading && observationsObject ?
                    observationsObject.map(singleObservationObject => 
                      <GenericObservationsComponent
                        key={ singleObservationObject.observationDefinition.id }
                        observationDefinition={ singleObservationObject.observationDefinition }
                        observations={ singleObservationObject.observations }
                        carePlan={ carePlan }
                        serviceRequest={ serviceRequest }
                        encounter={ encounter }
                        patient={ patient }
                        practitionerRole={ practitionerRole }
                        handleChangeObservations={ handleUpdatedObservations }
                        runState={ runState }
                        bluetoothStateHrv={ bluetoothStateHrv }
                        { ...rest }
                      />
                    )
                  : <SkeletonText></SkeletonText>
                }
              </div>
            </div>
          </div>
          <div className="servicerequest-component-button-footer">
            <ButtonSet>
              <Button kind="secondary" onClick={() => setShowConfirmCancel(true)} >Volver</Button>
              {
                observationMoments?.length && observationMoments.at(0) !== observationMoment ?
                  <Button onClick={() => updateObservationMoment( observationMoments[ observationMoments.indexOf(observationMoment) - 1 ])} renderIcon={ArrowLeft} kind="tertiary" >Anterior</Button> 
                : <></>
              }
              {
                observationMoments?.length && observationMoments.at(-1) !== observationMoment ?
                  <Button onClick={ () => updateObservationMoment(observationMoments[observationMoments.indexOf(observationMoment) + 1]) }
                    renderIcon={ArrowRight} >Siguiente</Button>
                : <Button renderIcon={Save} onClick={() => setShowConfirmSave(true)} >Guardar</Button>
              }
              {
                showConfirmSave && <ConfirmAction props={propsConfirmSave} />
              }
              {
                showConfirmCancel && <ConfirmAction props={propsConfirmCancel} />
              }
            </ButtonSet>
          </div>
        </Overlay>
      )}
    </>
  );
}

function getNotes(encounter, patient) {
  const clinicalImpression =
    encounter.contained &&
    encounter.contained.length > 0 &&
    encounter.contained.filter(c => c.resourceType === "ClinicalImpression");
  if (clinicalImpression && clinicalImpression.length > 0) {
    return clinicalImpression[0];
  } else {
    const now = new Date();
    return {
      resourceType: "ClinicalImpression",
      status: "completed",
      effectiveDateTime: toISOString(now),
      subject: {
        reference: `Patient/${patient.id}`,
      },
      encounter: {
        reference: `Encounter/${encounter.id}`,
      },
      note: [
        {
          time: toISOString(now),
          text: "",
        },
      ],
    };
  }
}

function addNotes(notesTextArea, encounter, patient) {
  if (notesTextArea) {
    const clinicalImpression = getNotes(encounter, patient);
    clinicalImpression.note[0].time = toISOString(new Date());
    clinicalImpression.note[0].text = notesTextArea;
    if (encounter.contained && encounter.contained.length > 0) {
      const otherContainedResources = encounter.contained.filter(
        (c) => c.resourceType !== "ClinicalImpression"
      );
      encounter.contained = otherContainedResources.concat(clinicalImpression);
    } else {
      encounter.contained = [clinicalImpression];
    }
  }
}
