import { Button } from "@carbon/react";
import LaunchManager from "components/Launchers/LaunchManager";
import { TrashCan } from '@carbon/icons-react';
import { useState } from "react";
import ConfirmAction from "components/ConfirmAction/ConfirmAction";
import { useEffect } from "react";
import { findEncounterById } from "components/Encounter/EncounterService";
import { findQuestionnaireResponseById } from "components/QuestionnaireResponse/QuestionnaireResponseService";


export default function ActivityDetailComponent({ activity, patient, carePlan, practitionerRole, outcome, handleDeleteActivity }) {
  // console.log(activity)
  // console.log(patient)
  // console.log(carePlan)
  // console.log(practitionerRole)
  // console.log(outcome)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);

  const propsConfirmDelete = {
    text: "¿Desea eliminar el registro de la actividad?",
    onSubmit: () => {
      handleDeleteActivity(activity.type, carePlan, activity.referenceUuid, outcome);
      setShowConfirmDelete(false);
    },
    onClose: () => setShowConfirmDelete(false)
  };

  useEffect(() => {
    if(activity.type === "Questionnaire") {
      findQuestionnaireResponseById(outcome.reference.replace('QuestionnaireResponse/', '')).then(response => {
        response.status === "entered-in-error" && setDisableDeleteButton(true)
      })
    }

    if(activity.type === "ServiceRequest") {
      findEncounterById(outcome.reference.replace('Encounter/', '')).then(response => {
        response.status === "cancelled" && setDisableDeleteButton(true)
      })
    }
  }, [activity, outcome])
  

  return (
    <>
      <li className='activity-outcome-row' key={`${activity.referenceUuid}-activity-outcome:${outcome.reference}`}>
        <Button disabled={disableDeleteButton} kind="ghost" hasIconOnly renderIcon={TrashCan} iconDescription="Borrar registro de actividad"
          className="delete-button" title="Borrar registro de actividad"
          onClick={() => setShowConfirmDelete(true)} />
        <LaunchManager id={`launcher:${activity?.reference}:${outcome?.reference}`}
          activity={activity} patient={patient} carePlan={carePlan}
          practitionerRole={practitionerRole}
          outcomeReference={outcome}
        />
      </li>
      {showConfirmDelete && <ConfirmAction props={propsConfirmDelete} />}
    </>
  );
}