export const SYSTEM_OBSERVATION_MOMENT = "http://coperia.es/fhir/observation-moment";
export const CODE_OBSERVATION_MOMENT_BEFORE = "before";
export const CODE_OBSERVATION_MOMENT_DURING = "during";
export const CODE_OBSERVATION_MOMENT_AFTER = "after";

/* LOINC codes for variables */
export const CODE_OBSERVATION_HEART_RATE_VARIABILITY = "80404-7";
export const CODE_OBSERVATION_HEART_RATE_PULSE = "8867-4";
export const CODE_OBSERVATION_PULSE_OXIMETER_SATURATION = "59408-5";
export const CODE_OBSERVATION_PULSE_OXIMETER_SATURATION_MIN = "59408-5-min";
export const CODE_OBSERVATION_PULSE_OXIMETER_SATURATION_MAX = "59408-5-max";

// SNOMED code for method
export const CODE_MONITORING_CARDIAC_OUTPUT = "3130004";
export const CODE_PULSE_OXIMETRY = "708065004";
export const CODE_GALVANIC_SKIN_RESPONSE = "1453000";
export const CODE_RECORD_VOICE = "258108004";

export const SYSTEM_OBSERVATION_OCCURRENCE = "http://coperia.es/fhir/observation-occurrence";