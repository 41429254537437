import React, { useState, useEffect } from 'react';
import QuestionnaireComponent from 'questionnaire';
import Questionnaire from 'api/Questionnaire';
import useNotification from 'hooks/useNotification';
import QuestionnaireResponse from 'api/QuestionnaireResponse';


export default function CommunicationQuestionnaireComponent({ communicationQuestionnaireElement, patient, practitionerRole, carePlan }) {
  const notification = useNotification();
  const [questionnaire, setQuestionnaire] = useState(null);
  const [questionnaireResponse, setQuestionnaireResponse] = useState(null);


  useEffect(() => {
    searchQuestionnaireResponse();
  }, [communicationQuestionnaireElement]);

  useEffect(() => {
    if (questionnaireResponse) {
      searchQuestionnaire();
    }
  }, [questionnaireResponse]);

  const onClose = () => {
    setQuestionnaire(null);
    setQuestionnaireResponse(null);
  };


  const contextData = {
    patient: patient,
    practitionerRole: practitionerRole,
    carePlan: carePlan
  };

  const searchQuestionnaire = () => {
    if (questionnaireResponse?.questionnaire) {
      Questionnaire.getQuestionnaire(questionnaireResponse.questionnaire.replace('Questionnaire/', ''))
        .then(questionnaireFound => {
          setQuestionnaire(questionnaireFound);
        }).catch(e => {
          console.log("Error cargando Questionnaire ", e);
          notification.error({
            title: "Error cargando los cuestionarios",
            caption: "No ha sido posible cargar los datos de los cuestionarios. Contacte con el administrador."
          });
        });
    }
  };

  const searchQuestionnaireResponse = () => {
    QuestionnaireResponse.getQuestionnaireResponse(communicationQuestionnaireElement?.reference.replace('QuestionnaireResponse/', ''))
      .then(questionnaireResponseFound => {
        setQuestionnaireResponse(questionnaireResponseFound);
      }).catch(e => {
        console.log("Error cargando Questionnaire Response", e);
        notification.error({
          title: "Error cargando los cuestionarios",
          caption: "No ha sido posible cargar los datos de los cuestionarios. Contacte con el administrador."
        });
      });

  };

  return (
    <>
      {questionnaire &&
        <QuestionnaireComponent
          questionnaire={questionnaire}
          questionnaireResponse={questionnaireResponse}
          contextData={contextData}
          readOnly={true}
          onClose={onClose}
          onSubmit={onClose}
        />
      }
    </>
  );


}