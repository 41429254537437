import { Modal } from '@carbon/react';
import AuthStore from 'auth/AuthStore';
import React, { useState } from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import ChatVisualizer from './ChatVisualizer';
import { findUser, getChatRoom, loginApi } from './RocketChatService';

export default function ChatComponent({ username, onClose, notificationError }) {
  const [rocketChatAuthenticationHeaders, setRocketChatAuthenticationHeaders] = useState();
  const [chatRoom, setChatRoom] = useState();

  useEffect(() => {
    loginRocketChatApi();
  }, []);

  useEffect(() => {
    if (rocketChatAuthenticationHeaders) {
      loadChatRoom();
    }
  }, [rocketChatAuthenticationHeaders]);

  const loadChatRoom = async () => {
    try {
      const existUser = await findUser(username, rocketChatAuthenticationHeaders);
      if (existUser) {
        const room = await getChatRoom(username, rocketChatAuthenticationHeaders);
        setChatRoom(room);
      } else {
        notificationError("Operación NO realizada", "El usuario seleccionado aún no ha iniciado sesión.");
        onClose();
      }
    } catch (error) {
      notificationError("Operación NO realizada", "No se ha podido abrir el chat. Consulte al administrador.");
    }

  };

  const loginRocketChatApi = () => {
    loginApi()
      .then(response => {
        //TODO Mover a un api commons
        const authHeaders = {
          headers: {
            'X-Auth-Token': `${response.data.authToken}`,
            'X-User-Id': `${response.data.userId}`
          }
        };
        setRocketChatAuthenticationHeaders(authHeaders);
      }).catch(function (error) {
        notificationError("Operación NO realizada", "Se han realizado demasiadas conexiones consecutivas. Espere un momento y vuelva a intentarlo.");
        onClose();
      });;
  };

  return (
    <>
      {
        typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <Modal
              open
              primaryButtonText="Cerrar"
              onRequestSubmit={onClose}
              onRequestClose={onClose}
              preventCloseOnClickOutside
              size="lg">
              {chatRoom && <ChatVisualizer room={chatRoom}></ChatVisualizer>}
            </Modal>
            , document.body
          )
      }
    </>
  );
}