import React from 'react'

export default function Analytics() {
  const supersetUrl = process.env.REACT_APP_SUPERSET_DASHBOARD_URL

  return (
    <div className="supserset-dashboard" style={{display:'flex'}}>
      <iframe style={{flexGrow:'1', minHeight:'100vh'}} title="Dashboard" src={supersetUrl} />
    </div>
  )
}
