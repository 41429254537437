import { View } from '@carbon/react/icons';
import { Link } from '@carbon/react';
import React, { useState, useEffect } from 'react';
import { findCommunicationById } from 'components/Communication/CommunicationService';

import useNotification from 'hooks/useNotification';
import 'components/Launchers/CommunicationLauncher.scss';
import CommunicationComponent from 'components/Communication/CommunicationComponent';

/**
 * Componente que permite visualizar una comunicación.
 * @param {*} activity Activity del tipo Communication que contiene la información a mostrar.
 * @param {*} patient
 * @param {*} carePlan
 * @param {*} practitionerRole
 * @returns 
 */
export default function CommunicationLauncher({ activity, patient, practitionerRole, carePlan }) {
  const notification = useNotification();
  const [communication, setCommunication] = useState(null);

  useEffect(() => {
    if (communication) {
      searchCommunication();
    }
  }, []);

  const onClose = () => {
    setCommunication(null);
  };


  const searchCommunication = () => {
    findCommunicationById(activity?.referenceUuid).then(communicationFound => {
      setCommunication(communicationFound);
    }).catch(e => {
      console.log("Error cargando Comunicación del paciente", e);
      notification.error({
        title: "Error cargando los datos de la comunicación",
        caption: "No ha sido posible cargar los datos de la comunicación. Contacte con el administrador."
      });
    });
  };


  return (
    <>
      {
        <Link className='questionnaire-link coperia-clickable' onClick={searchCommunication} renderIcon={View} >
          Ver detalles</Link>
      }

      {communication &&
        <CommunicationComponent communication={communication} defaultPatient={patient} practitionerRole={practitionerRole} onClose={onClose} />
      }
    </>
  );
} 