import React, { useState, useEffect } from 'react';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  MultiSelect,
  TextInput,
  Select,
  SelectItem
} from '@carbon/react';
import { Add, Search, Erase } from '@carbon/react/icons';

import { getGender, GENDERS } from 'common/PatientUtils';
import { SYSTEM_ASSAY } from 'api/constants/tags/AssayTags';
import { getTagCodesBySystem } from 'api/constants/tags/TagFunctions';

import { useAuth } from 'auth/Auth';
import { ALIAS_SORTING_ASC, ALIAS_SORTING_DESC, DEFAULT_SORTING, IDENTIFIER_SORTING_ASC, IDENTIFIER_SORTING_DESC } from './PatientSortingFields';

export default function PatientsTable({ rows, headers, defaultSearchValues, onAdd, onEdit, onSearch }) {

  const NONE = "NONE";
  const ASC = "ASC";
  const DESC = "DESC";

  const [listOfAssays, setListOfAssays] = useState(defaultSearchValues.assays ? defaultSearchValues.assays : []);
  const [searchField, setSearchField] = useState(defaultSearchValues.field);
  const [searchTerm, setSearchTerm] = useState(defaultSearchValues.term || '');
  const [searchBirthDate, setSearchBirthDate] = useState(defaultSearchValues.birthDate || '');
  const [searchGenders, setSearchGenders] = useState(defaultSearchValues.genders || []);
  const [searchAssays, setSearchAssays] = useState(defaultSearchValues.assays || []);
  const [searchValues, setSearchValues] = useState(defaultSearchValues);
  const [sortingIdentifier, setSortingIdentifier] = useState(NONE);
  const [sortingAlias, setSortingAlias] = useState(NONE);

  const auth = useAuth();
  const searchSorting = defaultSearchValues.sort || DEFAULT_SORTING;

  const generateSearchValues = (key, value) => {
    let tempSearchValues = {
      field: searchField,
      term: searchTerm,
      birthDate: searchBirthDate,
      genders: searchGenders,
      assays: searchAssays,
      sort: searchSorting
    };

    if (key) {
      tempSearchValues[key] = value;
    }

    setSearchValues(tempSearchValues);
  };

  const cleanSearchValues = () => {
    let tempSearchValues = {
      field: searchField,
      term: '',
      birthDate: '',
      genders: [],
      assays: []
    };

    setSearchValues(tempSearchValues);
  };

  const handleClearSearchTerm = () => {
    generateSearchValues('term', '');
  };

  const handleChangeSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChangeBirthDate = (e) => {
    setSearchBirthDate(e.target.value);
  };

  const handleChangeAssaySelection = (values) => {
    setSearchAssays(values.selectedItems);
  };

  const handleChangeGendersSelection = (values) => {
    setSearchGenders(values.selectedItems);
  };

  const handleChangeFieldSearch = (e) => {
    setSearchField(e.target.value);
  };

  const getAssaysFromPractitioner = () => {
    let assaysFromPractitionerOrNone = ['-'];
    if (auth.practitionerRole && auth.practitionerRole.meta) {
      assaysFromPractitionerOrNone = getTagCodesBySystem(auth.practitionerRole.meta, [SYSTEM_ASSAY]);
    }
    return assaysFromPractitionerOrNone;
  };

  const onHeaderClick = (status, ascSorting, descSorting) => {
    if (status === NONE) {
      generateSearchValues("sort", descSorting);
    }

    if (status === DESC) {
      generateSearchValues("sort", ascSorting);
    }

    if (status === ASC) {
      generateSearchValues("sort", DEFAULT_SORTING);
    }
  };

  //TODO: La funcionalidad de la ordenación de la lista se implementó correctamente pero se podría refactorizar para hacerla más dinámica
  useEffect(() => {
    switch (defaultSearchValues?.sort) {
      case IDENTIFIER_SORTING_ASC:
        setSortingIdentifier(ASC);
        break;
      case IDENTIFIER_SORTING_DESC:
        setSortingIdentifier(DESC);
        break;
      case ALIAS_SORTING_ASC:
        setSortingAlias(ASC);
        break;
      case ALIAS_SORTING_DESC:
        setSortingAlias(DESC);
        break;
      default:
    }
  }, [defaultSearchValues]);

  /**
   * Carga los ensayos a los que tiene acceso el facultativo.
   */
  useEffect(() => {
    const practitionerAssays = getAssaysFromPractitioner().map(item => { return { id: item }; });
    setListOfAssays(practitionerAssays);
  }, []);

  /**
 * Carga los ensayos a los que tiene acceso el facultativo.
 */
  useEffect(() => {
    if (searchValues) {
      onSearch(searchValues);
    }
  }, [searchValues]);

  return (
    <DataTable
      rows={rows}
      headers={headers}
      render={({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
      }) => (
        <TableContainer
          className="coperia--data-table-container"
          title="Pacientes"
          description="Listado de pacientes registrados en los ensayos de COPERIA. Introduzca los parámetros de búsqueda y pulse Enter o el botón de búsqueda.">
          <TableToolbar className="coperia--table-toolbar">
            <TableToolbarContent className="coperia--table-toolbar-content">
              <Select defaultValue={searchField}
                className="coperia--select--wrapper"
                helperText=""
                id="que-buscar"
                labelText=""
                onChange={handleChangeFieldSearch}
              >
                <SelectItem text="Alias del paciente" value="name" />
                <SelectItem text="Código de paciente" value="identifier" />
              </Select>
              <TableToolbarSearch
                className="toolbar-search-input"
                placeholder='Escriba el alias del paciente'
                persistent={true}
                tabIndex={-1}
                value={searchTerm}
                onClear={handleClearSearchTerm}
                onChange={handleChangeSearchTerm}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    generateSearchValues();
                  }
                }}
              />
              <TextInput
                className="birthdate-input"
                id="birthDate-input"
                type="number"
                labelText=""
                placeholder="Año de nacimiento"
                onChange={handleChangeBirthDate}
                value={searchBirthDate}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    generateSearchValues();
                  }
                }}
              />
              <MultiSelect id="genero"
                className="coperia--select--wrapper"
                items={GENDERS}
                itemToString={item => { return item ? getGender(item) : '-'; }}
                selectedItems={searchGenders}
                onChange={handleChangeGendersSelection}
                label="Género"
                selectionFeedback="top-after-reopen"
              />
              <MultiSelect id="ensayo"
                className="coperia--select--wrapper"
                items={listOfAssays}
                itemToString={item => { return item ? item.id : '-'; }}
                selectedItems={searchAssays}
                onChange={handleChangeAssaySelection}
                label="Ensayo"
                selectionFeedback="top-after-reopen"
              />
              <Button hasIconOnly kind="ghost" renderIcon={Erase} iconDescription="Borrar filtros" onClick={() => cleanSearchValues()} />
              <Button hasIconOnly kind="ghost" renderIcon={Search} iconDescription="Buscar" onClick={() => generateSearchValues()} />
              <Button onClick={onAdd} renderIcon={Add}>Inscribir paciente</Button>
            </TableToolbarContent>
          </TableToolbar>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map(header => {
                  let props = getHeaderProps({ header });
                  if (header.key === "identifier") {
                    props.isSortable = true;
                    props.isSortHeader = true;
                    props.sortDirection = sortingIdentifier;
                    props.onClick = (() => onHeaderClick(sortingIdentifier, IDENTIFIER_SORTING_ASC, IDENTIFIER_SORTING_DESC));
                  }

                  if (header.key === "alias") {
                    props.isSortable = true;
                    props.isSortHeader = true;
                    props.sortDirection = sortingAlias;
                    props.onClick = (() => onHeaderClick(sortingAlias, ALIAS_SORTING_ASC, ALIAS_SORTING_DESC));
                  }
                  return (<TableHeader {...props} >
                    {header.header}
                  </TableHeader>);
                }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow {...getRowProps({ row })} key={row.id} onClick={() => onEdit(row.id)} className='coperia-clickable'>
                  <>
                    <TableCell key={row.cells[0].id}>{row.cells[0].value}</TableCell>
                    <TableCell key={row.cells[1].id}>{row.cells[1].value}</TableCell>
                    <TableCell key={row.cells[2].id}>{row.cells[2].value}</TableCell>
                    <TableCell key={row.cells[3].id}>{row.cells[3].value}</TableCell>
                    <TableCell key={row.cells[4].id}>
                      {
                        row.cells[4].value && row.cells[4].value !== 'NA' ? row.cells[4].value.reduce(
                          (previousValue, currentValue) => previousValue + ', ' + currentValue
                        ) : ''
                      }
                    </TableCell>
                  </>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    />
  );
}
