import ReactDOM from 'react-dom';
import { DatePicker, DatePickerInput, Modal, Select, SelectItem, SelectItemGroup, TimePicker } from '@carbon/react';
import { useState } from 'react';
import 'agenda/_agenda.scss';
import { addHoursToDate, isHour, isValidDate, toDate } from 'common/DateTimeUtils';
import ConfirmAction from 'components/ConfirmAction/ConfirmAction';

export default function ActivityPlanner({ questionnaires, activities, preselectedActivity, selectedDate, selectedTime, handleUpdateCarePlan = () => { }, onClose = () => { } }) {
  const [activityDate, setActivityDate] = useState(selectedDate ? toDate(selectedDate) : new Date());
  const [activityTime, setActivityTime] = useState(selectedTime ? selectedTime : "00:00");
  const [selectedActivity, setSelectedActivity] = useState(preselectedActivity);

  const [validActivity, setValidActivity] = useState(true);
  const [validDate, setValidDate] = useState(true);
  const [validTime, setValidTime] = useState(true);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);

  const handleChangeActivitySelection = (e) => {
    if (e.target.value) {
      setSelectedActivity(JSON.parse(e.target.value));
    } else {
      setSelectedActivity();
    }
  };

  const validateFields = () => {
    let validFields = true;

    if (selectedActivity) {
      setValidActivity(true);
    } else {
      setValidActivity(false);
      validFields = false;
    }

    if (isValidDate(activityDate)) {
      setValidDate(true);
    } else {
      setValidDate(false);
      validFields = false;
    }

    if (isHour(activityTime)) {
      setValidTime(true);
    } else {
      setValidTime(false);
      validFields = false;
    }

    return validFields;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      const isQuestionnaire = selectedActivity ? selectedActivity.resourceType === "Questionnaire" : false;
      handleUpdateCarePlan(selectedActivity, isQuestionnaire, addHoursToDate(activityDate, activityTime));
    }
    onClose();
  };

  const propsConfirmSave = {
    text: preselectedActivity ? "¿Desea replanificar la actividad?" : "¿Desea crear una nueva actividad?",
    onSubmit: () => {
      handleSubmit();
      setShowConfirmSave(false);
    },
    onClose: () => setShowConfirmSave(false)
  };

  const propsConfirmCancel = {
    text: "¿Desea cerrar la planificación de actividades?",
    onSubmit: () => {
      onClose();
      setShowConfirmCancel(false);
    },
    onClose: () => setShowConfirmCancel(false)
  };

  return (
    <>
      {
        typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <Modal
              preventCloseOnClickOutside
              open
              onRequestSubmit={() => setShowConfirmSave(true)}
              onRequestClose={() => setShowConfirmCancel(true)}
              size="lg"
              modalHeading={preselectedActivity ? "Modificar registro de actividad" : "Crear nuevo registro de actividad"}
              primaryButtonText="Guardar"
              secondaryButtonText="Cancelar"
            >
              <div className="css-grid">
                <div className="css-grid-col-10 css-grid-col-condensed">
                  {preselectedActivity ?
                    <ModifyActivityDropdown preselectedActivity={selectedActivity} /> :
                    <Select
                      id="select-activity"
                      invalidText="Seleccione una opción válida"
                      invalid={!validActivity}
                      labelText="Actividades"
                      onChange={handleChangeActivitySelection}>
                      <SelectItem text="Actividades" />
                      <SelectItemGroup label="Cuestionarios">
                        {
                          questionnaires && questionnaires.map((q, index) => {
                            return <SelectItem
                              key={q.title + index}
                              text={q.title}
                              value={JSON.stringify(q, null, 2)}
                            />;
                          })
                        }
                      </SelectItemGroup>
                      <SelectItemGroup label="Actividades">
                        {
                          activities && activities.map((a, index) => {
                            return <SelectItem
                              key={a.resource.title + index}
                              text={a.resource.title}
                              value={JSON.stringify(a.resource, null, 2)}
                            />;
                          })
                        }
                      </SelectItemGroup>
                    </Select>
                  }
                </div>
                <div className="css-grid-col-2 css-grid-col-condensed">
                  <DatePicker locale={'es'}
                    dateFormat="d/m/Y"
                    datePickerType="single"
                    name="signDate"
                    label="Hora"
                    id="signDate"
                    value={activityDate}
                    onChange={d => {
                      setActivityDate(new Date(d));
                    }}
                  >
                    <DatePickerInput
                      id="signDateInput" autoComplete="off"
                      placeholder="dd/mm/yyyy"
                      invalid={!validDate}
                      invalidText="Seleccione una fecha válida"
                      labelText="Fecha"
                    />
                  </DatePicker>
                </div>
                <div className="css-grid-col-2 css-grid-col-condensed">

                  <TimePicker id="time-picker"
                    value={activityTime}
                    labelText="Hora"
                    invalid={!validTime}
                    invalidText="Seleccione una hora válida"
                    onChange={time => {
                      setActivityTime(time.target.value);
                    }}
                  >
                  </TimePicker>
                </div>
              </div>
            </Modal>
            , document.body
          )
      }
      {showConfirmSave && <ConfirmAction props={propsConfirmSave} />}
      {showConfirmCancel && <ConfirmAction props={propsConfirmCancel} />}
    </>
  );

  function ModifyActivityDropdown({ preselectedActivity }) {
    return (<>
      <Select
        defaultValue="placeholder-item"
        id="select-activity"
        labelText="Actividades"
      >
        <SelectItem text={preselectedActivity.title} />
      </Select>
    </>);
  }
}