import { Button, ButtonSet, Column, Grid, TabPanel, TextInput } from "@carbon/react";
import ConfirmAction from "components/ConfirmAction/ConfirmAction";
import { useState } from "react";

export default function PatientUserManagement({ patientUserInfo, resetPassword, deactivatePatient }) {
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const propsConfirmReset = {
    text: "¿Desea resetear la contraseña?",
    onSubmit: () => {
      resetPassword();
      setShowConfirmReset(false);
    },
    onClose: () => setShowConfirmReset(false)
  };

  const propsConfirmDelete = {
    text: "¿Desea eliminar el paciente?",
    onSubmit: () => {
      deactivatePatient();
      setShowConfirmDelete(false);
    },
    onClose: () => setShowConfirmDelete(false)
  };

  return (
    <>

      {patientUserInfo &&
        <TabPanel>
          <Grid fullWidth>
            <Column lg={6} md={5} sm={4}>
              <TextInput
                id="user"
                name="user"
                labelText="Usuario"
                value={patientUserInfo.user}
              />
            </Column>
          </Grid>
          <Grid fullWidth>
            <Column lg={6} md={5} sm={4}>
              <TextInput.PasswordInput
                id="user"
                name="user"
                labelText="Contraseña"
                placeholder=""
                value={patientUserInfo.password}
              />
            </Column>
          </Grid>
          <Grid fullWidth>
            <Column lg={6} md={5} sm={4}>
              <div className='component-button-footer'>
                <ButtonSet>
                  <Button onClick={() => setShowConfirmReset(true)}>Resetear contraseña</Button>
                  <Button kind="danger" onClick={() => setShowConfirmDelete(true)}>Eliminar paciente</Button>
                </ButtonSet>
              </div>
            </Column>
          </Grid>
        </TabPanel>}
      {showConfirmReset && <ConfirmAction props={propsConfirmReset} />}
      {showConfirmDelete && <ConfirmAction props={propsConfirmDelete} />}
    </>

  );
}