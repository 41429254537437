import React from "react";
import { Checkbox } from "@carbon/react";

import {
  PATIENT_TYPE_SYSTEM,
  PATIENT_TYPE_COVID_CONTROL,
} from "api/constants/tags/PatientTags";
import { useState } from "react";
import { useEffect } from "react";

export default function PatientTypeSelector({ patientTypeTag, onChangePatientType }) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if(patientTypeTag) {
      setChecked(patientTypeTag &&
        patientTypeTag.system === PATIENT_TYPE_SYSTEM &&
        patientTypeTag.code === PATIENT_TYPE_COVID_CONTROL);
    }
  },[patientTypeTag]);

  const onChangePatientTypeSeclector = (event) => {
    onChangePatientType(event.target.checked);
  };

  return (
    <Checkbox
      labelText={`Paciente COVID-control, es decir, ha sufrido COVID y no padece secuelas. Si está, desmarcado indica que el paciente padece COVID-persistente.`}
      id="patientCovidControlType"
      checked={ checked }
      onClick={(event) => onChangePatientTypeSeclector(event)}
    />
  );
}
