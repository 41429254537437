import RocketChat from "api/RocketChat";
import AuthStore from "auth/AuthStore";

export const loginApi = () => {
  const token = AuthStore.getAuthentication()?.accessToken;
  const body = {
    serviceName: "keycloak",
    accessToken: token,
    expiresIn: 200
  }
  return RocketChat.login(body);
};

export const findUser = async (username, rocketChatAuthenticationHeaders ) => {
  const response = await RocketChat.listUsersFilteredByUsername(username, rocketChatAuthenticationHeaders);
  return response.count > 0;
}

export const getChatRoom = async (username, rocketChatAuthenticationHeaders) => {
  const body = {
    usernames: username
  }

  const response = await RocketChat.createDirectMessageRoom(body, rocketChatAuthenticationHeaders);
  return response.room.rid;
}