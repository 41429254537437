import BluetoothApi from "api/bluetooth/BluetoothApi";

export const REDUCER_ACTIONS = {
  PAIR: 'pair',
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  START_NOTIFICATIONS: 'start_notifications',
  STOP_NOTIFICATIONS: 'stop_notifications',
  ADD_DATA: 'add_data'
};

/**
 * 
 * @param {Object} state - Estado del bluetooth compuesto por un objeto como el siguiente:
    {
      device: null - dispositivo bluetooth
      deviceName: '' - nombre del dispositivo
      characteristic - null, características del dispositico, necesarias para subscribirse a notificaciones
      dataToShow: '' - datos que se van a mostrar al usuario
      data: new Map() - mapa clave/valor donde se almacenarán los distintos datos que se capturen en las notificaciones.
    };
 * @param {Object} action - Objeto en el que se reciben la acción a ejecutar y la carga de pago asociada a la acción:
    { 
      type: '' - Una de las acciones disponibles en REDUCER_ACTIONS, 
      payload: {} - Objeto que contendrá la información que modificará el state
    }
 * @returns Estado del bluetooth actualizado o en su defecto el estado sin cambios si ninguna operación coincide con REDUCER_ACTIONS
 */
export default function bluetoothReducer(state, action) {
  const payload = action.payload;
  switch (action.type) {
    case REDUCER_ACTIONS.PAIR:
      return { ...state, loading: payload.loading };
    case REDUCER_ACTIONS.CONNECT:
      return { ...state, device: payload.device, deviceName: payload.device.name, characteristic: payload.characteristic, loading: payload.loading };
    case REDUCER_ACTIONS.DISCONNECT:
      if (payload.manual) {
        console.log(`desconectando device ${state.deviceName}`);
        BluetoothApi.disconnectDevice(state.device);
      }
      return { ...state, device: null, deviceName: '', characteristic: null, dataToShow: '' };
    case REDUCER_ACTIONS.START_NOTIFICATIONS:
      state.characteristic.startNotifications();
      return { ...state, stopTime: null, startTime: new Date().getTime(), data: new Map() };
    case REDUCER_ACTIONS.STOP_NOTIFICATIONS:
      state.characteristic.stopNotifications();
      return { ...state, stopTime: new Date().getTime(), dataToShow: '' };
    case REDUCER_ACTIONS.ADD_DATA:
      const newData = new Map();
      const iterator = action.payload.data.keys();
      for (const key of iterator) {
        const stateValOfKey = state.data.get(key);
        if (stateValOfKey && stateValOfKey.length > 0) {
          newData.set(key, stateValOfKey.concat(action.payload.data.get(key)));
        } else {
          newData.set(key, action.payload.data.get(key));
        }
      }
      return { ...state, dataToShow: payload.dataToShow, data: newData };
    default:
      return state;
  };
};