import React, { useEffect, useState } from 'react';
import Patient from 'api/Patient';
import { usePatient } from 'patients/PatientContext';
import { getAge, getGender } from 'common/PatientUtils';

import 'patients/_patients.scss';

export default function PatientDataComponent({ patient }) {
  const [data, setData] = useState();
  const context = usePatient();

  useEffect(() => {
    patient ? setData(patient) :
      setData(context);
  }, [patient, context, data]);


  return (
    <>
      {
        data &&
        <div className='patient-data-box'>
          <div className='patient-data-field-box'>
            <span className='bold-text'>ID: </span>
            <span>{Patient.getIdentifier(data)}</span>
          </div>

          <div className='patient-data-field-box'>
            <span className='bold-text'>Alias: </span>
            <span>{Patient.getName(data)}</span>
          </div>

          <div className='patient-data-field-box'>
            <span className='bold-text'>Ensayo:</span>
            <span>{Patient.getAssay(data)}</span>
          </div>

          <div className='patient-data-field-box'>
            <span className='bold-text'>Edad: </span>
            <span>{getAge(data.birthDate)}</span>
          </div>

          <div className='patient-data-field-box'>
            <span className='bold-text'>Género: </span>
            <span>{getGender(data.gender)}</span>
          </div>
        </div>
      }
    </>
  );
}
