import { Button, ButtonSet, InlineLoading } from '@carbon/react';
import { Bluetooth, BluetoothOff } from '@carbon/react/icons';

import React from 'react';

import useBluetoothBeurerPO60 from 'components/SpO2Recorder/BeurerPO60/useBluetoothBeurerPO60';
import SpO2BeurerPO60Configurer, { SPO2_CONNECTION_PARAMS } from 'components/SpO2Recorder/BeurerPO60/SpO2BeurerPO60Configurer';

import { CODE_OBSERVATION_PULSE_OXIMETER_SATURATION } from "api/constants/tags/ObservationTags";
import { useEffect } from 'react';

export const RECORDER_DATA_TYPE = "Quantity";
export const OBSERVATION_CODES = [CODE_OBSERVATION_PULSE_OXIMETER_SATURATION];

export default function SpO2RecorderBeurerPO60( { updater = () => {}, readOnly = false }) {

  const { bluetoothState, run, disconnect } = useBluetoothBeurerPO60(SPO2_CONNECTION_PARAMS, SpO2BeurerPO60Configurer.dataParser);

  useEffect(() => {
    if(bluetoothState.stopTime && bluetoothState.dataToShow) {
      updater(bluetoothState.dataToShow);
    }
  }, [bluetoothState.stopTime, bluetoothState.dataToShow]);

  return (
    <>
      <ButtonSet>
        <Button onClick={() => { run(); }} disabled={!bluetoothState || bluetoothState.loading || readOnly} renderIcon={ bluetoothState.loading ? null : Bluetooth }>
          {
            bluetoothState.loading ? 
                <InlineLoading description={`Leyendo datos... ${bluetoothState.notificationCounter ? bluetoothState.notificationCounter + 1 : 0}`} 
                aria-live={'assertive'} /> 
              : 'Conectar dispositivo'
          }
        </Button>
        <Button kind="danger" renderIcon={BluetoothOff} onClick={() => { disconnect(); }} disabled={!bluetoothState.loading} >Stop</Button>
      </ButtonSet>
    </>
  );
}