// Funcionalidades para gestión de recurso ServiceRequest
import http from 'common/HttpService';

const ServiceRequest = {
  findById: async (identifier) => {
    const res = await http.get(`/ServiceRequest/${identifier}`);
    return res.data;
  },
  findByCarePlanIdentifier: async (carePlanIdentifier) => {
    const res = await http.get(`/ServiceRequest?based-on=${carePlanIdentifier}&_count=200&_page=1`);
    const { entry = [] } = res.data;
    return entry;
  },
  createServiceRequest: async (values) => {
    const headers = { headers: { 'Prefer': 'return=representation' } };
    const data = createServiceRequestBody(values);
    return await http.post(`/ServiceRequest`, data, headers);
  },
  findByFilters: async (filters) => {
    const res = await http.get(`/ServiceRequest` + filters);
    const { entry = [] } = res.data;
    return entry;
  },
  updatePatch: async (dataPatch) => {
    const headers = { headers: { 'Content-Type': 'application/json-patch+json' } };
    return await http.patch(`/ServiceRequest/${dataPatch.id}`, dataPatch.patch, headers);
  }
};

const createServiceRequestBody = (values) => {
  return {
    resourceType: "ServiceRequest",
    status: "active",
    intent: "plan",
    instantiatesCanonical: ["ActivityDefinition/" + values.activityDefinition.id],
    basedOn: [
      {
        reference: "CarePlan/" + values.carePlan.id,
        type: "CarePlan"
      }
    ],
    subject: {
      reference: "Patient/" + values.patient.id
    },
    code: values.activityDefinition.code,
    occurrenceDateTime: values.activityDate,
    requester: {
      reference: "PractitionerRole/" + values.practitionerRole.id,
      display: values.practitionerRole.practitioner.display
    },
    performer: [
      {
        reference: "PractitionerRole/" + values.practitionerRole.id,
        display: values.practitionerRole.practitioner.display
      }
    ]
  };

};

export default ServiceRequest;