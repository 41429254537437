import React from 'react';
import NotificationCommunicationLauncher from './NotificationCommunicationLauncher';


export default function NotificationLauncher({ notification, handleCloseNotification }) {

  const returnLauncher = (notification, handleCloseNotification) => {
    let returnedComponent = <></>;
    if (notification?.resourceType === 'Communication') {
      returnedComponent = <NotificationCommunicationLauncher key={notification.id} notification={notification} onClose={handleCloseNotification} />;

    }
    return returnedComponent;
  };

  return returnLauncher(notification, handleCloseNotification);
}