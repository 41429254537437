
const BluetoothApi = {
  getDevice: async (serviceName) => {
    //console.log("navigator.bluetooth:", navigator.bluetooth);
    console.log('Servicio a buscar %s', serviceName);
    const device = await navigator.bluetooth.requestDevice({ filters: [{ services: [serviceName] }] });
    return device;
  },
  connectServer: async (device) => {
    console.log('Conectar al server en %s', device.name);
    return await device.gatt.connect();
  },
  getService: async (server, primaryServiceName) => {
    return await server.getPrimaryService(primaryServiceName);
  },
  getCharacteristicFromService: async (service, characteristicName) => {
    console.log('Conexión a la característica %s', characteristicName);
    return await service.getCharacteristic(characteristicName);
  },
  getCharacteristic: async (server, primaryServiceName, characteristicName) => {
    console.log('Conexión al servicio %s y característica %s', primaryServiceName, characteristicName);
    const service = await server.getPrimaryService(primaryServiceName);
    const characteristic = await service.getCharacteristic(characteristicName);
    return characteristic;
  },
  disconnectDevice: async (device) => {
    return await device.gatt.disconnect();
  },
  isConnected(device) {
    return device?.gatt.connected;
  }
};

export default BluetoothApi;