import React from 'react';

/**
 * Clona todos los componentes que se pasen como hijos pasándole todas las props que se pasen a este componente
 * @param {Function} Componente/s a clonar
 * @param {Object} props que se pasarán a los componentes hijos
 * @returns componente/s clonado/s con las props pasadas a este componente
 */
export default function CloneComponentWithProps({ children, ...props }) {

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...child.props, ...props });
    }
    return child;
  });

  return (<>{childrenWithProps}</>);
}
