import React from 'react';

import QuestionnaireLauncher from 'components/Launchers/QuestionnaireLauncher';
import ServiceRequestLauncher from 'components/Launchers/ServiceRequestLauncher';
import CommunicationLauncher from 'components/Launchers/CommunicationLauncher';

//import 'components/Launchers/LaunchManager.scss';

/**
 * Componente que se encarga de instanciar componentes específicos para poder ver o registrar una actividad 
 * de un CarePlan. En caso de que la actividad tenga resultados, se mostrarán. En otro caso, se cargará un 
 * componente que permita la captura de las variables de la actividad.
 * 
 * @param {*} activity Activity (agenda/Activity) con los datos de referencia de la actividad.
 * @param {*} patient Patient FHIR con los datos del paciente al que se le toman los datos.
 * @param {*} carePlan CarePlan FHIR al que pertenece la actividad.
 * @param {*} practitionerRole PractitionerRole FHIR del usuario logeado.
 * @param {*} outcomeReference "reference" FHIR a la instance de resultados que se quiere visualizar. <Null> si lo 
 *                    que se desea el introudcir resultados.
 * 
 * @returns un componente QuestionnaireLauncher, ServiceRequestLauncher o CommunicationLauncher que permite ver o crear la 
 *          información de la actividad.
 */
export default function LaunchManager({ activity, patient, carePlan, practitionerRole, outcomeReference, handleUpdateCarePlan }) {

  const returnLauncher = (activity, patient, carePlan, practitionerRole, outcomeReference) => {
    let returnedComponent = <></>;
    if (activity?.type === 'Questionnaire') {
      returnedComponent = <QuestionnaireLauncher key={`QuestionnaireLauncher${activity.referenceUuid}${outcomeReference ? outcomeReference.id : ''}`}
        activity={activity} patient={patient} carePlan={carePlan} practitionerRole={practitionerRole}
        questionnaireResponseReference={outcomeReference} handleUpdateCarePlan={handleUpdateCarePlan} />;
    } else if (activity?.type === 'ServiceRequest') {
      returnedComponent = <ServiceRequestLauncher key={`ServiceRequestLauncher${activity.referenceUuid}${outcomeReference ? outcomeReference.id : ''}`}
        activity={activity} patient={patient} carePlan={carePlan} practitionerRole={practitionerRole}
        encounterReference={outcomeReference} handleUpdateCarePlan={handleUpdateCarePlan} />;
    } else if (activity?.type === 'Communication') {
      returnedComponent = <CommunicationLauncher key={`CommunicationLauncher${activity.referenceUuid}${outcomeReference?.reference || ''}`}
        activity={activity} patient={patient} carePlan={carePlan} practitionerRole={practitionerRole} />;
    }

    return returnedComponent;
  };

  return returnLauncher(activity, patient, carePlan, practitionerRole, outcomeReference);
}