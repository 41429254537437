import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

export default function Overlay({className, children}) {

  useEffect(() => {
    document.body.className='body-with-overlay-open'
    return () => {
      document.body.className=''
    }
  }, [])

  return (
    <>
    {
      typeof document === 'undefined'
      ? null
      : ReactDOM.createPortal(
        <div className="overlay">
          <div className={`overlay--content ${className}`.trim()}>{children}</div>
        </div>
        , document.body
      )
    }
    </>
  )
}
