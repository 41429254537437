import { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import useNotification from 'hooks/useNotification';
import BluetoothApi from 'api/bluetooth/BluetoothApi';
import bluetoothReducer, { REDUCER_ACTIONS } from 'components/SpO2Recorder/BeurerPO60/BeurerPO60BluetoothReducer';

const MAX_NOTIFICACION_PER_BLOCK = 10;

const BLUETOOTH_INITIAL_STATE = {
  loading: false,
  device: null,
  deviceName: '',
  server: null,
  primaryService: null,
  writeCharacteristic: null,
  notifyCharacteristic: null,
  startTime: null,
  stopTime: null,
  dataBuffer: null,
  dataToShow: '',
  data: new Map(),
  notificationCounter: 0
};

useBluetoothBeurerPO60.propTypes = {
  btConnectionParams: PropTypes.object,
  started: PropTypes.bool,
  dataParser: PropTypes.func,
};

/**
 * Hook que gestiona la conexión con un dispositivo de pulsioximetría Beurer PO60. Devuelve los 
 * datos en el mismo formato que el hook genérico:
 * [
 *  ['59408-5', [61,61,62,65,67,70,...]]
 * ]
 * 
 * Pasos a ejecutar para recuperar los datos
 *    - emparejar dipositivo si no existe
 *    - conectarse al servicio
 *    - TODO: sincronizar fecha y hora
 *    - iniciar la notificaciones
 *      - recuperar la característica de notificaciones, iniciar las notificaciones y acoplar listener
 *      - recuperar la característica de envío de comandos e iniciar el envío de notificaciones
 *    - TODO: solicitar el siguiente bloque de notificaciones hasta que se terminen
 *    - TODO: ver como borrar los datos cuando estos han sido recibidos.
 * 
 * @param {object} btConnectionParams - datos de conexión al dispositivo (nombre del servicio que proporciona el bluetooth 
 * para filtrar la lista de dispositivos, nombre del servicio del dispositivo al que nos queremos conectar y nombre de la 
 * característica a la que nos vamos a conectar )
 * @param {func} dataParser - Función que nos permite pasear la información obtenida por el dispositivo. Deberá devolver un 
 * objeto con los campos dataToShow y data
 * @returns Objecto con el estado gestionado por este hook y la función connect que nos permitirá conectar y desconectar un 
 * dispositivo.
 */
export default function useBluetoothBeurerPO60(btConnectionParams, dataParser) {
  const [bluetoothState, dispatch] = useReducer(bluetoothReducer, BLUETOOTH_INITIAL_STATE);
  const notification = useNotification();

  const pairDevice = () => {
    dispatch({ type: REDUCER_ACTIONS.PAIR });
    return BluetoothApi.getDevice(btConnectionParams.primaryServiceName)
      .then(selectedDevice => {
        console.log('Servicio seleccionado ' + selectedDevice.name);
        selectedDevice.addEventListener('gattserverdisconnected', handleDisconnection);
        dispatch({ type: REDUCER_ACTIONS.PAIRED, payload: { device: selectedDevice } });
      }).catch(e => {
        console.log('Error buscando %s para emparejar.', btConnectionParams.primaryServiceName, e);
        notification.error({ title: "Error de emparejamiento", caption: `El dispositivo no se ha podido emparejar.` });
      });
  };

  const connectDevice = () => {
    const currentDevice = bluetoothState.device;

    if (!currentDevice) {
      console.log('> No paired Bluetooth Device');
      pairDevice();
    } else if (!BluetoothApi.isConnected(currentDevice)) {
      let server = null;
      return BluetoothApi.connectServer(currentDevice)
        .then(connectedServer => {
          server = connectedServer;
          return BluetoothApi.getService(connectedServer, btConnectionParams.primaryServiceName);
        }).then(connectedService => {
          dispatch({ type: REDUCER_ACTIONS.CONNECT, payload: { server: server, primaryService: connectedService } });
          notification.success({ title: "Dispositivo conectado", caption: `El dispositivo ${currentDevice.name} se ha conectado.` });
        });
    } else {
      console.log('> Bluetooth Device is already connected ' + currentDevice.name);
    }
  };

  /**
   * Recupera las características necesarias para la comunicación con un servicio mediante notificaciones. 
   * 
   * El dispositivo Beurer PO60 requiere:
   *  - conectarse a la característica de notificaciones e iniciarlas.
   *  - conectarse a la característica de escritura de comandos.
  */
  const getCharacteristics = () => {
    const service = bluetoothState.primaryService;
    let writableCharacteristic = null;
    let notifyCharacteristic = null;

    if (service) {
      BluetoothApi.getCharacteristicFromService(service, btConnectionParams.writeCharacteristicName)
        .then(wcharacteristic => {
          console.log('Recuperada la característica de escritura.');
          writableCharacteristic = wcharacteristic;
          return BluetoothApi.getCharacteristicFromService(service, btConnectionParams.notifyCharacteristicName);
        }).then(ncharacteristic => {
          console.log('Recuperada la característica de notificaciones.');
          notifyCharacteristic = ncharacteristic;
          dispatch({
            type: REDUCER_ACTIONS.START_NOTIFICATIONS,
            payload: {
              writeCharacteristic: writableCharacteristic,
              notifyCharacteristic: notifyCharacteristic
            }
          });
        }).catch(e => handleErrorConnectingDevice(e));
    } else {
      console.log('> Bluetooth Service no existe');
    }
  };

  const handleErrorConnectingDevice = (e) => {
    console.log('Error iniciando dispositivio', e);
    notification.error({ title: "Error iniciando Dispositivo", caption: "No se ha podido iniciar el dispositivo Bluetooth." });
    dispatch({ type: REDUCER_ACTIONS.DISCONNECT });
  };

  /**
   * Recupera los datos enviados por el dispositivo en un evento de notificación. Dado que 
   * cada notificación no se corresponde con un registro completo (ver protocolo de Beurer PO60)
   * es necesario almacenar el array de bytes recibido en un buffer.
   * @param {*} event 
   */
  const handleNotification = (event) => {
    const dataView = event.target.value;
    const bytes = [];
    for (let i = 0; i < dataView.byteLength; i++) {
      bytes.push(dataView.getUint8(i));
    }
    dispatch({ type: REDUCER_ACTIONS.ADD_DATA, payload: { dataBuffer: bytes } });
  };

  /**
   *  Cuando exista desconexión del dispositivo.
   * @param {*} event 
   */
  const handleDisconnection = (event) => {
    event.stopImmediatePropagation();
    notification.success({ title: "Dispositivo desconectado", caption: `El dispositivo ${event.target.name} está desconectado.` });
    dispatch({ type: REDUCER_ACTIONS.DISCONNECT });
  };

  /**
   * Clean up: desconectar el dispositivo al salir.
   */
  useEffect(() => {
    if (bluetoothState?.device) {
      connectDevice();
    }

    return () => {
      disconnect();
    };
  }, [bluetoothState.device]);

  /**
  * Procesar datos cuando hay datos y se ha terminado de cargar la información
  */
  useEffect(() => {
    //console.log('Procesar state: %o', bluetoothState);
    if (!bluetoothState.loading && bluetoothState.dataBuffer?.length) {
      const { data, dataToShow } = dataParser(bluetoothState.dataBuffer);
      dispatch({ type: REDUCER_ACTIONS.PROCESS_DATA, payload: { data: data, dataToShow: dataToShow } });
    }
  }, [bluetoothState.loading, bluetoothState.buffer]);

  /**
  * Se conecta al dispositivo emparejado.
  * 
  * Clean up: desconectar el dispositivo al salir.
  */
  useEffect(() => {
    if (bluetoothState?.primaryService) {
      getCharacteristics();
    }

    return () => {
      if (BluetoothApi.isConnected(bluetoothState?.device)) {
        BluetoothApi.disconnectDevice(bluetoothState.device);
        dispatch({ type: REDUCER_ACTIONS.DISCONNECT });
      }
    };
  }, [bluetoothState.primaryService]);

  /** 
   * Escribir el comando en el dispositivo para iniciar la recopilación de datos mediante notificaciones. Se
   * verifica que se ha recuperado la característica de notificaciones para evitar envíos antes de haber 
   * asociado el handler.
   * 
   */
  useEffect(() => {
    const writeCharacteristic = bluetoothState?.writeCharacteristic;
    const notifyCharacteristic = bluetoothState?.notifyCharacteristic;
    if (writeCharacteristic && notifyCharacteristic) {
      writeCharacteristic.writeValue(btConnectionParams.commands.START_NOTIFICATIONS);
    }
  }, [bluetoothState.writeCharacteristic, bluetoothState.notifyCharacteristic]);

  /** 
   * Asignar handler para la recopilación de datos desde las notificaciones y hacer clean up al salir.
   * 
   */
  useEffect(() => {
    const notifyCharacteristic = bluetoothState.notifyCharacteristic;
    if (notifyCharacteristic) {
      notifyCharacteristic.startNotifications().then(() =>
        notifyCharacteristic.addEventListener('characteristicvaluechanged', handleNotification)
      );
    } else {
      dispatch({ type: REDUCER_ACTIONS.STOP_NOTIFICATIONS });
    }

    return () => {
      if (bluetoothState.notifyCharacteristic) {
        bluetoothState.notifyCharacteristic.removeEventListener('characteristicvaluechanged', handleNotification);
      }
    };
  }, [bluetoothState.notifyCharacteristic, dispatch]);

  /**
   * El dispositivo Beurer PO60 envía 10 notificaciones por cada petición de datos. Aquí 
   * se controla se hayan recibido y se solicita un nuevo bloque de notificaciones.
   */
  useEffect(() => {
    const notificationNumber = (bluetoothState.notificationCounter || 0) + 1;
    if (notificationNumber % MAX_NOTIFICACION_PER_BLOCK === 0) {
      console.log(`Se han recibido %d. Se solicita un nuevo bloque.`, notificationNumber);
      bluetoothState.writeCharacteristic.writeValue(btConnectionParams.commands.NEXT_NOTIFICATIONS);
    }
  }, [bluetoothState.notificationCounter]);

  /**
   * Trigger que inicia la conexión con el dispositivo y la recopilación de datos.
   * 
   */
  function run() {
    if (BluetoothApi.isConnected(bluetoothState.device)) {
      dispatch({ type: REDUCER_ACTIONS.DISCONNECT });
    } else {
      if (navigator.bluetooth) {
        connectDevice();
      } else {
        notification.error({ title: "Error", caption: "El bluetooth no se encuentra activo en este navegador." });
      }
    }
  };

  function disconnect() {
    if (BluetoothApi.isConnected(bluetoothState?.device)) {
      BluetoothApi.disconnectDevice(bluetoothState.device);
    }
    dispatch({ type: REDUCER_ACTIONS.DISCONNECT });
  }

  return {
    bluetoothState,
    run,
    disconnect
  };

}
