import {
  CODE_OBSERVATION_PULSE_OXIMETER_SATURATION
} from "api/constants/tags/ObservationTags";

export const SPO2_CONNECTION_PARAMS = {
  primaryServiceName: '0000ff12-0000-1000-8000-00805f9b34fb',
  writeCharacteristicName: '0000ff01-0000-1000-8000-00805f9b34fb',
  notifyCharacteristicName: '0000ff02-0000-1000-8000-00805f9b34fb',
  commands: {
    START_NOTIFICATIONS: new Uint8Array([0x99, 0x00, 0x19]),
    NEXT_NOTIFICATIONS: new Uint8Array([0x99, 0x01, 0x1A]),
    RESEND_NOTIFICATIONS: new Uint8Array([0x99, 0x02, 0x1B]),
    CONFIRMATION_SAVE: new Uint8Array([0x99, 0x7e, 0x17]),
    CONFIRMATION_DELETE: new Uint8Array([0x99, 0x7F, 0x18]),
  }
};

export const OUTPUT_VARIABLE_SPO2 = CODE_OBSERVATION_PULSE_OXIMETER_SATURATION;

const MESSAGE_STRUCTURE = [
  'header',
  'packet',
  'startYear',
  'startMonth',
  'startDay',
  'startHour',
  'startMinute',
  'startSecond',
  'endYear',
  'endMonth',
  'endDay',
  'endHour',
  'endMinute',
  'endSecond',
  'timePeriodAndPRBits',
  'timePeriod',
  'timePeriod2',
  'sPO2Max',
  'sPO2Min',
  'sPO2Avg',
  'pRMax',
  'pRMin',
  'pRAvg',
  'checksum'
];

const SpO2BeurerPO60Configurer = {
  dataParser: (rawData) => {
    const measures = parseStreamData(rawData);
    const mappedMeasures = mapMeasures(measures);
    const spo2MappedMeasures = mappedMeasures.map(measure => {
      return {
        packet: measure.packet,
        start: measure.start,
        end: measure.end,
        duration: measure.duration,
        sPO2Max: measure.sPO2Max,
        sPO2Min: measure.sPO2Min,
        sPO2Avg: measure.sPO2Avg,
      };
    })
    const dataMap = new Map();
    dataMap.set(CODE_OBSERVATION_PULSE_OXIMETER_SATURATION, [spo2MappedMeasures]);
    return { dataToShow: spo2MappedMeasures[0], data: dataMap };
  }
};

function parseStreamData(data) {
  const result = [];
  let i = 0;
  let measure = {};

  while (i <= (data?.length || 0)) {
    const structureIndex = i % MESSAGE_STRUCTURE.length;
    const structureName = MESSAGE_STRUCTURE[structureIndex];
    measure[structureName] = data[i];
    const nextIndex = ++i;
    if (nextIndex % MESSAGE_STRUCTURE.length === 0) {
      result.push(measure);
      measure = {};
    }
  }
  
  return result;
}

function mapMeasures(measures) {
  let result = measures.map((measure, i) => {
    const start = new Date(2000 + measure.startYear, measure.startMonth - 1, measure.startDay, measure.startHour, measure.startMinute, measure.startSecond);
    const end = new Date(2000 + measure.endYear, measure.endMonth - 1, measure.endDay, measure.endHour, measure.endMinute, measure.endSecond);
        
    return {
      packet: measure.packet,
      start: start,
      end: end, 
      duration: Math.abs(end - start) / 1000,
      sPO2Max: measure.sPO2Max,
      sPO2Min: measure.sPO2Min,
      sPO2Avg: measure.sPO2Avg,
      pRMax: measure.pRMax,
      pRMin: measure.pRMin,
      pRAvg: measure.pRAvg,
    };
  });

  return result.sort(mostRecentFirst);
}

const mostRecentFirst = (a, b) => {
  if (a.start < b.start) {
    return 1;
  }
  if (a.start > b.start) {
    return -1;
  }
  return 0;
};

export default SpO2BeurerPO60Configurer;