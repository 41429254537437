import React, { Component, createContext, useContext} from 'react'

 
export const AuthContext = createContext({
  practitionerRole : "",
  userRole : ""
});
 
/** Hook to use within functional components: const auth = useAuth(); */
export function useAuth(){
  return useContext(AuthContext);
}
 
/** HOC to use within class components: export default class withAuth(MyComponent) */
export function withAuth(WrappedComponent){
  return class extends Component{
    render(){
      return (
        <AuthContext.Consumer>
          { value => <WrappedComponent auth={value} {...this.props} /> }
        </AuthContext.Consumer>
      )
    }
  }
}